<template>
    <div class="row">
        <div class="container p-3">
            <div class="row">
                <div class="col-md-3 desktop-only">
                    <sideBar />
                </div>
                <div class="col-md-9">
                    <div class="card">
                        <div class="card-header br-card-header-jaja">
                            <div class="d-flex justify-content-start bd-highlight ">
                                <div class=" bd-highlight text-white text-bold mr-auto">Tracking Pesanan</div>
                                <div class=" bd-highlight text-white text-bold">
                                    <router-link :to="'/order/'+this.$route.params.id">
                                        <button class="btn btn-sm btn-warning font-weight-bold">Kembali</button> 
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12 text-left">
                                    <ul class="timeline list-track">
                                        <li v-for="(item, index) in dataTracking.tracking" :key="index">
                                            <a class="float-right text-danger">{{item.date}} {{item.time}}</a>
                                            <p>{{item.description}}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import sideBar from '../../components/sidebar/sideBar'
    export default {
        data() {
			return {
                dataTracking:{}
            }
        },	
        components:{
            sideBar
        },
        computed: {
            ...mapGetters({globalToken:'globalTokenGetters'}),
            ...mapGetters({user:'user'}),
        },
        beforeMount(){
            this.getDataOrder()
        },
        methods: {
            getDataOrder() {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", this.globalToken);

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(`${this.domainURL}order/${this.$route.params.id}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                console.log("🚀 ~ file: tracking.vue ~ line 60 ~ getDataOrder ~ res", res)
                    if(res.status.code === 200) {
                        this.dataTracking = res.data
                    }
                })
                .catch(error => console.log('error', error));
            }
        }
    }
</script>
<style lang="scss" scoped>

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 98%;
    z-index: 9;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 9;
}

</style>
<template>
  <div class="card-login">
    <div class="kotak-login">
      <div>
        <form @submit.prevent="login">
          <div class="d-flex justify-content-between bd-highlight">
            <div class="bd-highlight">
              <h5 class="font-weight-bold">Masuk</h5>
            </div>
            <div class="bd-highlight"></div>
            <div class="bd-highlight">
              <router-link to="/">
                <img
                  width="70"
                  class="logo-jaja"
                  src="https://jaja.id/asset/home/logo/jaja-logo.png"
                  alt=""
                />
              </router-link>
            </div>
          </div>
          <div class="d-flex justify-content-between bd-highlight mb-2">
            <div class="bd-highlight">
              <small>Isikan email dan kata sandi anda untuk memulai</small>
            </div>
          </div>
          <div class="form-group">
            <input
              type="email"
              v-model="form.email"
              name="email"
              class="form-control"
              required=""
              placeholder="Email"
            />
          </div>
          <div class="form-group">
            <input
              :type="setTypeInput"
              v-model="form.password"
              name="password"
              id="passwordLogin"
              class="form-control"
              required=""
              placeholder="Password"
            />
          </div>

          <div class="d-flex justify-content-between bd-highlight mb-2">
            <div class="bd-highlight">
              <div class="form-check">
                <input
                  @click="seePass"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  <small>Lihat Password</small>
                </label>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between bd-highlight pt-3 mb-1">
            <div class="bd-highlight">
              <small
                ><router-link :to="'/auth/lupa-password'"
                  >Lupa Kata Sandi ?</router-link
                ></small
              >
            </div>
          </div>
          <button
            @click="login"
            class="btn bg-primary-jaja text-white font-weight-bold btn-block"
          >
            Masuk
          </button>
        </form>
      </div>

      <div class="">
        <div class="row center-middle-custom">
          <div class="col-md-3">
            <hr style="border-color: grey" />
          </div>
          <div class="col-md-6">
            <div class="d-flex justify-content-center bd-highlight">
              <div class="p-2 bd-highlight h6 text-secondary">
                atau login dengan
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <hr style="border-color: grey" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <GoogleLogin
            :params="params"
            :onSuccess="onSuccess"
            :onFailure="onFailure"
            class="btn btn-block border border-dark"
          >
            <div class="d-flex justify-content-center bd-highlight">
              <div class="bd-highlight mr-2">
                <img
                  src="https://nimda.jaja.id/asset/icon-jaja/google.png"
                  width="20"
                  alt=""
                />
              </div>
              <div class="bd-highlight mt-1 text-secondary">Google</div>
            </div>
          </GoogleLogin>
        </div>
      </div>
      <div class="mt-4 kotak-bawah">
        <div class="d-flex justify-content-between bd-highlight mt-4 p-2">
          <div class="p-2 bd-highlight">
            <small>Belum punya akun ? Daftar sekarang</small>
          </div>
          <div class="p-2 bd-highlight">
            <button
              @click="goToRegister"
              class="tombol-daftar p-1 text-dark font-weight-bold"
            >
              Daftar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleLogin from "vue-google-login";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      params: {
        // client_id: "662280724448-8fbj0b7ak8equ98eeui58q8hqrphlpqu.apps.googleusercontent.com"
        client_id:
          "284366139562-tnj3641sdb4ia9om7bcp25vh3qn5vvo8.apps.googleusercontent.com",
      },
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      setTypeInput: "password",
    };
  },
  computed: {
    ...mapGetters({
      dataUrl: "urlGetters",
    }),
  },
  components: {
    GoogleLogin,
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://apis.google.com/js/client:platform.js?onload=renderButton"
    );
    document.head.appendChild(externalScript);
  },
  methods: {
    login() {
      this.$swal.fire({
        html: '<img src="https://jaja.id/asset/home/logo/Loading_2.gif" alt="">', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$store
        .dispatch("login", this.form)
        .then((response) => {
          if (response.data.status.code === 200) {
            localStorage.setItem("tokenJancuk", String(response.data.data));
            let url = new URL(this.dataUrl.url);
            window.location.href = url.origin;
          } else if (response.data.status.code === 401) {
            this.$swal.fire("Gagal", response.data.status.message, "danger");
          } else {
            this.$swal.fire("Gagal", response.data.status.message, "warning");
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    onSuccess(googleUser) {
      this.successLoginGoogle(googleUser);

      // console.log('googleUser',googleUser);
      // This only gets the user information: id, name, imageUrl and email
      // console.log("googleUser.getBasicProfile()", googleUser.getBasicProfile());
    },
    successLoginGoogle(res) {
      var userProfile = res.getBasicProfile();

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: Object.values(userProfile)[5],
        fullName: Object.values(userProfile)[1],
        image: Object.values(userProfile)[4],
        fromLogin: "google",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://jaja.id/backend/user/google", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          localStorage.setItem("token", result.data);
          this.getUserProfile(res);
        })
        .catch((error) => console.log("error", error));
    },
    getUserProfile(req) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", localStorage.getItem("token"));

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch("https://jaja.id/backend/user/profile", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          // console.log("🚀 ~ file: logingoogle.php ~ line 157 ~ getUserProfile ~ res", res)
          localStorage.setItem("data_profile_user", result);
          // console.log('data_profile_user', result);
          this.getCustomerId(req, res);
        })
        .catch((error) => console.log("error", error));
    },
    getCustomerId(req, res) {
      if (res.status.code == 200) {
        setTimeout(() => {
          this.setUserData(req.email);
        }, 200);
      }
    },
    setUserData(email) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `https://jaja.id/auth/makeSessionIdCustomer/${email}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.status.code == 200) {
            let url = new URL(this.dataUrl.url);
            window.location.href = url.origin;
          } else {
            this.$swal.fire("Gagal", result.status.message, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
    onFailure() {
      this.$swal.fire("Warning", "Fail to login using google", "warning");
    },
    goToRegister() {
      this.$router.push("/Register");
    },
    seePass() {
      event.target.checked
        ? (this.setTypeInput = `text`)
        : (this.setTypeInput = `password`);
      console.log(this.setTypeInput);
    },
  },
};
</script>

<style lang="scss" scoped>
.kotak-login {
  width: 400px;

  -moz-box-shadow: 10px 10px 5px rgba(115, 115, 115, 0.75);
  -webkit-box-shadow: 10px 10px 5px rgba(115, 115, 115, 0.75);
  box-shadow: 10px 10px 10px rgba(115, 115, 115, 0.75);
  border-radius: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}
.kotak-bawah {
  position: relative;
  width: 400px;
  margin-left: -20px;
  background-color: #f0f0f0;
  border-radius: 0 0 20px 20px;
}

.opacity-button {
  opacity: 0.5;
}
.tombol-fb {
  width: 50vh;
}

.card-login {
}
.tombol-daftar {
  background-color: rgb(239 194 122);
  border-radius: 10px;
  font-size: 12px;
  width: 5vw;
  border: 0px !important;
}
</style>


<template>
  <flickity
    v-if="data && data.length > 0"
    ref="flickity"
    class="flickity"
    :options="flickityOptions"
  >
    <div
      v-for="(item, index) in data"
      :key="index"
      class="carousel-cell"
      style="padding-left: 10px !important"
    >
      <router-link :to="'/home/DetailProduk/' + item.slug">
        <div class="CheckShowCheck card-product p-3 mb-3 mr-1 shadow-custom">
          <div class="">
            <div></div>
            <div class="ImgSizeProduct img-product">
              <img
                :src="item.image"
                class=""
                style="
                  max-height: 100%;
                  max-width: 100%;
                  width: auto;
                  border-radius: 10px;
                "
              />
            </div>

            <div class="text-dark">
              <div class="keterangan ResetKet_JajaID">
                <div class="text-product one-line fsxx pt-1">
                  {{
                    item.name.length > 15
                      ? item.name.slice(0, 15).concat("...")
                      : item.name
                  }}
                </div>

                <br />
                <p class="text-price mt-2">
                  {{ item.isDiscount ? item.priceDiscount : item.price }}
                </p>
                <div class="kotak-location">
                  <img src="https://jaja.id/asset/home/logo/loc.png" class="" />
                  <small class="">{{ item.location }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="bd-highlight">

                <div class="card card-rekom shadow-custom rounded mr-3">
                    <div class="kotak-img-rekom">
                        <img class="card-img-top" :src="item.image" alt="Card image cap">
                    </div>
                    <div class="kotak-body-rekom ">
                        <h5 class="title-card judul-rekom d-flex">{{item.name.length > 15 ?  item.name.slice(0, 15).concat('...') : item.name }}</h5>
                        
                        <h4 class="card-text d-flex totalcari-rekom ">{{item.price}}</h4>
                    </div>
                </div>
                
                </div> -->
      </router-link>
    </div>
  </flickity>
  <cardPlaceholder v-else />
</template>

<script>
import Flickity from "vue-flickity";
import cardPlaceholder from "../../components/card/cardplaceholder";
export default {
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        wrapAround: false,
        freeScroll: false,
      },
    };
  },
  props: {
    data: {
      // type: Array,
      required: true,
    },
  },
  components: {
    Flickity,
    cardPlaceholder,
  },
};
</script>
<style lang="scss" scoped>
.body-home {
  // jaja
  //   background: #eff6f8
  /* ebh */
  background: #f2f2f2;
}

.header-filter {
  border-radius: 15px 15px 0 0;
}

.kotak-filter {
  color: #65b0c9;
  font-size: 25px;
}

.kotak-tombol-reset a {
  background: rgb(224, 116, 116);
  color: white;
}

.ul-list-filter {
  list-style-type: none;
}

.kotak-kondisi {
  position: relative;
  top: -12px;
}

.kotak-stock {
  position: relative;
  top: -8.5px;
}

.short-product {
  padding: 15px 0 15px 0;
  position: relative;
  top: -28px;
  border-radius: 0 0 15px 15px;
}
.short-product div {
  margin-left: 17.5%;
}

.text-label {
  color: rgb(117, 116, 116);
}

.label-order {
  font-size: 16px;
}

.label-order-ready {
  font-size: 14px;
}

.bg-filter {
  background: #ffd68d;
}
.card-product {
  border-radius: 20px;
  width: 200px;
}

.ImgSizeProduct {
  // height:150px;
  // width:150px;
}
.ImgSizeProduct img {
  border-radius: 20px 20px 0 0;
  width: 165px;
  height: 150px;
  object-fit: cover;
  /* border:1px solid rgb(224, 224, 224); */
}
.text-product {
  color: #65b0c9;
  font-weight: bold;
  text-align: left;
}
.text-price {
  color: #fdb739;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
}
.kotak-location {
  position: relative;
  display: flex;
  top: -10px;
}
.kotak-location small {
  position: absolute;
  left: 15px;
}
.kotak-location img {
  width: 14px;
  height: 14px;
  position: absolute;
}
a:hover {
  text-decoration: none;
}
</style>
import axios from 'axios'

export default {
    state: {
        homeData: {},
        recommendedData: {},
        globalToken: '',
        infoData: {},
        urlData: {}
    },
    getters: {
        homeGetters(state) {
            return state.homeData
        },
        recommendedGetters(state) {
            return state.recommendedData
        },
        globalTokenGetters(state) {
            return localStorage.getItem('token')
        },
        infoGetters(state) {
            return state.infoData
        },
        urlGetters(state) {
            return state.urlData
        }
    },
    actions: {
        async getHome({ commit }) {
            const res = await axios.get('home')
            commit('setHome', res.data.data)
        },
        async getRecomendation({ commit }) {
            const res = await axios.get('product/recommendation?page=1&limit=10')
            commit('setRecommended', res.data.data)
        },
        async getInfo({ commit }) {
            const res = await axios.get('user/info')
            commit('setInfo', res.data.data)
        },
        getUrlDinamic({ commit }) {
            let url_string = window.location.href;
            commit('setUrl', {
                url: url_string
            })
        },


    },
    mutations: {
        setHome(state, data) {
            state.homeData = data
        },
        setRecommended(state, data) {
            state.recommendedData = data
        },
        setInfo(state, data) {
            state.infoData = data
        },
        setUrl(state, data) {
            state.urlData = data
        }
    },
}
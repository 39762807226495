<template>
  <div class="container p-3">
    <div class="row">
      <div class="col-md-3">
        <sideBar />
      </div>
      <div class="col-md-9" v-if="isFinish">
        <!-- HEADER -->
        <div class="card-product shadow-custom br-card-jaja mb-2">
          <div class="card-header br-card-header-jaja">
            <div class="d-flex">
              <div class="">
                <h5 class="mt-2 text-white font-weight-bold">Favorite</h5>
              </div>
            </div>
          </div>

          <!-- FILTER -->
          <div class="card-body br-card-footer-jaja">
            <div class="row">
              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-2">
                    Filter by location
                  </div>
                  <div class="input-group">
                    <select
                      class="browser-default custom-select"
                      @change="fungsiFilterLocation"
                    >
                      <option value="">- Pilih Lokasi -</option>
                      <option
                        v-for="(a, b) in dataFilterLokasi"
                        :key="b"
                        :value="a.value"
                      >
                        {{ a.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-3">
                    Filter by Kondisi
                  </div>
                  <div class="d-flex justify-content-between bd-highlight">
                    <div
                      class="bd-highlight"
                      v-for="(a, b) in dataFilterCondition"
                      :key="b"
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="kondisibarang"
                          :value="a.value"
                          @change="fungsiFilterCondition"
                        />
                        <label
                          class="form-check-label text-label text-size-jaja"
                          for="kondisibarang"
                        >
                          <small class="font-weight-bold">{{ a.name }}</small>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-3">Filter by Stok</div>
                  <div class="d-flex justify-content-between bd-highlight">
                    <div
                      class="bd-highlight"
                      v-for="(a, b) in dataFilterPreorder"
                      :key="b"
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="preorderbarang"
                          :value="a.value"
                          @change="fungsiFilterPreorder"
                        />
                        <label
                          class="form-check-label text-label text-size-jaja"
                          for="preorderbarang"
                        >
                          <small class="font-weight-bold">{{ a.name }}</small>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- FILTER -->
        </div>
        <!-- HEADER -->

        <div class="row mb-2">
          <div class="col-6"></div>
          <div class="col-6">
            <form class="form-inline p-2 float-right" @submit="fungsiSearch">
              <div class="form-group">
                <input
                  class="form-control shadow-custom"
                  type="text"
                  name="searchWishlist"
                  placeholder="Search by keyword here..."
                  style="border-radius: 10px; width: 250px"
                  :v-model="reqData.keyword"
                  @keyup="fungsiSearch"
                />
                <img
                  src="https://nimda.jaja.id/asset/icon-jaja/luv.png"
                  style="width: 20px; margin-left: -30px"
                />
              </div>
            </form>
          </div>
        </div>

        <cardProduk
          :isFinish="isFinish"
          :data="wishList"
          :totalData="recordsLength"
        />

        <!-- PAGINATION BELUM BISA KARENA DARI API-NYA GA JALAN -->
        <!-- <br>
                <pagination style="display: flex; align-items: center; justify-content: center;" :records="recordsLength" v-model="page" :per-page="perPage" @paginate="getDataWisLish">
                </pagination> -->
      </div>
      <div class="col-md-9" v-if="!isFinish">
        <!-- HEADER -->
        <div class="card-product shadow-custom br-card-jaja mb-2">
          <div class="card-header br-card-header-jaja">
            <div class="d-flex">
              <div class="">
                <h5 class="mt-2 text-white font-weight-bold">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 200px; height: 20px"
                    />
                  </content-placeholders>
                </h5>
              </div>
            </div>
          </div>

          <!-- FILTER -->
          <div class="card-body br-card-footer-jaja">
            <div class="row">
              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-2">
                    <content-placeholders :rounded="true" :centered="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 100%; height: 20px"
                      />
                    </content-placeholders>
                  </div>
                  <content-placeholders :rounded="true" :centered="true">
                    <content-placeholders-img
                      style="width: 100%; height: 30px"
                    />
                  </content-placeholders>
                </div>
              </div>

              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-2">
                    <content-placeholders :rounded="true" :centered="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 100%; height: 20px"
                      />
                    </content-placeholders>
                  </div>
                  <content-placeholders :rounded="true" :centered="true">
                    <content-placeholders-img
                      style="width: 100%; height: 30px"
                    />
                  </content-placeholders>
                </div>
              </div>

              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-2">
                    <content-placeholders :rounded="true" :centered="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 100%; height: 20px"
                      />
                    </content-placeholders>
                  </div>
                  <content-placeholders :rounded="true" :centered="true">
                    <content-placeholders-img
                      style="width: 100%; height: 30px"
                    />
                  </content-placeholders>
                </div>
              </div>
            </div>
          </div>
          <!-- FILTER -->
        </div>
        <!-- HEADER -->

        <div class="row mb-2">
          <div class="col-6"></div>
          <div class="col-6">
            <form class="form-inline p-2 float-right">
              <div class="form-group">
                <content-placeholders :rounded="true" :centered="true">
                  <content-placeholders-img
                    style="width: 200px; height: 30px"
                  />
                </content-placeholders>
              </div>
            </form>
          </div>
        </div>

        <cardProduk
          :isFinish="isFinish"
          :data="wishList"
          :totalData="recordsLength"
        />

        <!-- PAGINATION BELUM BISA KARENA DARI API-NYA GA JALAN -->
        <!-- <br>
                <pagination style="display: flex; align-items: center; justify-content: center;" :records="recordsLength" v-model="page" :per-page="perPage" @paginate="getDataWisLish">
                </pagination> -->
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import mapGoggle from "../../components/mapcomponent/mapcomp.vue";
import cardProduk from "../../components/card/cardproduct";
export default {
  data() {
    return {
      wishList: {},

      dataFilter: {},
      dataSort: {},
      dataPage: {},
      reqData: {
        page: 1,
        limit: 20,
        keyword: "",
        filter_price: "",
        filter_location: "",
        filter_condition: "",
        filter_preorder: "",
        filter_brand: "",
        sort: "",
      },
      page: 1,
      perPage: 12,
      recordsLength: 0,
      pageSizes: [12, 24, 32],
      isFinish: false,
      dataFilterLokasi: [],
      dataFilterCondition: [],
      dataFilterPreorder: [],
    };
  },
  components: {
    sideBar,
    cardProduk,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  mounted() {
    this.getDataWisLish();
  },
  methods: {
    goToDetailProduk(slug) {
      this.$router.push(`/home/DetailProduk/${slug}`);
    },
    fungsiFilterLocation(slug) {
      this.reqData.filter_location = event.target.value;
      this.getDataWisLish(this.page);
    },
    fungsiFilterCondition(slug) {
      this.reqData.filter_condition = event.target.value;
      this.getDataWisLish(this.page);
    },
    fungsiFilterPreorder(slug) {
      this.reqData.filter_preorder = event.target.value;
      this.getDataWisLish(this.page);
    },
    fungsiSearch(slug) {
      this.reqData.keyword = event.target.value;
      this.getDataWisLish(this.page);
    },
    getDataWisLish: function (page) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}user/wishlist?page=${this.page}&limit=${this.perPage}&keyword=${this.reqData.keyword}&filter_preorder=${this.reqData.filter_preorder}&filter_condition=${this.reqData.filter_condition}&filter_location=${this.reqData.filter_location}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          this.isFinish = true;
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.wishList = res.data.wishlist;
            // this.dataFilter = res.data.filters
            // this.dataSort = res.data.sorts
            this.dataPage = res.data.totalPage;
            this.recordsLength = res.data.total_wish;
            this.getFilter();
          }
        })
        .catch((error) => console.log("error", error));
    },
    getFilter: function (page) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}user/wishlist?page=${this.page}&limit=${this.perPage}&keyword=${this.reqData.keyword}&filter_preorder=&filter_condition=&filter_location=`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.dataFilterLokasi = res.data.filters[0].items;
            this.dataFilterCondition = res.data.filters[1].items;
            this.dataFilterPreorder = res.data.filters[2].items;
          }
          this.isFinish = true;
        })
        .catch((error) => console.log("error", error));
    },
    handlePageSizeChange(event) {
      this.perPage = event.target.value;
      this.page = 1;
      this.getDataWisLish(this.page);
    },

    setAltImg(event) {
      event.target.src =
        "https://seller.jaja.id/asset/images/img-thumbnail.png";
    },
    filterProduk(slug) {
      const stringS = "filter_";
      const dataConcate = stringS.concat(slug);
      if (dataConcate == "filter_location") {
        this.reqData.filter_location = event.target.value;
      } else if (dataConcate == "filter_condition") {
        this.reqData.filter_condition = event.target.value;
      } else if (dataConcate == "filter_preorder") {
        this.reqData.filter_preorder = event.target.value;
      }
      setTimeout(() => this.getDataWisLish(), 200);
    },
    sortProduk() {
      this.reqData.sort = event.target.value;
      setTimeout(() => this.getDataWisLish(), 200);
    },
    setPage(page) {
      this.reqData.page = page;
      setTimeout(() => this.getDataWisLish(), 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-filter-item {
  border-radius: 10px;
  background: #ffd68d;
}
</style>
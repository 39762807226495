<template>
    <div class="container p-3">
        <div class="row">
            <div class="col-md-3">
                <sideBar />
            </div>
            <div class="col-md-9">
                <div class="d-flex flex-column text-left bd-highlight mb-3 shadow-custom card-product">
                    <div class="py-3 px-2 bd-highlight card-header-danakembali">
                        <div class="d-flex justify-content-start bd-highlight ">
                            <div class="ml-3 bd-highlight mr-auto">
                                <h5 class="text-white font-weight-bold d-block">Pengembalian Dana</h5>
                            </div>
                        </div>
                    </div>
                    <div class=" bd-highlight bg-white p-4 parent-card-body-danakembali shadow-custom">
                        <div class="d-flex align-items-start flex-column card-body-danakembali">
                            <div class="mb-auto "></div>
                            <div class="mx-auto font-weight-bold">Belum ada pengembalian Dana</div>
                            <div class="mt-auto"></div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="modal" id="modalUpdate">
            <div class="modal-dialog">
                <div class="modal-content">

                <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Update Profil</h4>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <!-- {{user}} -->
                    <div class="modal-body">
                        <form action="/action_page.php">
                            <div class="form-group">
                                <input type="text" v-model="user.name" class="form-control" placeholder="isi nama anda">
                            </div>
                            <div class="form-group">
                                <input type="number" name="phoneNumber" class="form-control" v-model="user.phoneNumber" placeholder="isi Handphone anda">
                            </div>
                            <div class="form-group">
                                <input type="email" name="email" class="form-control" v-model="user.email" placeholder="isi Email anda">
                            </div>
                            <div class="form-group">
                                <div class="d-flex justify-content-between bd-highlight mb-3">
                                    <div class="p-2 bd-highlight">
                                        <div class="form-check">
                                            <input @change="chooseGender" class="form-check-input" type="radio" name="gender" id="flexRadioDefault1" :checked="user.gender ==='pria'" value="pria">
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Pria
                                            </label>
                                        </div>
                                    </div>
                                    <div class="p-2 bd-highlight">
                                        <div class="form-check">
                                            <input @change="chooseGender" class="form-check-input" type="radio" name="gender" id="flexRadioDefault2" :checked="user.gender ==='wanita'" value="wanita">
                                            <label class="form-check-label" for="flexRadioDefault2">
                                                Wanita
                                            </label>
                                        </div>
                                    </div>
                                    <input type="hidden" v-model="user.gender">
                                </div>
                            </div>
                            <div class="form-group">
                                <date-picker :lang="lang" v-model="user.birthDate" valueType="format" format="DD-MM-YYYY"></date-picker>
                            </div>
                            <div class="container">
                            <h2>Upload file</h2>
                            <vue-base64-file-upload 
                                class="v1"
                                accept="image/png,image/jpeg"
                                image-class="v1-image"
                                input-class="v1-image"
                                :max-size="customImageMaxSize"
                                @size-exceeded="onSizeExceeded"
                                @file="onFile"
                                @load="onLoad" />
                            </div>
                        </form>
                    </div>

                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button @click="submitProfil" type="button" class="btn btn-info" >Submit</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script type="text/javascript" src="https://app.midtrans.com/snap/snap.js" data-client-key="Mid-client-S9f6KNmuVrQre0GQ"></script>
<script>

    import {mapGetters} from 'vuex';
    
    import VueBase64FileUpload from 'vue-base64-file-upload';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    // import 'vue2-datepicker/locale/zh-cn';
    import sideBar from '../../components/sidebar/sideBar'
    export default {
		data() {
			return {
                customImageMaxSize: 3 ,
                tanggal:null,
                reqUpdateProfil:{
                    name: "",
                    phoneNumber: null,
                    email: "",
                    gender: "",
                    birthDate: "",
                    photo: ""
                },
                dataProfil:{},
                lang: {
                    formatLocale: {
                        firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                    },
                }
        },
        components:{
            sideBar,
            DatePicker,
            VueBase64FileUpload
        },
		computed: {
            ...mapGetters({globalToken:'globalTokenGetters'}),
            ...mapGetters({user:'user'}),
        },
        beforeMount(){
            // this.getDataProfil()
        },
		methods: {
            modalUpdate() {
                $("#modalUpdate").modal('show')
            },
            submitProfil() {
                this.reqUpdateProfil.name = this.user.name
                this.reqUpdateProfil.phoneNumber = this.user.phoneNumber
                this.reqUpdateProfil.email = this.user.email
                this.reqUpdateProfil.gender = this.user.gender
                this.reqUpdateProfil.birthDate = this.user.birthDate
                this.updateProfil()
                // console.log(this.reqUpdateProfil)
            },
            chooseGender() {
                this.user.gender = event.target.value
                // console.log(event.target.value)
            },
            updateProfil() {
                let myHeaders = new Headers();
                myHeaders.append("Authorization", this.globalToken);
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Cookie", "ci_session=t3aj7ofie0camk7k1v2d6s8llbr7q3kb");

                let raw = JSON.stringify(this.reqUpdateProfil);

                let requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(`${this.domainURL}user/profile`, requestOptions)
                .then(response => response.json())
                .then(res => {
                    if(res.status.code === 200) {
                        $("#modalUpdate").modal('hide')
                        this.$swal.fire('Sukses', res.status.message, 'info')
                        // this.$store.dispatch('get_user')
                    }
                })
                .catch(error => console.log('error', error));
            },
            onFile(file) {
                console.log(file); // file object
            },
        
            onLoad(dataUri) {
                console.log(dataUri); // data-uri string
            },
        
            onSizeExceeded(size) {
                alert(`Image ${size}Mb size exceeds limits of ${this.customImageMaxSize}Mb!`);
            },
            inputProfil() {
                event.preventDefault()
                alert('ok')
            }
		}
    }
</script>
<style lang="scss" scoped>
    .iconPayment {
        width:50%;
        margin-right:5%
    }
    .mx-datepicker {
        width: 100%;
    }
    .parent-card-body-danakembali {
        border-radius:  0 0 10px 10px ;
    }
    .card-header-danakembali {
        background: rgb(100, 175, 200);
        border-radius: 10px 10px 0 0 ;
    }


    .card-body-danakembali {
        width:100%;
        height:200px;
        border: 1px solid  rgb(100, 175, 200);
        border-radius: 10px ;

    }

    .kotak-profil-1 {
        margin-right:15%;
    }
    .bg-jaja {
        background: rgb(100, 175, 200);
    }
</style>
<template>
  <div>
    <div
      class="
        navbar navbar-expand
        flex-column flex-md-row
        navbar-custom
        shadow-custom
      "
      id="navbarchat"
      style="z-index: 2"
    >
      <div class="container-fluid">
        <!-- LOGO -->
        <a
          @click="runRedirect"
          class="navbar-brand mr-0 mr-md-2 logo"
          style="display: block !important"
        >
          <span class="logo-lg">
            <img
              src="https://jaja.id/asset/home/logo/jaja-logo.png"
              alt=""
              class="desktop-only"
              style="max-width: 100%; width: 12vw"
            />
            <!-- <img src="https://jaja.id/asset/home/logo/jaja-logo.png" alt="" height="50" class="mobile-only" /> -->
            <span
              class="d-inline h4 ml-1 text-dark font-weight-bold"
              style="font-style: italic"
            >
              Chat Center
            </span>
          </span>
        </a>

        <!-- <a href="https://jaja.id/chat" class="nav-link mr-5 float-right" aria-haspopup="false" aria-expanded="false">
                    <img src="https://seller.jaja.id/asset/images/icon-jaja/jaja-chat.png" alt="icon" class="" style="width: 19px; height: 16px;">
                    <span style="position: absolute; border-radius:50%; color:#fff; font-size:7pt;" class="badge badge-warning"><span id="notifc" ></span></span>
                    <input type="hidden" id="notifch">
                </a> -->

        <div class="dropdown notification-list float-right">
          <a
            class="btn btn-light dropdown-toggle"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <div class="media">
              <div class="media text-left">
                <h6 class="pro-user-name ml-2 my-0">
                  <i class="fa fa-user"></i>
                  <span>&nbsp;{{ user.name }}</span>
                </h6>
              </div>
            </div>
          </a>
          <div class="dropdown-menu profile-dropdown-items dropdown-menu-right">
            <router-link :to="'/dashboard'" class="dropdown-item notify-item">
              <i class="fa fa-user icon-dual icon-xs mr-2"></i>
              <span>Akun Saya</span>
            </router-link>

            <router-link :to="'/listorder'" class="dropdown-item notify-item">
              <i class="fas fa-receipt icon-dual icon-xs mr-2"></i>
              <span>Pesanan Saya</span>
            </router-link>

            <a
              @click="logout"
              class="dropdown-item notify-item"
              style="cursor: pointer"
            >
              <i class="fas fa-sign-out-alt icon-dual icon-xs mr-2"></i>
              <span>Logout</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="" style="overflow: hidden">
      <div class="row">
        <div
          class="col-md-3 pr-0 bg-orange-soft-jaja"
          style="overflow: auto"
          :class="showListChat ? `` : `w-col-100`"
        >
          <div class="bg-orange-soft-jaja">
            <div class="card-header bg-orange-soft-jaja">
              <div class="d-flex justify-content-between bd-highlight">
                <div class="pt-2 bd-highlight mr-auto">
                  <h5 class="d-flex font-weight-bold text-white">
                    Seller's Chat
                  </h5>
                </div>
              </div>
            </div>

            <div v-if="isFinish">
              <div
                class="bg-white card-footer-body-sidebar"
                v-for="(item, index) in dataChatToko"
                :key="index"
              >
                <li
                  @click="listChat(index)"
                  class="listToko list-group-item tobepointer"
                  style="
                    background-color: #fedeac !important;
                    border: 1px solid white;
                    border-radius: 0px;
                  "
                >
                  <div class="d-flex justify-content-start bd-highlight">
                    <div class="bd-highlight">
                      <img
                        class="
                          shadow-custom
                          lazy
                          yuhu
                          gambar-profile
                          border
                          bg-white
                          mr-2
                          imgtokoavatar
                          rounded-circle
                        "
                        :src="item.photo"
                      />
                    </div>
                    <div class="bd-highlight mt-auto mb-auto w-100">
                      <div class="d-flex justify-content-start">
                        <div class="mr-auto">
                          <div class="d-flex align-content-center flex-wrap">
                            <div class="bd-highlight font-weight-bold">
                              {{ item.name }}
                            </div>
                          </div>
                          <div class="d-flex align-content-center flex-wrap">
                            <div class="bd-highlight">
                              <small v-if="item.message !== undefined">
                                {{
                                  item.message.text.length > 30
                                    ? item.message.text
                                        .slice(0, 30)
                                        .concat("...")
                                    : item.message.text
                                }}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div>
                          <span
                            :class="
                              item.amount
                                ? `badge badge-danger`
                                : `d-none badge badge-danger`
                            "
                            >{{ item.amount }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </div>
            <div v-if="!isFinish">
              <div
                class="bg-white card-footer-body-sidebar"
                v-for="index in 3"
                :key="index"
              >
                <li
                  class="listToko list-group-item tobepointer"
                  style="
                    background-color: #fedeac !important;
                    border: 1px solid white;
                    border-radius: 0px;
                  "
                >
                  <div class="d-flex justify-content-start bd-highlight">
                    <div class="bd-highlight">
                      <content-placeholders :rounded="true" :centered="true">
                        <content-placeholders-img
                          class="
                            shadow-custom
                            lazy
                            yuhu
                            gambar-profile
                            border
                            bg-white
                            mr-2
                            imgtokoavatar
                            rounded-circle
                          "
                          style="
                            width: 75px;
                            max-width: 100%;
                            height: 75px;
                            max-height: 100%;
                          "
                        />
                      </content-placeholders>
                    </div>
                    <div class="bd-highlight mt-auto mb-auto w-100">
                      <div class="d-flex justify-content-start">
                        <div class="mr-auto">
                          <div class="d-flex align-content-center flex-wrap">
                            <div class="bd-highlight font-weight-bold">
                              <content-placeholders
                                :rounded="true"
                                :centered="true"
                              >
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 10vw; height: 20px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                          <div class="d-flex align-content-center flex-wrap">
                            <div class="bd-highlight">
                              <content-placeholders
                                :rounded="true"
                                :centered="true"
                              >
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 7vw; height: 15px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-md-9 pl-0"
          id="list_chat"
          :class="showListChat ? `bg-soft-primary-jaja` : `bg-white`"
        >
          <div
            :class="showListChat ? `d-none` : `account-pages`"
            style="margin-top: 20vh"
          >
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-5 col-8">
                  <div class="text-center">
                    <div>
                      <img
                        src="https://jaja.id/asset/seller/images/banner-chat.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-center">
                  <h3 class="mt-3 fsx">Selamat Datang di Chat Center</h3>
                  <p class="text-muted fsx">
                    Anda bisa langsung chat penjual sekarang juga!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div :class="showListChat ? `` : `d-none`">
            <div>
              <div class="bg-soft-primary-jaja">
                <div
                  class="card-product bg-soft-primary-jaja"
                  style="border-radius: 0px !important"
                >
                  <div class="card-header">
                    <div class="d-flex justify-content-between bd-highlight">
                      <div class="pt-2 bd-highlight mr-auto">
                        <h5 class="d-flex font-weight-bold text-white">
                          {{ reqChatToko.name }}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div
                    id="list-chat-customer"
                    class="card-body bg-soft-primary-jaja"
                    :class="showCardChat ? `c-body-45` : `c-body-65`"
                    style="border-radius: 0px !important"
                  >
                    <ul class="list-group">
                      <template>
                        <div
                          v-for="(item, index) in listChatPerToko.data"
                          :key="index"
                        >
                          <div
                            :class="
                              user.uid == item.from
                                ? `float-right bd-highlight `
                                : `float-left bd-highlight `
                            "
                          >
                            <div class="p-2 bd-highlight">
                              <li
                                :class="
                                  user.uid == item.from
                                    ? 'list-group-item'
                                    : 'list-group-item'
                                "
                                style="
                                  background-color: transparent;
                                  border: 0px;
                                "
                              >
                                <div
                                  :class="
                                    user.uid == item.from
                                      ? 'row float-right'
                                      : 'row float-left'
                                  "
                                >
                                  <div
                                    :class="
                                      user.uid == item.from
                                        ? 'd-flex justify-content-end p-2'
                                        : 'd-flex justify-content-start p-2'
                                    "
                                  >
                                    <img
                                      v-if="item.from.includes('seller')"
                                      :src="reqChatToko.photo"
                                      width="40"
                                      height="40"
                                      class="
                                        shadow-custom
                                        lazy
                                        yuhu
                                        gambar-profile
                                        border
                                        bg-white
                                        rounded-circle
                                        mr-2
                                      "
                                    />
                                    <div v-if="item.message && item.order">
                                      <div
                                        @click="
                                          gotoDetailPesanan(
                                            item.order ? item.order.invoice : ``
                                          )
                                        "
                                        :class="
                                          item.order
                                            ? `card-body tab-content p-3 bordr-custom-chat bg-white mb-2`
                                            : `d-none`
                                        "
                                        style="cursor: pointer"
                                      >
                                        <div
                                          class="
                                            d-flex
                                            justify-content-start
                                            bd-highlight
                                          "
                                        >
                                          <div class="bd-highlight mr-2">
                                            <a class="image-popup">
                                              <img
                                                :src="item.order.imageOrder"
                                                width="60"
                                                class="
                                                  img-thumbnail img-responsive
                                                "
                                              />
                                            </a>
                                          </div>
                                          <div class="bd-highlight text-left">
                                            <div>
                                              <span
                                                class="
                                                  font-weight-bold
                                                  text-dark
                                                "
                                                >{{
                                                  item.order
                                                    ? item.order.invoice
                                                    : ``
                                                }}</span
                                              >
                                            </div>
                                            <div class="d-flex">
                                              <span
                                                class="
                                                  font-weight-bold
                                                  text-primary-jaja
                                                "
                                                >{{
                                                  item.order
                                                    ? item.order.status
                                                    : ``
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <p
                                        :class="
                                          user.uid == item.from
                                            ? 'bg-orange-soft-jaja p-2 text-dark br-card-jaja text-right shadow-custom-chat-right'
                                            : ' bg-orange-soft-jaja p-2 text-dark br-card-jaja text-left shadow-custom'
                                        "
                                        style="font-size: 90%"
                                      >
                                        {{ item.message }}
                                        <br />
                                        <br />
                                        <small
                                          class="text-dark font-weight-bold"
                                          >{{
                                            item.date
                                              ? String(item.date).slice(0, 21)
                                              : ""
                                          }}</small
                                        >
                                      </p>
                                    </div>
                                    <div
                                      v-else-if="item.order"
                                      @click="
                                        gotoDetailPesanan(
                                          item.order ? item.order.invoice : ``
                                        )
                                      "
                                      :class="
                                        item.order
                                          ? `card-body tab-content p-3 bordr-custom-chat bg-white`
                                          : `d-none`
                                      "
                                    >
                                      <div
                                        class="
                                          d-flex
                                          justify-content-start
                                          bd-highlight
                                        "
                                      >
                                        <div class="bd-highlight mr-2">
                                          <a class="image-popup">
                                            <img
                                              src="https://seller.jaja.id/asset/images/img-thumbnail.png"
                                              width="60"
                                              class="
                                                img-thumbnail img-responsive
                                              "
                                            />
                                          </a>
                                        </div>
                                        <div class="bd-highlight">
                                          <div>
                                            <span class="text-muted">{{
                                              item.order
                                                ? item.order.invoice
                                                : ``
                                            }}</span>
                                          </div>
                                          <div class="d-flex">
                                            <span class="text-muted">{{
                                              item.order
                                                ? item.order.status
                                                : ``
                                            }}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-else-if="
                                        item.message &&
                                        item.productTitle &&
                                        item.priceDiscount !== '0'
                                      "
                                    >
                                      <div
                                        @click="
                                          gotoDetailProduct(
                                            item.productSlug
                                              ? item.productSlug
                                              : ``
                                          )
                                        "
                                        :class="
                                          item.priceDiscount
                                            ? `card-body tab-content p-3 bordr-custom-chat bg-white mb-2`
                                            : `d-none`
                                        "
                                        style="cursor: pointer"
                                      >
                                        <div
                                          class="d-flex flex-row bd-highlight"
                                        >
                                          <div class="bd-highlight">
                                            <a class="image-popup">
                                              <img
                                                :src="item.productImage"
                                                width="60"
                                                class="
                                                  img-thumbnail img-responsive
                                                "
                                              />
                                            </a>
                                          </div>
                                          <div
                                            class="pl-3 bd-highlight text-left"
                                          >
                                            <span class="fsx"
                                              ><b class="mb-2">{{
                                                item.productTitle
                                                  ? item.productTitle
                                                  : ""
                                              }}</b></span
                                            ><br />
                                            <b
                                              ><span
                                                style="
                                                  text-decoration: line-through;
                                                "
                                                class="mr-2 fsx"
                                                >{{
                                                  item.priceDiscount
                                                    ? item.priceFirst
                                                    : ""
                                                }}</span
                                              ><span
                                                class="badge badge-danger"
                                                >{{
                                                  item.priceDiscount
                                                    ? item.priceDiscount + "%"
                                                    : ""
                                                }}</span
                                              ></b
                                            ><br />
                                            <span class="fsxx"
                                              ><b class="text-danger">{{
                                                item.priceDiscount
                                                  ? item.priceLast
                                                  : ""
                                              }}</b></span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                      <p
                                        :class="
                                          user.uid == item.from
                                            ? 'bg-orange-soft-jaja p-2 text-dark br-card-jaja text-right shadow-custom-chat-right'
                                            : ' bg-orange-soft-jaja p-2 text-dark br-card-jaja text-left shadow-custom'
                                        "
                                        style="font-size: 90%"
                                      >
                                        {{ item.message }}
                                        <br />
                                        <br />
                                        <small
                                          class="text-dark font-weight-bold"
                                          >{{
                                            item.date
                                              ? String(item.date).slice(0, 21)
                                              : ""
                                          }}</small
                                        >
                                      </p>
                                    </div>
                                    <div
                                      v-else-if="
                                        item.message &&
                                        item.productTitle &&
                                        item.priceDiscount == '0'
                                      "
                                    >
                                      <div
                                        @click="
                                          gotoDetailProduct(
                                            item.productSlug
                                              ? item.productSlug
                                              : ``
                                          )
                                        "
                                        :class="
                                          item.priceDiscount
                                            ? `card-body tab-content p-3 bordr-custom-chat bg-white mb-2`
                                            : `d-none`
                                        "
                                        style="cursor: pointer"
                                      >
                                        <div
                                          class="
                                            d-flex
                                            justify-content-start
                                            bd-highlight
                                          "
                                        >
                                          <div class="bd-highlight mr-2">
                                            <a class="image-popup">
                                              <img
                                                :src="item.productImage"
                                                width="60"
                                                class="
                                                  img-thumbnail img-responsive
                                                "
                                              />
                                            </a>
                                          </div>
                                          <div class="bd-highlight text-left">
                                            <div>
                                              <span
                                                class="
                                                  font-weight-bold
                                                  text-dark
                                                "
                                                >{{
                                                  item.productTitle
                                                    ? item.productTitle
                                                    : ``
                                                }}</span
                                              >
                                            </div>
                                            <div class="d-flex">
                                              <span
                                                class="
                                                  font-weight-bold
                                                  text-primary-jaja
                                                "
                                                >{{
                                                  item.priceFirst
                                                    ? item.priceFirst
                                                    : ``
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <p
                                        :class="
                                          user.uid == item.from
                                            ? 'bg-orange-soft-jaja p-2 text-dark br-card-jaja text-right shadow-custom-chat-right'
                                            : ' bg-orange-soft-jaja p-2 text-dark br-card-jaja text-left shadow-custom'
                                        "
                                        style="font-size: 90%"
                                      >
                                        {{ item.message }}
                                        <br />
                                        <br />
                                        <small
                                          class="text-dark font-weight-bold"
                                          >{{
                                            item.date
                                              ? String(item.date).slice(0, 21)
                                              : ""
                                          }}</small
                                        >
                                      </p>
                                    </div>
                                    <div v-else-if="item.message && item.image">
                                      <a :href="item.image">
                                        <img
                                          :class="
                                            item.image
                                              ? `image-send img-fluid br-card-jaja shadow-custom-chat-right mb-2`
                                              : `d-none`
                                          "
                                          :src="item.image"
                                          alt=""
                                        />
                                      </a>
                                      <p
                                        :class="
                                          user.uid == item.from
                                            ? 'bg-orange-soft-jaja p-2 text-dark br-card-jaja text-right shadow-custom-chat-right'
                                            : ' bg-orange-soft-jaja p-2 text-dark br-card-jaja text-left shadow-custom'
                                        "
                                        style="font-size: 90%"
                                      >
                                        {{ item.message }}
                                        <br />
                                        <br />
                                        <small
                                          class="text-dark font-weight-bold"
                                          >{{
                                            item.date
                                              ? String(item.date).slice(0, 21)
                                              : ""
                                          }}</small
                                        >
                                      </p>
                                    </div>
                                    <a
                                      :href="item.image"
                                      v-else-if="item.image"
                                    >
                                      <img
                                        :class="
                                          item.image
                                            ? `image-send img-fluid br-card-jaja shadow-custom-chat-right`
                                            : `d-none`
                                        "
                                        :src="item.image"
                                        alt=""
                                      />
                                      <br />
                                      <small
                                        class="text-dark font-weight-bold"
                                        >{{
                                          item.date
                                            ? String(item.date).slice(0, 21)
                                            : ""
                                        }}</small
                                      >
                                    </a>
                                    <div v-else-if="item.message">
                                      <p
                                        :class="
                                          user.uid == item.from
                                            ? 'bg-orange-soft-jaja p-2 text-dark br-card-jaja text-right shadow-custom-chat-right'
                                            : ' bg-orange-soft-jaja p-2 text-dark br-card-jaja text-left shadow-custom'
                                        "
                                        style="font-size: 90%"
                                      >
                                        {{ item.message }}
                                        <br />
                                        <br />
                                        <small
                                          class="text-dark font-weight-bold"
                                          >{{
                                            item.date
                                              ? String(item.date).slice(0, 21)
                                              : ""
                                          }}</small
                                        >
                                      </p>
                                    </div>

                                    <img
                                      v-if="user.uid == item.from"
                                      src="https://nimda.jaja.id/asset/icon-jaja/user%20jaja%201.png"
                                      width="40"
                                      height="40"
                                      class="
                                        shadow-custom
                                        lazy
                                        yuhu
                                        gambar-profile
                                        border
                                        bg-white
                                        rounded-circle
                                        ml-2
                                      "
                                    />
                                  </div>
                                </div>
                              </li>
                            </div>
                          </div>
                        </div>
                      </template>
                    </ul>
                  </div>
                  <div
                    class="card-footer mb-0"
                    :class="showCardChat ? `c-footer-35` : `c-footer-25`"
                    style="
                      background-color: #cde8ef;
                      border-radius: 0px !important;
                    "
                  >
                    <div
                      :class="
                        showListChat ? `form-group parent-form mb-0` : `d-none`
                      "
                    >
                      <!-- <div id="content-preview" class="preview-img d-none">
                                            <div>
                                                <img :src="previewImage" >
                                            </div>
                                            <hr/>
                                        </div> -->

                      <div class="preview-img d-none">
                        <div
                          :class="
                            showCardChat
                              ? `d-flex  bd-highlight mb-1`
                              : `d-none`
                          "
                        >
                          <div
                            style="cursor: pointer"
                            :class="showCardChat ? `mb-2` : `d-none`"
                          >
                            <a class="image-popup" :href="previewImage">
                              <img
                                v-if="previewImage !== undefined"
                                :src="previewImage"
                                width="auto"
                                class="
                                  img-thumbnail img-responsive
                                  bordr-custom-chat
                                "
                                style="max-height: 100%; height: 60px"
                              />
                            </a>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="paramToChat"
                        :class="
                          showCardChat
                            ? `d-flex justify-content-start align-items-end bd-highlight mb-1`
                            : `d-none`
                        "
                      >
                        <div v-if="paramToChat.order">
                          <div
                            @click="
                              gotoDetailPesanan(
                                paramToChat.order
                                  ? paramToChat.order.invoice
                                  : ``
                              )
                            "
                            style="cursor: pointer"
                            :class="
                              showCardChat
                                ? `card-body tab-content p-3 bordr-custom-chat bg-white mb-2`
                                : `d-none`
                            "
                          >
                            <div
                              class="d-flex justify-content-start bd-highlight"
                            >
                              <div class="bd-highlight mr-2">
                                <a class="image-popup">
                                  <img
                                    v-if="paramToChat.order !== undefined"
                                    :src="paramToChat.order.imageOrder"
                                    width="auto"
                                    class="img-thumbnail img-responsive"
                                    style="max-height: 100%; height: 60px"
                                  />
                                </a>
                              </div>
                              <div class="bd-highlight text-left">
                                <div>
                                  <span class="font-weight-bold text-dark">{{
                                    paramToChat.order
                                      ? paramToChat.order.invoice
                                      : ``
                                  }}</span>
                                </div>
                                <div class="d-flex">
                                  <span
                                    class="font-weight-bold text-primary-jaja"
                                    >{{
                                      paramToChat.order
                                        ? paramToChat.order.status
                                        : ``
                                    }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="paramToChat.product">
                          <div
                            @click="
                              gotoDetailProduct(
                                paramToChat.product.slug
                                  ? paramToChat.product.slug
                                  : ``
                              )
                            "
                            :class="
                              paramToChat.product.priceDiscount
                                ? `card-body tab-content p-3 bordr-custom-chat bg-white mb-2`
                                : `d-none`
                            "
                            style="cursor: pointer"
                          >
                            <div
                              class="d-flex justify-content-start bd-highlight"
                            >
                              <div class="bd-highlight mr-2">
                                <a class="image-popup">
                                  <img
                                    :src="paramToChat.product.image"
                                    width="60"
                                    class="img-thumbnail img-responsive"
                                  />
                                </a>
                              </div>
                              <div class="bd-highlight text-left">
                                <div>
                                  <span class="font-weight-bold text-dark">{{
                                    paramToChat.product.name
                                      ? paramToChat.product.name
                                      : ``
                                  }}</span>
                                </div>
                                <div class="d-flex">
                                  <span
                                    class="font-weight-bold text-primary-jaja"
                                    >{{
                                      paramToChat.product.priceFirst
                                        ? paramToChat.product.priceFirst
                                        : ``
                                    }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          d-flex
                          justify-content-start
                          align-items-end
                          bd-highlight
                          mb-1
                        "
                      >
                        <div class="pb-2 pr-2 bd-highlight">
                          <span
                            @click="getString"
                            class="btn-outline-warning-custom-checkbox p-2"
                            style="cursor: pointer"
                            >Hai apakah produk ini masih ada ?</span
                          >
                        </div>
                        <div @click="getString" class="pb-2 pr-2 bd-highlight">
                          <span
                            class="btn-outline-warning-custom-checkbox p-2"
                            style="cursor: pointer"
                            >Terima kasih</span
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-10">
                          <textarea
                            class="form-control bordr-custom-chat"
                            @keyup="inputChat"
                            id="strChat"
                            rows="3"
                            placeholder="Tulis Pesanmu ... "
                          ></textarea>
                        </div>
                        <div class="col-md-1">
                          <div
                            class="d-flex justify-content-center bd-highlight"
                          >
                            <div class="p-2 bd-highlight">
                              <input
                                type="file"
                                id="actual-btn"
                                @change="uploadGambar"
                                hidden
                              />
                              <label
                                class="
                                  btn btn-warning
                                  bg-warning-jaja
                                  rounded
                                  p-2
                                "
                                for="actual-btn"
                                ><i class="fa fa-camera text-dark fa-2x"> </i
                              ></label>
                              <!-- <input id="actual-btn" @change="uploadGambar" type="file"/> -->
                              <!-- <div class="fileUpload btn btn-secondary">
                                                            <span><i class="fa fa-camera text-dark fa-2x"></i></span>
                                                            <input class="gambar" name="gambar" id="gambar" type="file" @change="uploadGambar" />
                                                        </div>   -->
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <div class="col-md-1">
                          <div
                            class="d-flex justify-content-center bd-highlight"
                          >
                            <div class="p-2 bd-highlight">
                              <span
                                @click="sendChat"
                                class="btn btn-info bg-primary-jaja rounded p2"
                                ><i
                                  class="fa fa-paper-plane fa-2x text-white"
                                ></i
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";

export default {
  data() {
    return {
      showListChat: false,
      showCardChat: false,
      dataChatToko: {},
      arrChatToko: [],
      dataUser: {},
      paramToChat: {
        chat: "",
        id: "",
        name: "",
        order: {
          imageOrder: "",
          invoice: "",
          status: "",
        },
        product: {
          image: "",
          name: "",
          priceDiscount: "",
          priceFirst: "",
          priceLast: "",
          slug: "",
        },
      },
      dataIndexToko: {},
      reqChatToko: {
        data: {
          name: "",
          photo: "",
          chat: "",
          id: "",
          amount: "",
        },
        newData: null,
      },
      listChatPerToko: {},
      tokoName: "",
      strChat: "",
      message: {
        message: "",
        read: false,
        time: firebase.database.ServerValue.TIMESTAMP,
        date: new Date().toString(),
        from: "",
        image: "",
        order: null,
        priceDiscount: "",
        priceFirst: "",
        priceLast: "",
        productImage: "",
        productTitle: "",
        productSlug: "",
      },
      previewImage: "",
      notifNew: {},
      chatProduct: {},
      isFinish: false,
    };
  },
  components: {},
  computed: {
    ...mapGetters({ isLoggedIn: "isLoggedIn" }),
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
    ...mapGetters({
      dataUrl: "urlGetters",
    }),
  },
  beforeCreate() {
    // console.log(URL.createObjectURL())
  },
  mounted() {
    this.paramToChat = JSON.parse(localStorage.getItem("paramToChat"));
    console.log("this.paramToChat", this.paramToChat);
    this.dataUser = this.user;
    const database = firebase.database();
    const uid = this.dataUser.uid;
    database.ref(`people/${uid}`).on("value", function (snapshot) {
      let item = snapshot.val();
      let notif;
      if (item) {
        if (item.notif) {
          notif = item.notif;
        }
      }
    });

    this.getSellerChat();
  },
  beforeMount() {},
  methods: {
    getSellerChat() {
      const uid = this.dataUser.uid;
      const database = firebase.database();
      let arr_people = [];
      let tamp_arr_people = [];
      var returnArray = [];
      setTimeout(() => {}, 2000);
      database.ref(`friend/${uid}`).on("value", (snapshot) => {
        arr_people.length = 0;
        // arr_people = [];
        snapshot.forEach(function (snap) {
          var item = snap.val();
          // console.log(snap.key);
          if (snap.key !== "undefined") {
            item.id = snap.key;
            if (item.id !== `${uid}` && item.id !== "null") {
              returnArray.push(item);

              const tampArrPeople = [];
              database.ref(`people/${item.id}`).on("value", function (people) {
                var item_people = people.val();
                item.photo = item_people?.photo
                  ? item_people.photo
                  : `https://bitsofco.de/content/images/2018/12/broken-1.png`;
                arr_people.push(item);

                // let item_people = people.val();
                // item.photo = item_people?.photo;
                // tamp_arr_people.push(item);
                // let sortedObj = tamp_arr_people.sort(function (a, b) {
                //     return new Date(b.message.time) - new Date(a.message.time);
                // });

                // arr_people = sortedObj;
              });
            }
          }
        });
        setTimeout(() => {
          this.dataChatToko = [...new Set(arr_people)];
          this.isFinish = true;
          if (this.paramToChat) {
            this.showListChat = true;
            this.reqChatToko.id = this.paramToChat.id;
            this.reqChatToko.name = this.paramToChat.name;
            this.reqChatToko.chat = this.paramToChat.chat;
            this.reqChatToko.amount = 0;
            this.listChatbyOutside(this.paramToChat);
          }
        }, 1000);
      });
    },
    listChatbyOutside(params) {
      this.showListChat = true;
      // if (this.paramToChat.id !== '' && this.paramToChat.order ) {
      //     this.showCardChat = true;
      // }
      if (
        localStorage.getItem("paramToChat") &&
        this.paramToChat &&
        this.paramToChat.order
      ) {
        this.showCardChat = true;
      }
      if (
        localStorage.getItem("paramToChat") &&
        this.paramToChat &&
        this.paramToChat.product
      ) {
        this.showCardChat = true;
      }
      this.reqChatToko.id = params.id;
      this.reqChatToko.name = params.name;
      this.reqChatToko.chat = params.chat;
      this.showChat();
      localStorage.removeItem("paramToChat");
    },
    listChat(index) {
      // console.log('aaa',this.paramToChat);

      this.showListChat = true;
      this.showCardChat = false;

      this.dataIndexToko = index;
      this.reqChatToko.id = this.dataChatToko[index].id;
      this.reqChatToko.name = this.dataChatToko[index].name;
      this.reqChatToko.chat = this.dataChatToko[index].chat;
      this.reqChatToko.photo = this.dataChatToko[index].photo;
      this.reqChatToko.amount = this.dataChatToko[index].amount;

      if (this.paramToChat && this.paramToChat.id !== "") {
        this.paramToChat.id = this.dataChatToko[index].id;
        this.paramToChat.name = this.dataChatToko[index].name;
        this.paramToChat.chat = this.dataChatToko[index].chat;
      }

      this.showChat();
    },
    showChat() {
      const uid = this.dataUser.uid;
      const uidToko = this.reqChatToko.id;
      const nameToko = this.reqChatToko.name;
      const dataChat = this.reqChatToko.chat;
      const database = firebase.database();

      const uidBuyer = this.user.uid;
      const nameBuyer = this.user.name;
      const tokoAmount = this.reqChatToko.amount;
      database.ref(`friend/${uid}/${uidToko}/`).update({
        amount: 0,
      });
      database
        .ref(`people/${uid}`)
        .once("value")
        .then((snapshot) => {
          let item = snapshot.val();
          if (tokoAmount >= item.notif.chat) {
            database.ref(`/people/${uid}/notif`).update({
              chat: 0,
            });
          } else {
            database.ref(`/people/${uid}/notif`).update({
              chat: item.notif.chat - tokoAmount,
            });
          }
        });
      const dataSnap = {
        isTrue: false,
        data: "",
      };
      database
        .ref(`messages`)
        .child(dataChat)
        .on("value", function (snapshoot) {
          if (snapshoot.val() !== null) {
            const values = Object.values(snapshoot.val());
            const newSnap = values
              .sort((a, b) => (a.time < b.time ? 1 : -1))
              .reverse()
              .map((res) => {
                return res;
              });
            dataSnap.data = newSnap;
            dataSnap.isTrue = true;
          }
        });
      this.listChatPerToko = dataSnap;
      setTimeout(() => this.setScrollTop(), 500);
    },
    setScrollTop() {
      var elmnt = document.getElementById("list-chat-customer");
      elmnt.scrollTop = elmnt.scrollHeight;
      document.getElementById("strChat").value = "";

      this.clearParamsChat();
    },
    inputChat() {
      this.strChat = event.target.value;
    },
    clearParamsChat() {
      this.message.image = "";
      this.message.read = false;
      this.message.from = "";
      this.message.message = "";
      this.message.order = null;
      this.message.priceDiscount = "";
      this.message.priceFirst = "";
      this.message.priceLast = "";
      this.message.productImage = "";
      this.message.productTitle = "";
      this.message.productSlug = "";
    },
    sendChat() {
      // console.log('this.strChat.length', this.strChat.length)
      this.strChat.length ? this.runSendChat() : "";
      this.hideOrShowPreview("add");
    },
    gotoDetailProduct(slug) {
      this.$router.push(`/home/DetailProduk/${slug}`);
    },
    gotoDetailPesanan(slug) {
      this.$router.push(`/order/${slug}`);
    },
    getString() {
      document.getElementById("strChat").value = event.target.innerHTML;
    },
    hideOrShowPreview(params) {
      const contentPreview = document.getElementsByClassName("preview-img")[0];
      params == "remove"
        ? contentPreview.classList.remove("d-none")
        : contentPreview.classList.add("d-none");
    },
    uploadGambar() {
      const typeImage = ["jpg", "JPG", "jpeg", "png"];
      const jenisData = event.target.files[0].type.split("/")[0];
      const tipeData = event.target.files[0].type.split("/")[1];
      this.hideOrShowPreview("remove");

      if (jenisData == "image") {
        const res = typeImage.filter((item) => {
          if (tipeData == item) {
            return true;
          }
        });
        if (res.length > 0) {
          if (event.target.files[0].size < 100000) {
            const reader = new FileReader();
            let rawImg;
            reader.onloadend = () => {
              rawImg = reader.result;
              const resUrl = this.getUrlImage(rawImg);
              this.previewImage = rawImg;
              this.showCardChat = true;
            };
            reader.readAsDataURL(event.target.files[0]);
          } else {
            this.$swal.fire({
              icon: "warning",
              title: "File Terlalu Besar",
              text: "Maksimal ukuran file adalah 100 Kb",
              confirmButtonText: "OK",
            });
          }
        }
      } else {
        this.$swal.fire({
          icon: "warning",
          title: "Jenis File Tidak Sesuai",
          text: "Mohon untuk mengupload gambar dengan tipe file image",
          confirmButtonText: "OK",
        });
      }
    },
    async getUrlImage(params) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=3jj2gelqr7k1pgt00mekej9msvt8evts");
      let raw = JSON.stringify({
        image: params,
      });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      await fetch(
        `${process.env.VUE_APP_SERVICE_URL}chat/image`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            console.log("this.message.image before", res.data.url);
            this.message.image = res.data.url;
            console.log("this.message.image after", this.message.image);
            //    return this.message.image = res.data.url
          }
          // try {
          //     return res
          // } catch (error) {
          //     return false
          // }
        })
        .catch((error) => {
          return error;
        });
    },
    runSendChat() {
      const uidBuyer = this.user.uid;
      const uidSeller = this.reqChatToko.id;
      const concatUidBuyerAndSeller = uidBuyer.concat(uidSeller);
      const database = firebase.database();
      const nameToko = this.reqChatToko.name;
      const nameBuyer = this.user.name;
      this.message.from = uidBuyer;
      let notif = "";
      database.ref(`people/${uidSeller}`).on("value", function (snapshot) {
        let item = snapshot.val();
        if (item) {
          if (item.notif) {
            notif = item.notif;
          }
        }
      });
      if (this.paramToChat?.id !== undefined && this.paramToChat?.order) {
        this.message.order = this.paramToChat?.order;
      }
      if (this.paramToChat?.id !== undefined && this.paramToChat?.product) {
        // console.log('dari m', this.paramToChat);
        this.message.priceDiscount = this.paramToChat
          ? this.paramToChat.product.priceDiscount
          : "";
        this.message.priceFirst = this.paramToChat
          ? this.paramToChat.product.priceFirst
          : "";
        this.message.priceLast = this.paramToChat
          ? this.paramToChat.product.priceLast
          : "";
        this.message.productImage = this.paramToChat
          ? this.paramToChat.product.image
          : "";
        this.message.productTitle = this.paramToChat
          ? this.paramToChat.product.name
          : "";
        this.message.productSlug = this.paramToChat
          ? this.paramToChat.product.slug
          : "";
      }
      // this.message.product = this.paramToChat?.product
      this.message.message = this.strChat;
      const isiChat = this.message.message;
      let message = this.message;
      const gambar = this.message.image;
      console.log("this.message.image eeeeeeee", gambar);
      let msgId = database
        .ref("messages")
        .child(concatUidBuyerAndSeller)
        .push().key;
      database
        .ref("messages/" + concatUidBuyerAndSeller + "/" + msgId)
        .set(message)
        .then((res) => {
          this.showCardChat = false;
          if (this.paramToChat) {
            this.paramToChat.order = {};
            this.paramToChat.product = {
              priceDiscount: "",
              priceFirst: "",
              priceLast: "",
              image: "",
              name: "",
              slug: "",
            };
            // console.log('m',message);
          }
        });
      database.ref(`/people/${uidSeller}/notif`).update({
        chat: notif.chat + 1,
      });
      this.notifNew = notif;

      database
        .ref(`friend/${uidBuyer}/${uidSeller}`)
        .set({
          chat: concatUidBuyerAndSeller,
          name: nameToko,
          message: {
            text: gambar !== "" ? `Mengirim gambar` : isiChat,
            time: firebase.database.ServerValue.TIMESTAMP,
          },
          amount: 0,
        })
        .then(() => {
          database
            .ref(`friend/${uidSeller}/${uidBuyer}`)
            .once("value")
            .then((snapshot) => {
              let itemx = snapshot.val();
              database
                .ref(`friend/${uidSeller}/${uidBuyer}`)
                .set({
                  chat: concatUidBuyerAndSeller,
                  name: nameBuyer,
                  message: {
                    text: gambar !== "" ? `Mengirim gambar` : isiChat,
                    time: firebase.database.ServerValue.TIMESTAMP,
                  },
                  amount: itemx.amount + 1,
                })
                .then(() => {});
            });
        });

      setTimeout(() => this.setScrollTop(), 500);
    },
    logout() {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda akan logout?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Logout",
          denyButtonText: "Cencel",
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runLogout();
          }
          // else if (result.isDenied) {
          //   this.$swal.fire("Cancel", "Anda tidak Logout", "warning");
          // }
        });
      // localStorage.clear();
      // window.location.href = "${this.domainURL}"
    },
    runLogout() {
      localStorage.clear();
      setTimeout(() => this.runRedirect(), 500);
    },
    runRedirect() {
      let url = new URL(this.dataUrl.url);
      window.location.href = url.origin;
      // this.$router.push('/')
    },
  },
};
</script>
<style lang="scss">
.imguseravatar {
  width: 60px;
  height: 60px;
}
.bg-orange-soft-jaja {
  background: #fedeac !important;
}
.card-header-sidbar {
  border-radius: 10px;
}
.bg-biru-jaja {
  background: rgb(100, 175, 200);
}
.rm-bullet {
  list-style-type: none;
  width: 290px;
}
.kotak-list {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  width: 230px;
  // position: relative;
  left: -9px;
  color: rgb(153, 150, 150);
}
.parent-list-account {
  position: relative;
  top: -10px;
}
.list-sidebar {
  border-radius: 10px;
}
.list-sidebar span {
  color: rgb(153, 150, 150);
}
.card-header-body-sidebar {
  border-radius: 10px 10px 0 0;
}
.card-footer-body-sidebar {
  border-radius: 0 0 10px 10px;
}
.bg-chat {
  background-color: #f2f2f2;
}
.bg-list-chat {
  background-color: white;
  max-height: 100%;
  height: 80%;
}
.imgtokoavatar {
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}
.preview-img {
  // background: red;
  // position:absolute;
  // z-index: 1;
  // bottom: 100px;
  // left: 50px;
  // width: 100px;
  // height: 100px;
  // background: blue;
}
.preview-img div {
  // position: relative;
  // background: rgb(160, 215, 240);
  // width: 100px;
  // height: 100px;
}
.preview-img div img {
  // position: absolute;
  // top:10px;
  // left: 10px;
  // width: 80px;
  // height: 80px;
  // object-fit: cover;
}
.listToko {
  background-color: white;
}
.listToko:hover {
  cursor: pointer;
  background-color: #fedeac;
}
.color-buyer {
  background-color: #64b0c9;
  /* color:white; */
}
.color-seller {
  background-color: #e9eaef;
  color: black;
}
#list-chat-customer {
  overflow: auto;
  height: 100%;
  position: relative;
}
.nav-chat-user {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 69.5vw;
  background: #f4f2f2;
}
.image-send {
  height: 100px;
  width: 100px;
}
.fileUpload {
  position: absolute;
  width: 50%;
  overflow: hidden;
  margin-top: 10%;
  opacity: 0.1;
}
.card-list-seller {
  overflow: auto;
}
.custom-margin-date-chat-buyer {
  margin-top: -4vh;
  margin-right: 4vw;
}
.custom-margin-date-chat-seller {
  margin-top: -4vh;
  margin-left: 4vw;
}
.shadow-custom-chat-right {
  box-shadow: rgb(0 0 0 / 50%) -8px 8px 10px -8px !important;
}
.bordr-custom-chat {
  border-radius: 10px;
  border: 2px solid #64b0c9 !important;
}
.c-body-45 {
  height: 55vh !important;
}
.c-body-65 {
  height: 70vh !important;
}
.c-footer-25 {
  height: 22vh !important;
}
.c-footer-35 {
  height: 36vh !important;
}
.w-col-100 {
  height: 100vh;
}
.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 0px;
}
</style>
<template>
  <div>
    <div class="nav_pos">
      <div class=""></div>
      <nav class="navbar navbar-expand-lg navbar-light warnabirujaja py-0">
        <div class="container">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse bg-default p-2">
            <a
              class="bg-white p-1 rounded text-dark text-decoration-none btn"
              href="https://play.google.com/store/apps/details?id=com.jajaidbuyer"
            >
              <small class="font-weight-bold">
                <i class="fas fa-mobile-alt"></i> Download Jaja Apps
              </small>
            </a>

            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a
                  class="
                    bg-white
                    p-1
                    rounded
                    text-dark text-decoration-none
                    btn
                    font-weight-bold
                    mr-2
                  "
                  href="https://jaja.id/help"
                >
                  <small class="font-weight-bold">
                    <i class="fas fa-question-circle"></i> Bantuan
                  </small>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="
                    bg-white
                    p-1
                    rounded
                    text-dark text-decoration-none
                    btn
                    font-weight-bold
                  "
                  href="https://jaja.id/mulai-jualan"
                >
                  <small class="font-weight-bold"> Mulai Jualan di Jaja </small>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div
        id="wrapper"
        class="
          wrapper-fluid
          banners-effect-10
          BlurNavbarJaja
          shadow-custom
          bg-white
        "
      >
        <header id="header" class="typeheader-4">
          <div class="header-middle">
            <div class="container">
              <table align="center" border="0" width="100%">
                <tbody>
                  <tr>
                    <td width="17%">
                      <div class="logo">
                        <router-link :to="'/'">
                          <img
                            src="https://jaja.id/asset/home/logo/jaja-logo.png"
                            title="Jaja ID Toko Online Terlengkap di Indonesia"
                            alt="Jaja ID Toko Online Terlengkap di Indonesia"
                            height="85px"
                          />
                        </router-link>
                      </div>
                    </td>
                    <td>
                      <div
                        id="sosearchpro"
                        class="so-search"
                        style="margin-left: 8%"
                      >
                        <form @submit="pencarianEnter()">
                          <div id="" class="searchPencarian input-group mr-4">
                            <input
                              class="
                                triggerMiddleware
                                form-control
                                border-top-1
                              "
                              @click="pencarianData()"
                              @keypress="pencarianData()"
                              @keyup="pencarianData()"
                              v-model="srcData"
                              id="inputPencarian"
                              type="text"
                              placeholder="Cari produk dan toko di jaja "
                              autocomplete="off"
                            />
                            <span class="input-group-btn mr-4">
                              <button
                                type="button"
                                @click="pencarianEnter()"
                                class="button-search btn btn-warning btn-border"
                              >
                                <img
                                  src="https://nimda.jaja.id/asset/icon-jaja/luv.png"
                                  style="
                                    width: 23px;
                                    filter: brightness(0) invert(100%);
                                  "
                                />
                              </button>
                            </span>
                          </div>
                          <div class="triggerMiddleware" v-if="isOpen">
                            <!-- {{hiddenCardPencarian}} -->
                            <ul
                              class="
                                triggerMiddleware
                                list-group
                                ul-pencarian
                                shadow-custom
                              "
                              style="max-width: 580px !important"
                            >
                              <div
                                v-for="(item, index) in dataCombine"
                                :key="index"
                              >
                                <label
                                  class="
                                    triggerMiddleware
                                    d-flex
                                    container
                                    font-weight-bold
                                    p-2
                                    text-white
                                  "
                                  style="
                                    background-color: #65b0c9 !important;
                                    margin-bottom: 0px;
                                    border-radius: 2px;
                                  "
                                  for=""
                                  >{{
                                    index == 0
                                      ? `Toko`
                                      : index == 1
                                      ? `Kategori`
                                      : index == 2
                                      ? `Produk`
                                      : ``
                                  }}</label
                                >
                                <div
                                  :class="
                                    index
                                      ? index == 1
                                        ? `triggerMiddleware cardkategori `
                                        : index == 2
                                        ? `triggerMiddleware cardproduk `
                                        : ``
                                      : `triggerMiddleware cardtoko `
                                  "
                                  style="margin-bottom: 15px"
                                >
                                  <div
                                    v-if="item.length > 0"
                                    class="triggerMiddleware"
                                    style="width: 100%; text-align: left"
                                  >
                                    <div v-for="(a, b) in item" :key="b">
                                      <template v-if="index == 0">
                                        <li
                                          @click="
                                            setPage(index, a.slug, a.name)
                                          "
                                          class="
                                            triggerMiddleware
                                            tobepointer
                                            list-group-item
                                            d-flex
                                            font-weight-bold
                                          "
                                          style="cursor: pointer"
                                        >
                                          <span class="mr-3"
                                            ><img
                                              :src="a.image"
                                              width="30"
                                              height="30"
                                              alt=""
                                            />
                                          </span>
                                          {{ a.name }}
                                        </li>
                                      </template>
                                      <template v-else-if="index == 1">
                                        <li
                                          @click="
                                            setPage(index, a.slug, a.name)
                                          "
                                          class="
                                            triggerMiddleware
                                            tobepointer
                                            list-group-item
                                            d-flex
                                            font-weight-bold
                                          "
                                          style="cursor: pointer"
                                        >
                                          <span class="mr-3"
                                            ><img
                                              :src="a.icon"
                                              width="30"
                                              height="30"
                                              alt=""
                                            />
                                          </span>
                                          {{ a.name }}
                                        </li>
                                      </template>
                                      <template v-else-if="index == 2">
                                        <li
                                          @click="
                                            setPage(index, a.slug, a.name)
                                          "
                                          class="
                                            triggerMiddleware
                                            tobepointer
                                            list-group-item
                                            d-flex
                                            font-weight-bold
                                          "
                                          style="cursor: pointer"
                                        >
                                          <span class="mr-3"> </span>
                                          {{ a.name }}
                                        </li>
                                      </template>
                                    </div>
                                  </div>

                                  <div v-else>
                                    <li
                                      class="
                                        triggerMiddleware
                                        list-group-item
                                        d-flex
                                      "
                                    >
                                      Data tidak ditemukan
                                    </li>
                                  </div>
                                </div>
                              </div>
                            </ul>
                          </div>
                          <button
                            type="button"
                            style="display: none"
                            class="triggerMiddleware triggerMiddlewareEnter"
                          ></button>
                        </form>
                        <div v-click-outside="onClickOutside"></div>
                        <div v-click-outside="vcoConfig"></div>
                      </div>
                    </td>
                    <td v-if="!isLoggedIn" width="90" align="right">
                      <div class="d-flex justify-content-start bd-highlight">
                        <router-link :to="'/auth/login'">
                          <div class="bd-highlight">
                            <a class="warnabirujaja btn btn-sm text-white mr-2">
                              <span><b>Login</b></span>
                            </a>
                          </div>
                        </router-link>
                        <router-link :to="'/register'">
                          <div class="bd-highlight">
                            <a class="warnaorangejaja btn btn-sm text-white">
                              <span><b>Daftar</b></span>
                            </a>
                          </div>
                        </router-link>
                      </div>
                    </td>
                    <td v-else>
                      <div
                        class="
                          d-flex
                          justify-content-end
                          align-items-center
                          bd-highlight
                          mb-1
                          ml-4
                        "
                      >
                        <div class="bd-highlight">
                          <router-link :to="'/chat'">
                            <a class="nav-link avatar-with-name">
                              <span class="icon-top-menu">
                                <i class="fas fa-envelope"></i>
                                <span class="badge badge-warning rounded-circle"
                                  ><span id="notifc"></span
                                ></span>
                              </span>
                            </a>
                          </router-link>
                        </div>
                        <div class="bd-highlight">
                          <router-link
                            :to="'/pemberitahuan'"
                            class="nav-link avatar-with-name"
                            id="navbarDropdownMenuLink"
                          >
                            <span class="icon-top-menu">
                              <i class="fas fa-bell"></i>
                              <span class="badge badge-warning rounded-circle"
                                ><span id="notifh"></span
                              ></span>
                            </span>
                          </router-link>
                        </div>
                        <div class="bd-highlight">
                          <div class="shopping_cart">
                            <div id="cart">
                              <router-link :to="'/cart/cart'">
                                <div
                                  class="
                                    border border-info
                                    p-1
                                    avatar-with-name
                                  "
                                >
                                  <i class="fa fa-shopping-bag"></i>
                                  <span class="mr-2 ml-2">{{
                                    totalKeranjang.totalProductInCart
                                  }}</span>
                                </div>
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <div class="p-2 bd-highlight">
                          <div @click="showDropDownLogout" class="dropdown">
                            <button class="ml-2 btn btn-light dropdown-toggle">
                              <i class="fa fa-user"></i>
                              <span>&nbsp;{{ user.name }}</span>
                            </button>
                            <div
                              :class="
                                showOrHiddenLogout
                                  ? 'dropdown-content shadow-custom'
                                  : 'dropdown-content d-none'
                              "
                              style="
                                margin-left: 5%;
                                position: absolute;
                                z-index: 5;
                                background-color: #f8f9fa;
                                border-radius: 10px;
                              "
                            >
                              <div
                                class="bd-highlight mb-1"
                                style="text-align: left"
                              >
                                <div class="bd-highlight">
                                  <router-link :to="'/dashboard'">
                                    <a
                                      class="dropdown-item"
                                      style="border-radius: 5px"
                                      >Akun Saya</a
                                    >
                                  </router-link>
                                </div>
                              </div>
                              <div
                                class="bd-highlight mb-1"
                                style="text-align: left"
                              >
                                <div class="bd-highlight">
                                  <router-link :to="'/listorder'">
                                    <a
                                      class="dropdown-item"
                                      style="border-radius: 5px"
                                      >Pesanan Saya</a
                                    >
                                  </router-link>
                                </div>
                              </div>
                              <div
                                class="bd-highlight mb-1"
                                style="text-align: left"
                              >
                                <div class="bd-highlight">
                                  <a
                                    @click="logout"
                                    class="dropdown-item"
                                    style="cursor: pointer; border-radius: 5px"
                                    >Logout</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- //Header center -->

          <!-- Header Bottom -->

          <div class="header-bottom hidden-compact">
            <div class="container">
              <div class="row">
                <div class="bottom1 menu-vertical col-lg-2 col-md-3">
                  <div class="responsive so-megamenu megamenu-style-dev">
                    <div class="so-vertical-menu">
                      <nav class="navbar-default">
                        <div class="container-megamenu vertical">
                          <div id="menuHeading">
                            <div class="megamenuToogle-wrapper">
                              <div class="megamenuToogle-pattern">
                                <div
                                  class="
                                    d-flex
                                    justify-content-start
                                    bd-highlight
                                  "
                                >
                                  <div class="p-1 bd-highlight" style="">
                                    <div
                                      @mouseover="mouseMasuk()"
                                      @mouseleave="mouseKeluar()"
                                      class="
                                        megamenu-btn
                                        warnabirujaja
                                        tab-kategori
                                        text-white
                                        font-weight-bold
                                      "
                                      style="
                                        width: 100px !important;
                                        padding-top: 5px;
                                        padding-bottom: 5px;
                                      "
                                    >
                                      <i class="fa fa-bars"></i>
                                      <small
                                        class="font-weight-bold"
                                        style="font-size: 85%"
                                      >
                                        &nbsp; Kategori</small
                                      >
                                    </div>

                                    <div
                                      class="row"
                                      :class="
                                        cekMouseKategori
                                          ? 'megamenu list-unstyled data-list-kategory'
                                          : 'd-none'
                                      "
                                      @mouseover="mouseMasuk()"
                                      @mouseleave="mouseKeluar()"
                                    >
                                      <div class="col-md-6">
                                        <ul
                                          style="
                                            overflow-y: scroll;
                                            max-height: 450px;
                                            position: absolute;
                                            z-index: 999;
                                            width: 222%;
                                            left: -25px;
                                          "
                                        >
                                          <ul class="list-group">
                                            <div
                                              v-for="(
                                                item, index
                                              ) in masterKategori"
                                              :key="index"
                                            >
                                              <li
                                                @click="
                                                  gotoDetailProdukNavbar(
                                                    item.slug
                                                  )
                                                "
                                                @mouseover="
                                                  mouselistover(index)
                                                "
                                                :id="'active' + index"
                                                :class="
                                                  index == indexParentActive
                                                    ? 'list-group-item active text-dark'
                                                    : 'list-group-item text-dark'
                                                "
                                                style="
                                                  cursor: default;
                                                  font-size: 85%;
                                                "
                                              >
                                                {{ item.name }}
                                              </li>
                                            </div>
                                          </ul>
                                        </ul>
                                      </div>
                                      <div
                                        class="col-md-6"
                                        v-if="
                                          Object.keys(childCategori.data)
                                            .length > 0
                                        "
                                      >
                                        <div
                                          style="
                                            position: absolute;
                                            left: 118%;
                                            width: 200px;
                                            height: 450px;
                                            background: white;
                                            z-index: 999;
                                            left: 84%;
                                          "
                                        >
                                          <ul class="list-group">
                                            <div
                                              v-for="(
                                                item, index
                                              ) in childCategori.data"
                                              :key="index"
                                            >
                                              <li
                                                @click="
                                                  gotoDetailProdukNavbar(
                                                    item.slug
                                                  )
                                                "
                                                @mouseover="
                                                  mouselistchildover(index)
                                                "
                                                @mouseleave="
                                                  mouselistchildleave(index)
                                                "
                                                class="
                                                  list-group-item
                                                  child-megamenu
                                                  text-dark
                                                "
                                                :id="'childActive' + index"
                                                style="
                                                  cursor: default;
                                                  font-size: 85%;
                                                "
                                              >
                                                {{ item.name }}
                                              </li>
                                            </div>
                                          </ul>
                                        </div>
                                      </div>
                                      <div v-else></div>
                                    </div>
                                  </div>
                                  <div class="p-1 bd-highlight">
                                    <router-link
                                      :to="'/home/DetailToko/jaja-official'"
                                    >
                                      <div
                                        class="
                                          megamenu-btn
                                          warnabirujaja
                                          tab-kategori
                                          text-white
                                          font-weight-bold
                                        "
                                        style="
                                          width: 110px !important;
                                          padding-top: 5px;
                                          padding-bottom: 5px;
                                        "
                                      >
                                        <img
                                          src="https://nimda.jaja.id/asset/icon-jaja/diamond.png"
                                          width="16px"
                                          alt=""
                                        />
                                        <small
                                          class="font-weight-bold"
                                          style="font-size: 85%"
                                        >
                                          Jaja Official</small
                                        >
                                      </div>
                                    </router-link>
                                  </div>
                                  <div class="p-1 bd-highlight">
                                    <router-link
                                      :to="'/home/DetailToko/eureka-bookhouse'"
                                    >
                                      <div
                                        class="
                                          megamenu-btn
                                          warnabirujaja
                                          tab-kategori
                                          text-white
                                          font-weight-bold
                                        "
                                        style="
                                          width: 150px !important;
                                          padding-top: 5px;
                                          padding-bottom: 5px;
                                        "
                                      >
                                        <img
                                          src="https://nimda.jaja.id/asset/icon-jaja/diamond.png"
                                          width="16px"
                                          alt=""
                                        />
                                        <small
                                          class="font-weight-bold"
                                          style="font-size: 85%"
                                        >
                                          Eureka Bookhouse</small
                                        >
                                      </div>
                                    </router-link>
                                  </div>
                                  <div class="p-1 bd-highlight">
                                    <router-link
                                      :to="'/home/DetailToko/master-diskon'"
                                    >
                                      <div
                                        class="
                                          megamenu-btn
                                          warnabirujaja
                                          tab-kategori
                                          text-white
                                          font-weight-bold
                                        "
                                        style="
                                          width: 120px !important;
                                          padding-top: 5px;
                                          padding-bottom: 5px;
                                        "
                                      >
                                        <img
                                          src="https://nimda.jaja.id/asset/icon-jaja/diamond.png"
                                          width="16px"
                                          alt=""
                                        />
                                        <small
                                          class="font-weight-bold"
                                          style="font-size: 85%"
                                        >
                                          Master Diskon</small
                                        >
                                      </div>
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="vertical-wrapper">
                            <!-- <div class="megamenu-pattern"> -->
                            <div class="">
                              <div class="container-mega">
                                <!-- <div class="list-child" style="position: absolute; left: 98%; width: 200px; height: 500px; background: white;">

                          </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
    <!-- <div class="box-penambal">

    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      srcData: "",
      typingTimer: "",
      doneTypingInterval: 700,
      dataCombine: [],
      hiddenCardPencarian: false,
      dataToko: {},
      dataCategory: {},
      dataProduct: {},
      dataDitemukan: {},
      masterKategori: {},
      cekMouseKategori: false,
      showOrHiddenLogout: false,
      childCategori: {
        length: 0,
        data: "",
      },
      indexParentActive: 0,

      vcoConfig: {
        handler: this.handler,
        middleware: this.middleware,
        events: ["dblclick", "click"],
        // Note: The default value is true, but in case you want to activate / deactivate
        //       this directive dynamically use this attribute.
        isActive: true,
        // Note: The default value is true. See "Detecting Iframe Clicks" section
        //       to understand why this behaviour is behind a flag.
        detectIFrame: true,
      },
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters({ isLoggedIn: "isLoggedIn" }),
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ totalKeranjang: "infoGetters" }),
    ...mapGetters({ user: "user" }),
    ...mapGetters({
      dataUrl: "urlGetters",
    }),
  },
  created() {
    this.srcData = this.srcData === "" ? this.$route.params.id : "";
  },
  beforeMount() {
    this.getInfo(), this.getMasteKategori();
    // this.escapeFunction()
  },
  methods: {
    onClickOutside(event) {},
    handler(event) {
      this.isOpen = false;
    },
    middleware(event) {
      return event.target.className.split(" ")[0] !== "triggerMiddleware";
    },
    escapeFunction() {
      // this.hiddenCardPencarian=true
      // alert('dsadsa')
      // let that = this;
      // document.addEventListener('keyup', function (evt) {
      //     if (evt.keyCode === 27) {
      //       console.log(this.hiddenCardPencarian)
      //       this.hiddenCardPencarian=true
      //     }
      // });
    },
    onEnter(e) {
      this.$router.push("/product-search/" + e);
    },
    pencarianEnter() {
      this.$router.push("/product-search/" + this.srcData);
      // document.getElementsByClassName('ul-pencarian')[0].style.display = 'none'
    },
    pencarianData() {
      this.isOpen = true;
      this.srcData = event.target.value;
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => this.doneTyping(this.srcData), 500);
    },
    doneTyping(string) {
      let myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=vah7ivbaoqeus4qfh89d7c8o2q55216c");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}product/search?limit=5&keyword=${string}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          this.dataCombine = [];
          this.hiddenCardPencarian = false;
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.dataToko = res.data.store;
            this.dataCategory = res.data.category;
            this.dataProduct = res.data.product;
            this.dataDitemukan = true;
            this.dataCombine.push(this.dataToko);
            this.dataCombine.push(this.dataCategory);
            this.dataCombine.push(this.dataProduct);
          } else {
            this.dataDitemukan = false;
            this.dataProduct = [];
            this.dataCategory = [];
            this.dataProduct = [];
            this.dataCombine.push(this.dataToko);
            this.dataCombine.push(this.dataCategory);
            this.dataCombine.push(this.dataProduct);
          }
        });
    },
    getInfo() {
      this.$store.dispatch("getInfo");
    },
    getMasteKategori() {
      let myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=mgqcv1a854uuigbeae07b3hue1hcdgo8");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}master/category`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.masterKategori = res.data;
          }
        })
        .catch((error) => console.log("error", error));
    },
    mouseMasuk() {
      this.cekMouseKategori = true;
    },
    mouseKeluar() {
      this.cekMouseKategori = false;
    },
    mouselistover(index) {
      this.indexParentActive = index;
      this.childCategori.length = this.masterKategori[index].children.length;
      this.childCategori.data = this.masterKategori[index].children;
    },
    gotoDetailProdukNavbar(slug) {
      this.$router.push("/kategori/" + slug);
      this.cekMouseKategori = false;
    },
    mouselistchildover(index) {
      var element = document.getElementById("childActive" + index);
      element.classList.add("active");
      this.cekMouseKategori = true;
    },
    mouselistchildleave(index) {
      var element = document.getElementById("childActive" + index);
      element.classList.remove("active");
    },
    setPage(index, slug, name) {
      if (index == 0) {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/home/DetailToko/${slug}`);
      } else if (index == 1) {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/kategori/${slug}`);
      } else {
        this.isOpen = false;
        this.srcData = name;
        this.$router.push(`/home/DetailProduk/${slug}`);
      }
      this.dataCombine = [];
    },
    logout() {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda akan keluar?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Keluar",
          denyButtonText: `Batalkan`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runLogout();
          }
          // else if (result.isDenied) {
          //   this.$swal.fire("Cancel", "Anda tidak Logout", "warning");
          // }
        });
      // localStorage.clear();
      // window.location.href = "${this.domainURL}"
    },
    runLogout() {
      // this.$swal.fire('sukses', 'anda berhasil logout', 'success')
      localStorage.clear();
      setTimeout(() => this.runRedirect(), 500);
    },
    runRedirect() {
      let url = new URL(this.dataUrl.url);
      window.location.href = url.origin;
    },
    showDropDownLogout() {
      this.showOrHiddenLogout
        ? (this.showOrHiddenLogout = false)
        : (this.showOrHiddenLogout = true);
    },
  },
};
</script>
<style lang="scss" scoped>
.nav_pos {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background: white;
}
.box-penambal {
  width: 100vh;
  /* height:23vh; */
  background: yellow;
  /* margin-bottom:1%; */
}
/* .ul-pencarian {
    position: absolute;
    z-index: 999;
    background: white;
    width: 55%;
} */
.ul-pencarian {
  // margin-left:3.4%;
  margin-left: 0;
  position: absolute;
  z-index: 999;
  background: white;
  width: 38%;
  box-shadow: 5px 10px #888888;
  padding: 30px;
}
.card-body {
  background-color: white;
}
.tobepointer:hover {
  cursor: pointer;
}
.cardtoko {
  max-height: 150px;
  overflow: scroll;
}
.cardkategori {
  max-height: 150px;
  overflow: scroll;
}
.cardproduk {
  max-height: 150px;
  overflow: scroll;
}
.warnabirujaja {
  background: #65b0c9 !important;
}
.warnatextbirujaja {
  color: #65b0c9 !important;
}
.warnaorangejaja {
  background: #fdb738 !important;
}
.logo img {
  height: 100px;
}

.searchPencarian {
  // margin-left:8%;
  margin-left: 0;
  width: 600px;
}
.searchPencarian input {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
}
.tab-kategori {
  width: 150px;
  padding: 1px;
  border-radius: 10px 10px 0 0;
}

/* CUSTOM BY MALA */
.btn-border {
  border-radius: 0rem 0.25rem 0.25rem 0rem !important;
  border: 1px solid #ffc107;
  /* border-top: 1px solid #495057; 
  border-bottom: 1px solid #495057; 
  border-right: 1px solid #495057; */
}
/* width */
::-webkit-scrollbar {
  height: 1px;
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.list-group-item.active {
  z-index: 2;
  color: #fff !important;
  background-color: rgb(101, 176, 201) !important;
  border-color: rgb(101, 176, 201) !important;
}

.avatar-with-name {
  color: #65b0c9 !important;
}

.dropdown-item:hover {
  background-color: #65b0c9 !important;
  color: white;
}
</style>
<template>
  <div>
    <div
      class="
        d-flex
        flex-column
        bd-highlight
        bg-orange-soft-jaja
        py-3
        card-header-sidbar
        mb-2
      "
    >
      <div class="bd-highlight mb-2" v-if="isFinish">
        <img
          class="rounded-circle square-80 imguseravatar bg-biru-jaja"
          :src="
            userComp.image
              ? userComp.image
              : `https://nimda.jaja.id/asset/icon-jaja/user%20jaja.png`
          "
          alt="avatar"
        />
      </div>
      <div class="bd-highlight mb-2" v-else>
        <content-placeholders :rounded="true" :centered="true">
          <content-placeholders-img
            class="rounded-circle square-80 imguseravatar"
            style="width: 80px; height: 80px"
          />
        </content-placeholders>
      </div>
      <div class="bd-highlight" v-if="isFinish">
        <span class="font-weight-bold h5">{{ userComp.name }}</span>
      </div>
      <div class="bd-highlight" v-else>
        <span class="font-weight-bold h5"> Name </span>
      </div>
      <div class="bd-highlight">
        <img
          src="https://jaja.id/asset/front/images/koin.png"
          alt="coin-gif"
          width="32"
        />
        <span class="nilaiKoin font-weight-bold" v-if="isFinish">
          {{ userComp.coinFormat }}
        </span>
        <span class="nilaiKoin font-weight-bold" v-else> Coin </span>
      </div>
      <div class="bd-highlight">
        <a href="https://jaja.id/mulai-jualan"
          ><small class="font-weight-bold text-dark">Jajakan Produkmu</small></a
        >
      </div>
    </div>

    <div
      class="
        d-flex
        justify-content-start
        bd-highlight
        card-header-body-sidebar
        bg-biru-jaja
        pt-2
      "
    >
      <div class="bd-highlight ml-4 mr-3">
        <i class="fa fa-user h3 text-white" aria-hidden="true"></i>
      </div>
      <div class="bd-highlight text-white font-weight-bold h6 mt-auto mb-auto">
        Akun Saya
      </div>
    </div>

    <div class="bg-orange-soft-jaja card-footer-body-sidebar py-3 px-2">
      <router-link :to="'/dashboard'">
        <div class="bg-white p-2 text-left mb-2 list-sidebar">
          <span class="ml-2 font-weight-bold text-dark font-weight-bold"
            >Dashboard</span
          >
        </div>
      </router-link>
      <router-link :to="'/profil'">
        <div class="bg-white p-2 text-left mb-2 list-sidebar">
          <span class="ml-2 font-weight-bold text-dark">Profil</span>
        </div>
      </router-link>

      <router-link :to="'/address'">
        <div class="bg-white p-2 text-left mb-2 list-sidebar">
          <span class="ml-2 font-weight-bold text-dark">Alamat</span>
        </div>
      </router-link>

      <router-link :to="'/gantipassword'">
        <div class="bg-white p-2 text-left mb-2 list-sidebar">
          <span class="ml-2 font-weight-bold text-dark">Ganti Password</span>
        </div>
      </router-link>

      <router-link :to="'/pemberitahuan'">
        <div class="bg-white p-2 text-left mb-2 list-sidebar">
          <span class="ml-2 font-weight-bold text-dark">Pemberitahuan</span>
        </div>
      </router-link>

      <router-link :to="'/rekening'">
        <div class="bg-white p-2 text-left mb-2 list-sidebar">
          <span class="ml-2 font-weight-bold text-dark">Rekening Anda</span>
        </div>
      </router-link>

      <!-- <router-link :to="'/danakembali'">
				<div class="bg-white p-2 text-left mb-2 list-sidebar">
					<span class="ml-2 font-weight-bold text-dark">Pengembalian Dana</span>
				</div>
			</router-link> -->

      <router-link :to="'/koin'">
        <div class="bg-white p-2 text-left mb-2 list-sidebar">
          <span class="ml-2 font-weight-bold text-dark">Koin</span>
        </div>
      </router-link>

      <router-link :to="'/favorite'">
        <div class="bg-white p-2 text-left mb-2 list-sidebar">
          <span class="ml-2 font-weight-bold text-dark">Favorite</span>
        </div>
      </router-link>

      <router-link :to="'/lastseen'">
        <div class="bg-white p-2 text-left list-sidebar">
          <span class="ml-2 font-weight-bold text-dark">Terakhir dilihat</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userComp: {},
      isFinish: false,
    };
  },
  components: {},
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      if (this.user.uid) {
        this.userComp = this.user;
        this.isFinish = true;
      } else {
        this.isFinish = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.imguseravatar {
  width: 60px;
  height: 60px;
}

.bg-orange-soft-jaja {
  background: #fedeac !important;
}

.card-header-sidbar {
  border-radius: 10px;
}

.bg-biru-jaja {
  background: rgb(100, 175, 200);
}

.rm-bullet {
  list-style-type: none;
  width: 290px;
}

.kotak-list {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  width: 230px;
  // position: relative;
  left: -9px;
  color: rgb(153, 150, 150);
}

.parent-list-account {
  position: relative;
  top: -10px;
}
.list-sidebar {
  border-radius: 10px;
}
.list-sidebar span {
  color: rgb(153, 150, 150);
}

.card-header-body-sidebar {
  border-radius: 10px 10px 0 0;
}

.card-footer-body-sidebar {
  border-radius: 0 0 10px 10px;
}
</style>
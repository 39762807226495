<template>
  <div>
    <div class="container p-3" v-if="isFinish">
      <div class="">
        <nav
          class="nav nav-pills nav-fill isiNav container"
          style="margin-left: -10px"
        >
          <div v-for="(item, index) in resFilter" :key="index">
            <button
              type="button"
              @click="getOrderByStatus(index, item.value)"
              :class="item.inActive ? classButton + ' active' : classButton"
            >
              <b>{{ item.title }} ({{ item.total }})</b>
            </button>
          </div>
        </nav>
      </div>
    </div>
    <div class="container p-3" v-if="!isFinish">
      <div class="">
        <nav class="nav nav-pills nav-fill isiNav" style="margin-left: -10px">
          <div v-for="index in 5" :key="index">
            <content-placeholders
              :rounded="true"
              :centered="true"
              :class="classButton"
            >
              <content-placeholders-text
                :lines="1"
                :centered="true"
                style="width: 10vw; height: 20px; padding-top: 2px"
              />
            </content-placeholders>
          </div>
        </nav>
      </div>
    </div>
    <ListOrder
      :recordsLength="recordsLength"
      :page="page"
      :perPage="perPage"
      :getListOrder="getListOrder"
      :resResultAllOrder="resResultAllOrder"
      :isFinish="isFinish"
      v-if="isFinish"
    />
    <div v-if="!isFinish">
      <div
        class="card-body tab-content p-3 container"
        style="margin-top: -20px !important"
      >
        <div class="tab-pane show active">
          <div class="row bodyHtml container">
            <div class="card-product shadow-custom mt-3 col-md-12">
              <div class="d-flex justify-content-between bd-highlight">
                <div class="p-2 bd-highlight">
                  <small class="text-primary-jaja">
                    <content-placeholders :rounded="true" :centered="true">
                      <content-placeholders-text
                        :lines="1"
                        :centered="true"
                        style="width: 10vw; height: 20px"
                      />
                    </content-placeholders>
                  </small>
                </div>
                <div class="p-2 bd-highlight">
                  <span class="text-primary-jaja text-bold float-right sml">
                    <content-placeholders :rounded="true" :centered="true">
                      <content-placeholders-text
                        :lines="1"
                        :centered="true"
                        style="width: 10vw; height: 20px"
                      />
                    </content-placeholders>
                  </span>
                </div>
              </div>

              <div>
                <div class="d-flex justify-content-start bd-highlight">
                  <div class="p-2 bd-highlight">
                    <b class="nml text-primary-jaja">
                      <content-placeholders :rounded="true" :centered="true">
                        <content-placeholders-text
                          :lines="1"
                          :centered="true"
                          style="width: 10vw; height: 20px"
                        />
                      </content-placeholders>
                    </b>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <div
                      class="d-flex justify-content-start bd-highlight"
                      style="margin-left: 30px"
                    >
                      <div class="p-2 bd-highlight">
                        <content-placeholders :rounded="true" :centered="true">
                          <content-placeholders-img
                            class="img-thumbnail img-fluid"
                            style="
                              width: 100px;
                              max-width: 100%;
                              height: 100px;
                              max-height: 100%;
                            "
                          />
                        </content-placeholders>
                      </div>
                      <div class="p-2 bd-highlight">
                        <div class="text-left">
                          <a class="text-dark">
                            <span class="mb-0"
                              ><b>
                                <content-placeholders
                                  :rounded="true"
                                  :centered="true"
                                >
                                  <content-placeholders-text
                                    :lines="1"
                                    :centered="true"
                                    style="width: 10vw; height: 20px"
                                  />
                                </content-placeholders> </b
                            ></span>
                          </a>
                          <br />
                          <span class="text-dark">
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                :centered="true"
                                style="width: 5vw; height: 20px"
                              />
                            </content-placeholders>
                          </span>
                          <span class="text-primary-jaja"
                            ><b>
                              <content-placeholders
                                :rounded="true"
                                :centered="true"
                              >
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 5vw; height: 20px"
                                />
                              </content-placeholders> </b
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex justify-content-end bd-highlight">
                      <div class="p-2 bd-highlight mt-4">
                        <span class="text-primary-jaja"
                          ><b>
                            <content-placeholders
                              :rounded="true"
                              :centered="true"
                            >
                              <content-placeholders-text
                                :lines="1"
                                :centered="true"
                                style="width: 10vw; height: 20px"
                              />
                            </content-placeholders> </b
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div class="d-flex justify-content-between bd-highlight mb-3">
                <div class="p-2 bd-highlight">
                  <button class="stardust-white cslight btn btn-warning-custom">
                    <b>
                      <content-placeholders :rounded="true" :centered="true">
                        <content-placeholders-text
                          :lines="1"
                          :centered="true"
                          style="width: 10vw; height: 20px"
                        />
                      </content-placeholders>
                    </b>
                  </button>
                </div>
                <div class="p-2 bd-highlight">
                  <b class="text-primary-jaja">
                    <content-placeholders :rounded="true" :centered="true">
                      <content-placeholders-text
                        :lines="1"
                        :centered="true"
                        style="width: 10vw; height: 20px"
                      />
                    </content-placeholders>
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <pagination
      style="display: flex; align-items: center; justify-content: center"
      :records="recordsLength"
      v-model="page"
      :per-page="perPage"
      @paginate="getListOrder"
    >
    </pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ListOrder from "../../components/listordercomponent/listordercomp";
export default {
  data() {
    return {
      reqData: {
        status: "",
      },
      resResultAllOrder: {
        data: {},
        isActiveStatus: "",
      },
      classButton:
        "btn btn-outline-warning-custom2 mb-2 mt-2 pl-2 pr-2 pt-1 pb-1",
      resFilter: {},
      // isActiveStatus:'',
      navbarActive: 0,
      isFinish: false,
      dataPage: {},
      page: 1,
      perPage: 3,
      recordsLength: 0,
      pageSizes: [12, 24, 32],
    };
  },
  components: {
    ListOrder,
  },
  computed: {
    ...mapGetters({ isLoggedIn: "isLoggedIn" }),
    ...mapGetters({ globalToken: "globalTokenGetters" }),
  },
  mounted() {
    this.getListOrder(this.page);
  },
  created() {
    this.getListOrder(this.page);
  },
  beforeMount() {},
  methods: {
    getListOrder: function (page) {
      const queryParams = this.$route.query.status;

      const qParams = queryParams ? queryParams : this.reqData.status;

      this.resResultAllOrder.data = {};
      this.resResultAllOrder.isActiveStatus = "";

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=u87cbvm2b10s1j0jvq97m2dppb2on45q");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}order?page=${this.page}&limit=${this.perPage}&status=${qParams}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          this.resResultAllOrder.data = res.data;
          this.resFilter = res.data.filters;
          const newFilter = res.data.filters.filter((item) => {
            if (item.inActive) {
              return item.value;
            }
          });
          this.resResultAllOrder.isActiveStatus = newFilter[0].value;
          this.isFinish = true;

          this.dataPage = res.data.totalPage;
          this.recordsLength = res.data.totalData;
        })
        .catch((error) => console.log("error", error));
    },
    getOrderByStatus(index, slug) {
      this.navbarActive = index;
      this.reqData.status = slug;
      this.resResultAllOrder.isActiveStatus = slug;
      this.page = 1;
      setTimeout(() => this.getListOrder(this.page), 200);
    },
  },
};
</script>

<style scoped>
/* CUSTOM RADIO BUTTON RATING PRODUCT DETAIL*/
.btn-outline-warning-custom2 {
  color: #fdb738 !important;
  background-color: #fff !important;
  border-color: #fdb738 !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 8px !important;
  font-weight: bold;
}
.btn-outline-warning-custom2:hover,
.btn-outline-warning-custom2:focus,
.btn-outline-warning-custom2:active,
.btn-outline-warning-custom2.active,
.open > .dropdown-toggle.btn-outline-warning-custom2 {
  color: #fff !important;
  background-color: #fdb738 !important;
  border-color: #fdb738 !important;
  font-weight: bold;
}
</style>
<template>
  <div>
    <div class="container style-text" v-if="isFinish">
      <div>
        <div :class="!is_non_physical ? `row` : `d-none`">
          <!-- alamat & produk -->
          <div :class="!is_non_physical ? `col-md-7` : `d-none`">
            <div class="container mt-4 style-text">
              <div
                class="card shadow-custom"
                style="border-radius: 10px !important"
              >
                <div
                  class="card-header"
                  style="border-radius: 10px 10px 0 0 !important"
                >
                  <span
                    class="mb-0 float-left fsx"
                    style="color: white; font-weight: bold"
                    >Alamat Pengiriman</span
                  >
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-5">
                      <span class="fsx"
                        ><b>{{ receiverName }}</b> <br />{{ phoneNumber }}</span
                      >
                    </div>
                    <div class="col-md-7">
                      <h6 class="">
                        <b>{{ label }}</b>
                      </h6>
                      <span class="">{{ address }}</span>

                      <div class="row mt-3">
                        <div class="d-flex justify-content-around bd-highlight">
                          <div
                            class="p-2 ml-2 bd-highlight"
                            @click="showModalAddress"
                          >
                            <small
                              style="
                                color: #64b0c9;
                                font-weight: bold;
                                font-size: 90%;
                                cursor: pointer;
                              "
                              >Alamat Pengiriman</small
                            >
                          </div>
                          <div
                            class="p-2 ml-4 bd-highlight"
                            @click="goToAddSendAddress"
                          >
                            <small
                              style="
                                color: #64b0c9;
                                font-weight: bold;
                                font-size: 90%;
                                cursor: pointer;
                              "
                              >Tambah Alamat Pengiriman</small
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(item, index) in dataCart.cart"
              :key="index"
              :class="index ? (index > 0 ? `mt-plus` : ``) : `mt-3`"
            >
              <div
                class="container style-text"
                :class="
                  Object.keys(dataCart.voucherJajaSelected).length > 0
                    ? ` `
                    : ``
                "
              >
                <div
                  class="card shadow-custom"
                  style="border-radius: 10px !important"
                >
                  <div
                    class="card-header"
                    style="border-radius: 10px 10px 0 0 !important"
                  >
                    <span
                      class="mb-0 float-left fsx"
                      style="color: white; font-weight: bold"
                    >
                      <router-link
                        :to="'/home/DetailToko/' + item.store.slug"
                        class="text-white"
                      >
                        {{ item.store.name }}
                      </router-link>
                    </span>
                  </div>
                  <div class="card-body">
                    <div class="my-2">
                      <div
                        class="list-group-item p-0"
                        style="border: 0px !important"
                      >
                        <div class="table-responsive">
                          <table
                            class="table Table_JajaID"
                            style="margin-top: -2%"
                          >
                            <tbody class="HeaderCartBody_JajaID">
                              <tr class="desktop-only">
                                <td style="width: 70%" colspan="2"></td>
                                <td
                                  class="text-center"
                                  style="
                                    width: 15%;
                                    color: #64b0c9;
                                    font-weight: bold;
                                  "
                                >
                                  Harga
                                </td>
                                <td
                                  class="text-center"
                                  style="
                                    width: 5%;
                                    color: #64b0c9;
                                    font-weight: bold;
                                  "
                                >
                                  Jumlah
                                </td>
                                <td
                                  class="text-right"
                                  style="
                                    width: 15%;
                                    color: #64b0c9;
                                    font-weight: bold;
                                  "
                                >
                                  Sub Total
                                </td>
                              </tr>
                              <tr
                                v-for="(a, b) in item.products"
                                :key="b"
                                class="desktop-only"
                              >
                                <td style="width: 15%">
                                  <img
                                    :src="a.image"
                                    class="img-responsive"
                                    style="
                                      display: inline-block;
                                      float: left;
                                      object-fit: contain;
                                    "
                                    width="60px"
                                    height="60px"
                                  />
                                </td>
                                <td
                                  style="
                                    width: 25%;
                                    color: #64b0c9;
                                    font-weight: bold;
                                  "
                                >
                                  <router-link
                                    :to="'/home/DetailProduk/' + a.slug"
                                    class="text-primary-jaja"
                                  >
                                    {{ a.name }}
                                  </router-link>
                                  <br />
                                </td>
                                <td class="text-right">
                                  <strike
                                    v-if="a.isDiscount"
                                    class="text-danger"
                                    ><small>{{
                                      a.priceCurrencyFormat
                                    }}</small></strike
                                  >

                                  {{
                                    a.isDiscount
                                      ? a.priceDiscountCurrencyFormat
                                      : a.priceCurrencyFormat
                                  }}
                                </td>
                                <td class="text-center">{{ a.qty }}</td>
                                <td
                                  class="text-right"
                                  style="
                                    text-align: right;
                                    color: #000 !important;
                                  "
                                >
                                  {{ a.subTotalCurrencyFormat }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        class="list-group-item"
                        style="border: 0px !important"
                        v-if="Object.keys(item.voucherStore).length > 0"
                      >
                        <p
                          class="
                            mb-0
                            p-2
                            btn btn-outline-danger btn-block
                            font-weight-bold
                          "
                          style="
                            border: none;
                            border: solid 2px #fb7181 !important;
                            border-radius: 10px;
                            padding: 10px;
                            text-align: center;
                          "
                          @click="showVoucherToko(index)"
                        >
                          Pakai Voucher Toko
                        </p>
                      </div>
                      <div
                        class="list-group-item"
                        style="border: 0px !important"
                      >
                        <p class="mb-0" v-if="item.voucherDiscount">
                          Voucher Toko
                          <span class="text-danger-jaja float-right">
                            {{ item.voucherDiscountCurrencyFormat }}
                          </span>
                        </p>
                        <p class="mb-0">
                          Subtotal Produk
                          <span
                            class="text-dark float-right"
                            v-if="item.voucherDiscount > 0"
                          >
                            {{ item.totalDiscountCurrencyFormat }}
                          </span>
                          <span class="text-dark float-right" v-else>
                            {{ item.totalCurrencyFormat }}
                          </span>
                        </p>
                      </div>
                      <div
                        class="list-group-item ubahPengiriman"
                        data-key="0"
                        data-store_id="349"
                        id="ongkir_barang_25"
                        style="border: 0px !important"
                      >
                        <div
                          v-if="item.shippingSelected.code !== ''"
                          class="border border-secondary p-2"
                          style="
                            border: none;
                            border: solid 1px #6c757d !important;
                            border-radius: 10px;
                            padding: 10px;
                          "
                        >
                          <a
                            @click="chooseShipping(index)"
                            style="
                              border-bottom: #222 dashed 1px;
                              cursor: pointer;
                            "
                            class="fsx text-danger"
                          >
                            <span class="fsx font-weight-bold"
                              >Ubah Pengiriman</span
                            >
                          </a>
                          <a class="float-right">{{
                            item.shippingSelected.priceCurrencyFormat
                          }}</a>
                          <br />

                          <small>
                            {{
                              item.shippingSelected.name
                                ? item.shippingSelected.name +
                                  " (" +
                                  item.shippingSelected.name +
                                  ")"
                                : `Pengiriman belum di pilih`
                            }}</small
                          >
                          <div>
                            {{
                              item.shippingSelected.name
                                ? item.shippingSelected.etdText
                                : ``
                            }}
                            <span>{{
                              item.shippingSelected.dateSendTime
                                ? item.shippingSelected.dateSendTime
                                : ``
                            }}</span>
                          </div>

                          <span class="d-inline float-right fsx">
                            <small class="clsa"></small>
                            <span id="tampilkurir25" class="fsx"></span>
                          </span>
                        </div>
                      </div>
                      <div
                        class="list-group-item"
                        style="border: 0px !important"
                      >
                        <textarea
                          style="
                            width: 100%;
                            border: none;
                            border: solid 1px #6c757d !important;
                            border-radius: 10px;
                            padding: 10px;
                          "
                          rows="2"
                          class="note_customer"
                          v-model="notesPayment[index]"
                          placeholder="Catatan Untuk Penjual"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- rincian -->
          <div :class="!is_non_physical ? `col-md-5` : `d-none`">
            <div class="container mt-4 style-text">
              <div
                class="card shadow-custom"
                style="border-radius: 10px !important"
              >
                <div
                  class="card-header"
                  style="border-radius: 10px 10px 0 0 !important"
                >
                  <span
                    class="mb-0 float-left fsx"
                    style="color: white; font-weight: bold"
                    >Rincian Pembayaran</span
                  >
                </div>
                <div class="card-body">
                  <div class="rincianbayar">
                    <div class="list-group">
                      <div
                        class="list-group-item d-flex justify-content-between"
                        style="border: 0px !important"
                      >
                        <div class="fsx">
                          <i
                            class="fas fa-ticket-alt"
                            style="color: #fdb739"
                          ></i>
                          Pakai Voucher Jaja
                        </div>
                        <div>
                          <span class="fsx"
                            ><a
                              @click="showVoucherJaja()"
                              style="
                                color: #64b0c9;
                                font-weight: bold;
                                font-size: 90%;
                                cursor: pointer;
                              "
                              >Pilih Voucher</a
                            ></span
                          >
                        </div>
                      </div>

                      <div
                        class="list-group-item d-flex justify-content-between"
                        style="border: 0px !important"
                      >
                        <div class="fsx">
                          Total Pesanan
                          <span class="fsx" id="CountBarang"></span>
                        </div>
                        <div>
                          <span class="fsx">{{
                            dataPayment.totalPesanan
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="list-group-item d-flex justify-content-between"
                        style="border: 0px !important"
                      >
                        <div class="fsx">
                          Total Pengiriman
                          <div id="ShowOngkirCap" class="fsx"></div>
                        </div>
                        <div>
                          <span id="HargaOngkosKirim" class="fsx">{{
                            dataPayment.totalPengiriman
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="list-group-item d-flex justify-content-between"
                        style="border: 0px !important"
                        v-if="
                          Object.keys(dataCart.voucherJajaSelected).length > 0
                        "
                      >
                        <div class="fsx">
                          Total {{ dataCart.voucherDiscountJajaDesc }}
                          <div id="ShowOngkirCap" class="fsx"></div>
                        </div>
                        <div>
                          <span
                            id="HargaOngkosKirim"
                            class="fsx text-danger-jaja"
                            >{{
                              dataCart.voucherDiscountJajaCurrencyFormat
                            }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="list-group-item d-flex justify-content-between"
                        style="border: 0px !important"
                      >
                        <div class="fsx">
                          <input
                            type="checkbox"
                            class="mr-2 gunakanKoin"
                            id="gunakanKoin"
                            @change="useCoin()"
                          />
                          <label for="gunakanKoin"> Gunakan Koin</label><br />
                        </div>
                        <div>
                          <span id="statuskoin" class="text-danger mr-2 fsx"
                            >[- {{ dataProfil.coinFormat }}]</span
                          >
                        </div>
                      </div>

                      <div
                        class="
                          list-group-item
                          d-flex
                          justify-content-between
                          fsx
                        "
                        style="border: 0px !important"
                      >
                        <div class="fsx font-weight-bold">Total Pembayaran</div>
                        <div>
                          <b style="color: black">
                            <span id="TotalPembayaranUnfix" class="fsx">{{
                              dataPayment.totalBayar
                            }}</span>
                          </b>
                        </div>
                      </div>

                      <div
                        v-if="
                          isCoin || dataCart.totalCurrencyFormat == 'Gratis'
                        "
                      >
                        <button
                          @click="goToDetailOrder()"
                          class="btn btn-block btn-primary font-weight-bold"
                          style="
                            background: #64b0c9 !important;
                            border-color: #64b0c9 !important;
                          "
                        >
                          Buat Pesanan
                        </button>
                      </div>
                      <div v-else-if="!isCoin">
                        <button
                          @click="goToDetailOrder()"
                          class="btn btn-block btn-primary font-weight-bold"
                          style="
                            background: #64b0c9 !important;
                            border-color: #64b0c9 !important;
                          "
                        >
                          Pilih Pembayaran
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="is_non_physical ? `row` : `d-none`">
          <!-- produk -->
          <div :class="is_non_physical ? `col-md-7  mt-4-custom` : `d-none`">
            <div
              v-for="(item, index) in dataCart.cart"
              :key="index"
              :class="index ? (index > 0 ? `mt-plus` : ``) : `cardPertama`"
            >
              <div
                class="container style-text"
                :class="
                  Object.keys(dataCart.voucherJajaSelected).length > 0
                    ? `mt-minus-13 `
                    : ``
                "
              >
                <div
                  class="card shadow-custom"
                  style="border-radius: 10px !important"
                >
                  <div
                    class="card-header"
                    style="border-radius: 10px 10px 0 0 !important"
                  >
                    <span
                      class="mb-0 float-left fsx"
                      style="color: white; font-weight: bold"
                    >
                      <router-link
                        :to="'/home/DetailToko/' + item.store.slug"
                        class="text-white"
                      >
                        {{ item.store.name }}
                      </router-link>
                    </span>
                  </div>
                  <div class="card-body">
                    <div class="my-2">
                      <div
                        class="list-group-item p-0"
                        style="border: 0px !important"
                      >
                        <div class="table-responsive">
                          <table
                            class="table Table_JajaID"
                            style="margin-top: -2%"
                          >
                            <tbody class="HeaderCartBody_JajaID">
                              <tr class="desktop-only">
                                <td style="width: 70%" colspan="2"></td>
                                <td
                                  class="text-center"
                                  style="
                                    width: 15%;
                                    color: #64b0c9;
                                    font-weight: bold;
                                  "
                                >
                                  Harga
                                </td>
                                <td
                                  class="text-center"
                                  style="
                                    width: 5%;
                                    color: #64b0c9;
                                    font-weight: bold;
                                  "
                                >
                                  Jumlah
                                </td>
                                <td
                                  class="text-right"
                                  style="
                                    width: 15%;
                                    color: #64b0c9;
                                    font-weight: bold;
                                  "
                                >
                                  Sub Total
                                </td>
                              </tr>
                              <tr
                                v-for="(a, b) in item.products"
                                :key="b"
                                class="desktop-only"
                              >
                                <td style="width: 15%">
                                  <img
                                    :src="a.image"
                                    class="img-responsive"
                                    style="
                                      display: inline-block;
                                      float: left;
                                      object-fit: contain;
                                    "
                                    width="60px"
                                    height="60px"
                                  />
                                </td>
                                <td
                                  style="
                                    width: 25%;
                                    color: #64b0c9;
                                    font-weight: bold;
                                  "
                                >
                                  <router-link
                                    :to="'/home/DetailProduk/' + a.slug"
                                    class="text-primary-jaja"
                                  >
                                    {{ a.name }}
                                  </router-link>
                                  <br />
                                </td>
                                <td class="text-right">
                                  <strike
                                    v-if="a.isDiscount"
                                    class="text-danger"
                                    ><small>{{
                                      a.priceCurrencyFormat
                                    }}</small></strike
                                  >

                                  {{
                                    a.isDiscount
                                      ? a.priceDiscountCurrencyFormat
                                      : a.priceCurrencyFormat
                                  }}
                                </td>
                                <td class="text-center">{{ a.qty }}</td>
                                <td
                                  class="text-right"
                                  style="
                                    text-align: right;
                                    color: #000 !important;
                                  "
                                >
                                  {{ a.subTotalCurrencyFormat }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        class="list-group-item"
                        style="border: 0px !important"
                        v-if="Object.keys(item.voucherStore).length > 0"
                      >
                        <p
                          class="
                            mb-0
                            p-2
                            btn btn-outline-danger btn-block
                            font-weight-bold
                          "
                          style="
                            border: none;
                            border: solid 2px #fb7181 !important;
                            border-radius: 10px;
                            padding: 10px;
                            text-align: center;
                          "
                          @click="showVoucherToko(index)"
                        >
                          Pakai Voucher Toko
                        </p>
                      </div>
                      <div
                        class="list-group-item"
                        style="border: 0px !important"
                      >
                        <p class="mb-0" v-if="item.voucherDiscount">
                          Voucher Toko
                          <span class="text-danger-jaja float-right">
                            {{ item.voucherDiscountCurrencyFormat }}
                          </span>
                        </p>
                        <p class="mb-0">
                          Subtotal Produk
                          <span
                            class="text-dark float-right"
                            v-if="item.voucherDiscount > 0"
                          >
                            {{ item.totalDiscountCurrencyFormat }}
                          </span>
                          <span class="text-dark float-right" v-else>
                            {{ item.totalCurrencyFormat }}
                          </span>
                        </p>
                      </div>
                      <div
                        class="list-group-item"
                        style="border: 0px !important"
                      >
                        <textarea
                          style="
                            width: 100%;
                            border: none;
                            border: solid 1px #6c757d !important;
                            border-radius: 10px;
                            padding: 10px;
                          "
                          rows="2"
                          class="note_customer"
                          v-model="notesPayment[index]"
                          placeholder="Catatan Untuk Penjual"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- rincian -->
          <div :class="is_non_physical ? `col-md-5 mt-3` : `d-none`">
            <div class="container style-text">
              <div
                class="card shadow-custom"
                style="border-radius: 10px !important"
              >
                <div
                  class="card-header"
                  style="border-radius: 10px 10px 0 0 !important"
                >
                  <span
                    class="mb-0 float-left fsx"
                    style="color: white; font-weight: bold"
                    >Rincian Pembayaran</span
                  >
                </div>
                <div class="card-body">
                  <div class="rincianbayar">
                    <div class="list-group">
                      <div
                        class="list-group-item d-flex justify-content-between"
                        style="border: 0px !important"
                      >
                        <div class="fsx">
                          <i
                            class="fas fa-ticket-alt"
                            style="color: #fdb739"
                          ></i>
                          Pakai Voucher Jaja
                        </div>
                        <div>
                          <span class="fsx"
                            ><a
                              @click="showVoucherJaja()"
                              style="
                                color: #64b0c9;
                                font-weight: bold;
                                font-size: 90%;
                                cursor: pointer;
                              "
                              >Pilih Voucher</a
                            ></span
                          >
                        </div>
                      </div>

                      <div
                        class="list-group-item d-flex justify-content-between"
                        style="border: 0px !important"
                      >
                        <div class="fsx">
                          Total Pesanan
                          <span class="fsx" id="CountBarang"></span>
                        </div>
                        <div>
                          <span class="fsx">{{
                            dataPayment.totalPesanan
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="list-group-item d-flex justify-content-between"
                        style="border: 0px !important"
                      >
                        <div class="fsx">
                          Total Pengiriman
                          <div id="ShowOngkirCap" class="fsx"></div>
                        </div>
                        <div>
                          <span id="HargaOngkosKirim" class="fsx">{{
                            dataPayment.totalPengiriman
                          }}</span>
                        </div>
                      </div>
                      <div
                        class="list-group-item d-flex justify-content-between"
                        style="border: 0px !important"
                        v-if="
                          Object.keys(dataCart.voucherJajaSelected).length > 0
                        "
                      >
                        <div class="fsx">
                          Total {{ dataCart.voucherDiscountJajaDesc }}
                          <div id="ShowOngkirCap" class="fsx"></div>
                        </div>
                        <div>
                          <span
                            id="HargaOngkosKirim"
                            class="fsx text-danger-jaja"
                            >{{
                              dataCart.voucherDiscountJajaCurrencyFormat
                            }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="list-group-item d-flex justify-content-between"
                        style="border: 0px !important"
                      >
                        <div class="fsx">
                          <input
                            type="checkbox"
                            class="mr-2 gunakanKoin"
                            id="gunakanKoin"
                            @change="useCoin()"
                          />
                          <label for="gunakanKoin"> Gunakan Koin</label><br />
                        </div>
                        <div>
                          <span id="statuskoin" class="text-danger mr-2 fsx"
                            >[- {{ dataProfil.coinFormat }}]</span
                          >
                        </div>
                      </div>

                      <div
                        class="
                          list-group-item
                          d-flex
                          justify-content-between
                          fsx
                        "
                        style="border: 0px !important"
                      >
                        <div class="fsx font-weight-bold">Total Pembayaran</div>
                        <div>
                          <b style="color: black">
                            <span id="TotalPembayaranUnfix" class="fsx">{{
                              dataPayment.totalBayar
                            }}</span>
                          </b>
                        </div>
                      </div>

                      <div v-if="isCoin">
                        <button
                          @click="goToDetailOrder()"
                          class="btn btn-block btn-primary font-weight-bold"
                          style="
                            background: #64b0c9 !important;
                            border-color: #64b0c9 !important;
                          "
                        >
                          Buat Pesanan
                        </button>
                      </div>
                      <div v-if="!isCoin">
                        <button
                          @click="goToDetailOrder()"
                          class="btn btn-block btn-primary font-weight-bold"
                          style="
                            background: #64b0c9 !important;
                            border-color: #64b0c9 !important;
                          "
                        >
                          Pilih Pembayaran
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="mod_change_addres"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-title font-weight-bold" id="exampleModalLabel">
                <h5 class="modal-title font-weight-bold">Alamat Pengiriman</h5>
                <small>Pilih salah satu alamat berikut</small>
              </div>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div
                v-for="(row, index) in dataAddress"
                :key="index"
                class="list-group"
              >
                <label
                  for="0"
                  class="list-group-item"
                  style="border: 0px !important"
                >
                  <input
                    @change="gantiPrimaryAlamat(row.id)"
                    class="form-check-input me-1"
                    type="radio"
                    value="true"
                    :checked="row.is_primary"
                    name="alamat"
                  />
                  {{ row.alamat_lengkap }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="myModal" centered>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title font-weight-bold">Ubah Pengiriman</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                :disabled="isLoading"
              >
                &times;
              </button>
            </div>
            <div class="modal-body" id="list-pengiriman">
              <div v-if="isLoading" class="text-center">
                <img
                  src="https://jaja.id/asset/home/logo/Loading_2.gif"
                  alt=""
                />
              </div>
              <div v-else>
                <ul
                  v-for="(row, index) in dataShipping.items"
                  :key="index"
                  class="list-group mb-2"
                  style="
                    border-radius: 10px;
                    border: 2px solid rgb(100, 176, 201);
                  "
                >
                  <li class="list-group-item" style="border: 0px !important">
                    <div class="font-weight-bold">
                      <div class="row">
                        <div
                          class="col-md-4"
                          style="
                            width: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: left;
                          "
                        >
                          <img
                            class=""
                            :src="row.icon"
                            style="width: 100%; height: 50px"
                          />
                        </div>
                        <div class="col-md-8">
                          <ul
                            v-for="(a, b) in row.type"
                            :key="b"
                            class="list-group"
                          >
                            <li
                              class="list-group-item"
                              style="border: 0px !important"
                            >
                              <div class="row">
                                <div class="col-md-8">
                                  <input
                                    @change="chooseSubDelivery(index + ',' + b)"
                                    class="form-check-input me-1 subDev"
                                    :id="b"
                                    type="radio"
                                    name="subDev"
                                    value=""
                                    aria-label="..."
                                    :checked="a.isSelected"
                                  />
                                  <label :for="b">{{ a.name }}</label>
                                </div>
                                <div class="col-md-4" style="text-align: right">
                                  {{ a.priceCurrencyFormat }}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul
                  class="list-group mb-2"
                  style="
                    border-radius: 10px;
                    border: 3px solid rgb(100, 176, 201);
                  "
                >
                  <h3 class="text-primary-jaja font-weight-bold pl-3 pt-3">
                    Pilih Waktu pengiriman
                  </h3>
                  <li class="list-group-item" style="border: 0px !important">
                    <div class="font-weight-bold">
                      <div class="row">
                        <div class="col-md-12">
                          <ul
                            v-for="(a, b) in dataShipping.sendTime"
                            :key="b"
                            class="list-group"
                          >
                            <li
                              class="list-group-item"
                              style="border: 0px !important"
                            >
                              <input
                                @change="chooseSendTime(b)"
                                class="form-check-input me-1 sendTime"
                                :id="'sendTime' + b"
                                type="radio"
                                name="sendTime"
                                aria-label="..."
                                :checked="a.isSelected"
                              />
                              <label :for="b">{{ a.name }}</label>
                              <input
                                type="date"
                                v-if="b == 2"
                                class="form-control datePicker d-none"
                                id="datepickerSendTime"
                                v-model="paramsJadwalKirim.dateSendTime"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="modal-footer">
              <button
                :disabled="isLoading"
                type="button"
                class="btn btn-secondary font-weight-bold"
                data-dismiss="modal"
                style="background: #fdb738; border-color: #fdb738"
              >
                Close
              </button>
              <button
                :disabled="isLoading"
                type="button"
                @click="selectedShipping()"
                class="btn btn-primary font-weight-bold"
                style="background: #64b0c9; border-color: #64b0c9"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="myModalVoucherJaja"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div
              class="
                modal-header
                NoBorderCustom_JajaID
                BlurOriginal
                ResetCustomPadding
              "
            >
              <h5 class="modal-title font-weight-bold" id="exampleModalLabel">
                Voucher Jaja
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div v-if="Object.keys(dataCart.voucherJaja).length > 0">
                <div v-for="(item, index) in dataCart.voucherJaja" :key="index">
                  <div
                    id="selectNClaim"
                    class="modal-body resetPadding selectedVoucher"
                    style="background: #fafafa; overflow-x: hidden"
                  >
                    <div
                      class="
                        coupon
                        rounded
                        mb-3
                        d-flex
                        justify-content-between
                        mr-4
                        ml-4
                        bg-warning-jaja
                      "
                      style="box-shadow: 2px 2px 2px 2px #e9eaef"
                    >
                      <div class="kiri p-3">
                        <div class="icon-container">
                          <div class="icon-container_box"></div>
                        </div>
                      </div>
                      <div
                        class="
                          tengah
                          py-3
                          d-flex
                          w-100
                          justify-content-start
                          text-white
                        "
                      >
                        <div>
                          <h6 class="fsx font-weight-bold text-white">
                            {{ item.code }}
                          </h6>
                          <span class="fsx"></span>
                          <span class="fsx">Diskon</span>
                          <span style="font-weight: bold" class="fsx">
                            {{ item.discountText }}
                          </span>

                          <br />
                          <small class="mb-0"
                            ><small>*) S/d : {{ item.endDate }}</small></small
                          >
                        </div>
                      </div>
                      <div
                        class="kanan"
                        :class="
                          item.isClaimed == false
                            ? 'bg-light-grey-jaja'
                            : ' bg-warning-jaja'
                        "
                      >
                        <div class="info m-3 d-flex align-items-center">
                          <div class="w-100">
                            <div class="block">
                              <button
                                v-if="!item.isClaimed"
                                @click="useVoucherJaja(item.id)"
                                class="
                                  tombol-gunakan-point
                                  btn btn-outline-primary
                                  mr-2
                                  btn-sm
                                  float-right
                                  bg-white
                                "
                                style="margin-top: 10%"
                              >
                                <span
                                  class="info-gunakan-poin font-weight-bold"
                                >
                                  Klaim
                                </span>
                                <i data-id="" data-checklist="none"></i><br />
                              </button>
                              <button
                                v-else-if="item.isClaimed && !item.isSelected"
                                @click="pilihVoucherJaja(item.id)"
                                data-id_vtoko="443"
                                data-storeid="25"
                                id="claimVToko"
                                class="
                                  tombol-gunakan-point
                                  btn btn-outline-primary
                                  mr-2
                                  btn-sm
                                  float-right
                                  bg-white
                                "
                                style="margin-top: 10%"
                              >
                                <span class="font-weight-bold"> Pakai </span>
                                <i data-id="" data-checklist="none"></i><br />
                              </button>
                              <button
                                v-else-if="item.isSelected"
                                @click="pilihVoucherJaja(item.id)"
                                data-id_vtoko="443"
                                data-storeid="25"
                                id="claimVToko"
                                class="
                                  tombol-gunakan-point
                                  btn btn-outline-danger
                                  mr-2
                                  btn-sm
                                  float-right
                                  bg-white
                                "
                                style="margin-top: 10%"
                              >
                                <span class="font-weight-bold"> Terpakai </span>
                                <i data-id="" data-checklist="none"></i><br />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="p-2 bd-highlight font-weight-bold h4">
                  Kamu Belum Punya Voucher
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="myModalVoucherToko"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div
              class="
                modal-header
                NoBorderCustom_JajaID
                BlurOriginal
                ResetCustomPadding
              "
            >
              <h5 class="modal-title font-weight-bold" id="exampleModalLabel">
                Voucher Toko
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                <div
                  v-for="(item, index) in itemCart.voucherStore"
                  :key="index"
                >
                  <div
                    id="selectNClaim"
                    class="modal-body resetPadding selectedVoucher"
                    style="background: #fafafa; overflow-x: hidden"
                  >
                    <div
                      class="
                        coupon
                        rounded
                        mb-3
                        d-flex
                        justify-content-between
                        mr-4
                        ml-4
                        bg-warning-jaja
                      "
                      style="box-shadow: 2px 2px 2px 2px #e9eaef"
                    >
                      <div class="kiri p-3">
                        <div class="icon-container">
                          <div class="icon-container_box"></div>
                        </div>
                      </div>
                      <div
                        class="
                          tengah
                          py-3
                          d-flex
                          w-100
                          justify-content-start
                          text-white
                        "
                      >
                        <div>
                          <span class="fsx">Diskon</span>
                          <span style="font-weight: bold" class="fsx">
                            {{ item.discountText }}
                          </span>

                          <br />
                          <small class="mb-0"
                            ><small>*) S/d : {{ item.endDate }}</small></small
                          >
                        </div>
                      </div>
                      <div
                        class="kanan"
                        :class="
                          item.isClaimed == false
                            ? 'bg-light-grey-jaja'
                            : ' bg-warning-jaja'
                        "
                      >
                        <div class="info m-3 d-flex align-items-center">
                          <div class="w-100">
                            <div class="block">
                              <button
                                v-if="!item.isClaimed"
                                @click="useVoucherToko(item.id, item.storeId)"
                                class="
                                  tombol-gunakan-point
                                  btn btn-outline-primary
                                  mr-2
                                  btn-sm
                                  float-right
                                  bg-white
                                "
                                style="margin-top: 12px"
                              >
                                <span
                                  class="info-gunakan-poin font-weight-bold"
                                >
                                  Klaim
                                </span>
                                <i data-id="" data-checklist="none"></i><br />
                              </button>
                              <button
                                v-else-if="item.isClaimed && !item.isSelected"
                                @click="pilihVoucherToko(item.id, item.storeId)"
                                data-id_vtoko="443"
                                data-storeid="25"
                                id="claimVToko"
                                class="
                                  tombol-gunakan-point
                                  btn btn-outline-primary
                                  mr-2
                                  btn-sm
                                  float-right
                                  bg-white
                                "
                                style="margin-top: 12px"
                              >
                                <span class="font-weight-bold"> Pakai </span>
                                <i data-id="" data-checklist="none"></i><br />
                              </button>
                              <button
                                v-else-if="item.isSelected"
                                @click="pilihVoucherToko(item.id, item.storeId)"
                                data-id_vtoko="443"
                                data-storeid="25"
                                id="claimVToko"
                                class="
                                  tombol-gunakan-point
                                  btn btn-outline-danger
                                  mr-2
                                  btn-sm
                                  float-right
                                  bg-white
                                "
                                style="margin-top: 12px"
                              >
                                <span class="font-weight-bold"> Terpakai </span>
                                <i data-id="" data-checklist="none"></i><br />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-else>
                        <div class="p-2 bd-highlight font-weight-bold h4">Kamu Belum Punya Voucher</div>
                    </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="myModalLoading">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body" id="list-pengiriman">
              <div v-if="isLoading" class="text-center">
                <img
                  src="https://jaja.id/asset/home/logo/Loading_2.gif"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container style-text" v-if="!isFinish">
      <div class="row">
        <!-- alamat & produk -->
        <div class="col-md-7">
          <div class="container mt-4 style-text">
            <div
              class="card shadow-custom"
              style="border-radius: 10px !important"
            >
              <div
                class="card-header"
                style="border-radius: 10px 10px 0 0 !important"
              >
                <span
                  class="mb-0 float-left fsx"
                  style="color: white; font-weight: bold"
                >
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 200px; height: 20px"
                    />
                  </content-placeholders>
                </span>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-5">
                    <span class="fsx"
                      ><b>
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 150px; height: 20px"
                          />
                        </content-placeholders>
                      </b>
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 200px; height: 20px"
                        />
                      </content-placeholders>
                    </span>
                  </div>
                  <div class="col-md-7">
                    <h6 class="">
                      <b>
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 150px; height: 20px"
                          />
                        </content-placeholders>
                      </b>
                    </h6>
                    <span class="">
                      <content-placeholders :rounded="true">
                        <content-placeholders-heading style="width: 200px" />
                      </content-placeholders>
                    </span>

                    <div class="row mt-3">
                      <div class="d-flex justify-content-around bd-highlight">
                        <div class="p-2 ml-2 bd-highlight">
                          <small
                            style="
                              color: #64b0c9;
                              font-weight: bold;
                              font-size: 90%;
                              cursor: pointer;
                            "
                          >
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100px; height: 20px"
                              />
                            </content-placeholders>
                          </small>
                        </div>
                        <div class="p-2 ml-4 bd-highlight">
                          <small
                            style="
                              color: #64b0c9;
                              font-weight: bold;
                              font-size: 90%;
                              cursor: pointer;
                            "
                          >
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                style="width: 100px; height: 20px"
                              />
                            </content-placeholders>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="container style-text">
              <div
                class="card shadow-custom"
                style="border-radius: 10px !important"
              >
                <div
                  class="card-header"
                  style="border-radius: 10px 10px 0 0 !important"
                >
                  <span
                    class="mb-0 float-left fsx"
                    style="color: white; font-weight: bold"
                  >
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 150px; height: 20px"
                      />
                    </content-placeholders>
                  </span>
                </div>
                <div class="card-body">
                  <div class="my-2">
                    <div
                      class="list-group-item p-0"
                      style="border: 0px !important"
                    >
                      <div class="table-responsive">
                        <table
                          class="table Table_JajaID"
                          style="margin-top: -2%"
                        >
                          <tbody class="HeaderCartBody_JajaID">
                            <tr class="desktop-only">
                              <td style="width: 70%" colspan="2"></td>
                              <td
                                class="text-center"
                                style="
                                  width: 15%;
                                  color: #64b0c9;
                                  font-weight: bold;
                                "
                              >
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 80px; height: 20px"
                                  />
                                </content-placeholders>
                              </td>
                              <td
                                class="text-center"
                                style="
                                  width: 5%;
                                  color: #64b0c9;
                                  font-weight: bold;
                                "
                              >
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 80px; height: 20px"
                                  />
                                </content-placeholders>
                              </td>
                              <td
                                class="text-right"
                                style="
                                  width: 15%;
                                  color: #64b0c9;
                                  font-weight: bold;
                                "
                              >
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 80px; height: 20px"
                                  />
                                </content-placeholders>
                              </td>
                            </tr>
                            <tr class="desktop-only">
                              <td style="width: 15%">
                                <content-placeholders :rounded="true">
                                  <content-placeholders-img
                                    class="img-responsive"
                                    style="
                                      display: inline-block;
                                      float: left;
                                      object-fit: contain;
                                    "
                                    width="60px"
                                    height="60px"
                                  />
                                </content-placeholders>
                              </td>
                              <td
                                style="
                                  width: 25%;
                                  color: #64b0c9;
                                  font-weight: bold;
                                "
                              >
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 100px; height: 20px"
                                  />
                                </content-placeholders>
                                <br />
                              </td>
                              <td class="text-right">
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 60px; height: 20px"
                                  />
                                </content-placeholders>
                              </td>
                              <td class="text-center">
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 60px; height: 20px"
                                  />
                                </content-placeholders>
                              </td>
                              <td
                                class="text-right"
                                style="
                                  text-align: right;
                                  color: #000 !important;
                                "
                              >
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 60px; height: 20px"
                                  />
                                </content-placeholders>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="list-group-item" style="border: 0px !important">
                      <p class="mb-0">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 200px; height: 20px"
                          />
                        </content-placeholders>
                        <span
                          class="text-dark float-right"
                          style="margin-top: -15px"
                        >
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px; height: 20px"
                            />
                          </content-placeholders>
                        </span>
                      </p>
                    </div>
                    <div
                      class="list-group-item ubahPengiriman"
                      style="border: 0px !important"
                    >
                      <div
                        class="border border-secondary p-2"
                        style="
                          border: none;
                          border: solid 1px #6c757d !important;
                          border-radius: 10px;
                          padding: 10px;
                        "
                      >
                        <a
                          style="
                            border-bottom: #222 dashed 1px;
                            cursor: pointer;
                          "
                          class="fsx text-danger"
                        >
                          <span class="fsx font-weight-bold">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                style="width: 200px; height: 20px"
                              />
                            </content-placeholders>
                          </span>
                        </a>
                        <a class="float-right">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px; height: 20px"
                            />
                          </content-placeholders>
                        </a>
                        <br />
                        <div>
                          <content-placeholders :rounded="true">
                            <content-placeholders-heading
                              style="width: 200px"
                            />
                          </content-placeholders>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- rincian -->
        <div class="col-md-5">
          <div class="container mt-4 style-text">
            <div
              class="card shadow-custom"
              style="border-radius: 10px !important"
            >
              <div
                class="card-header"
                style="border-radius: 10px 10px 0 0 !important"
              >
                <span
                  class="mb-0 float-left fsx"
                  style="color: white; font-weight: bold"
                >
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 200px; height: 20px"
                    />
                  </content-placeholders>
                </span>
              </div>
              <div class="card-body">
                <div class="rincianbayar">
                  <div class="list-group">
                    <div
                      class="list-group-item d-flex justify-content-between"
                      style="border: 0px !important"
                    >
                      <div class="fsx">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 150px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                      <div>
                        <span class="fsx">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px; height: 20px"
                            />
                          </content-placeholders>
                        </span>
                      </div>
                    </div>

                    <div
                      class="list-group-item d-flex justify-content-between"
                      style="border: 0px !important"
                    >
                      <div class="fsx">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 140px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                      <div>
                        <span class="fsx">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px; height: 20px"
                            />
                          </content-placeholders>
                        </span>
                      </div>
                    </div>
                    <div
                      class="list-group-item d-flex justify-content-between"
                      style="border: 0px !important"
                    >
                      <div class="fsx">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 160px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                      <div>
                        <span class="fsx">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px; height: 20px"
                            />
                          </content-placeholders>
                        </span>
                      </div>
                    </div>
                    <div
                      class="list-group-item d-flex justify-content-between"
                      style="border: 0px !important"
                    >
                      <div class="fsx">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 150px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                      <div>
                        <span class="fsx">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px; height: 20px"
                            />
                          </content-placeholders>
                        </span>
                      </div>
                    </div>
                    <div
                      class="list-group-item d-flex justify-content-between"
                      style="border: 0px !important"
                    >
                      <div class="fsx">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 140px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                      <div>
                        <span class="fsx">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px; height: 20px"
                            />
                          </content-placeholders>
                        </span>
                      </div>
                    </div>

                    <div
                      class="list-group-item d-flex justify-content-between"
                      style="border: 0px !important"
                    >
                      <div class="fsx">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            style="width: 160px; height: 20px"
                          />
                        </content-placeholders>
                      </div>
                      <div>
                        <span class="fsx">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="1"
                              style="width: 100px; height: 20px"
                            />
                          </content-placeholders>
                        </span>
                      </div>
                    </div>
                    <div>
                      <content-placeholders :rounded="true" :centered="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 100%; height: 20px"
                        />
                      </content-placeholders>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dataCart: {},
      dataAddress: {},
      dataProfil: {},
      receiverName: "",
      phoneNumber: "",
      label: "",
      address: "",
      listShipping: {},
      isLoading: false,
      resShippingData: {},
      dataShipping: {},
      isCoin: 0,
      dataPayment: {
        totalPesanan: "",
        totalPengiriman: "",
        totalKoin: "",
        totalBayar: "",
      },
      paramsJadwalKirim: {
        storeId: "",
        shippingCode: "",
        shippingTypeCode: "",
        sendTime: "",
        dateSendTime: "",
      },
      itemCart: {},
      itemVoucherJaja: {},
      note: "",
      itemNote: {},
      notesPayment: [],
      is_non_physical: false,
      isFinish: false,
    };
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    // ...mapGetters({dataShipping:'shippingGetters'})
  },
  created() {
    this.changeAddress();
  },
  beforeMount() {
    this.getDataPayment();
  },
  methods: {
    getDataPayment() {
      this.is_non_physical = JSON.parse(
        localStorage.getItem("is_non_physical")
      );

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}checkout?isCoin=${
          this.isCoin
        }&fromCart=0&is_non_physical=${this.is_non_physical ? 1 : 0}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          this.isFinish = true;
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.dataCart = res.data;
            this.receiverName = res.data.address.receiverName;
            this.phoneNumber = res.data.address.phoneNumber;
            this.label = res.data.address.label;
            this.address = res.data.address.address;
            this.dataPayment.totalPesanan = res.data.subTotalCurrencyFormat;
            this.dataPayment.totalPengiriman =
              res.data.shippingCostCurrencyFormat;
            this.dataPayment.totalKoin = res.data.coinRemainingFormat;
            this.dataPayment.totalBayar = res.data.totalCurrencyFormat;
            if (!this.is_non_physical) {
              setTimeout(() => {
                this.getShipping();
              }, 100);
            }
          }
        })
        .catch((error) => console.log("error", error));
    },
    getShipping() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=vah7ivbaoqeus4qfh89d7c8o2q55216c");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}checkout/shipping`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.resShippingData = res.data;
            localStorage.setItem("shippingData", JSON.stringify(res.data));
          }
        })
        .catch((error) => console.log("error", error));
    },
    useCoin() {
      event.target.checked ? (this.isCoin = 1) : (this.isCoin = 0);
      setTimeout(() => {
        this.getDataPayment();
      }, 100);
    },
    showModalAddress() {
      $("#mod_change_addres").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );
    },
    goToAddSendAddress() {
      this.$router.push(`/AddSendAddr`);
    },
    gantiPrimaryAlamat(id) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        addressId: id,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/changePrimaryAddress`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.$swal.fire({
            icon: "success",
            title: "Sukses",
            text: `Berhasil Ganti Alamat Utama`,
          });
          $("#mod_change_addres").modal("hide");
          this.getDataPayment();
        })
        .catch((error) => console.log("error", error));
    },
    changeAddress() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=vah7ivbaoqeus4qfh89d7c8o2q55216c");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/profile`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code == 200) {
            this.dataProfil = res.data;
            this.dataAddress = res.data.location;
          }
        })
        .catch((error) => console.log("error", error));
    },
    chooseShipping(id) {
      const shippingData = this.resShippingData;
      this.dataShipping = shippingData[id];
      this.paramsJadwalKirim.storeId = shippingData[id].store.id;
      this.listShipping = this.dataShipping;
      $("#myModal").modal({ backdrop: "static", keyboard: false }, "show");
    },
    chooseSubDelivery(data) {
      const indexArr = data.split(",");
      this.paramsJadwalKirim.shippingCode =
        this.dataShipping.items[indexArr[0]].code;
      this.paramsJadwalKirim.shippingTypeCode =
        this.dataShipping.items[indexArr[0]].type[indexArr[1]].code;
    },
    chooseSendTime(data) {
      this.paramsJadwalKirim.sendTime = this.dataShipping.sendTime[data].value;
      var today = new Date();
      var dd = ("0" + today.getDate()).slice(-2);
      var mm = ("0" + (today.getMonth() + 1)).slice(-2);
      var yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      this.paramsJadwalKirim.dateSendTime = today;

      if (this.dataShipping.sendTime[data].value === "pilih tanggal") {
        $(".datePicker").removeClass("d-none");
      } else {
        $(".datePicker").addClass("d-none");
      }
    },
    selectedShipping() {
      var today = new Date();
      var dd = ("0" + today.getDate()).slice(-2);
      var mm = ("0" + (today.getMonth() + 1)).slice(-2);
      var yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;

      if (this.paramsJadwalKirim.sendTime == "") {
        this.paramsJadwalKirim.sendTime = "setiap saat";
      }
      if (this.paramsJadwalKirim.dateSendTime == "") {
        this.paramsJadwalKirim.dateSendTime = today;
      }

      const objReq = this.paramsJadwalKirim;
      const cekObj = Object.values(objReq);
      const cekObjKeys = Object.keys(objReq);

      const arrKosong = [];
      for (let i = 0; i < cekObj.length; i++) {
        if (cekObjKeys[i] != "dateSendTime") {
          if (!cekObj[i]) {
            arrKosong.push(i);
          }
        }
      }

      if (arrKosong[0]) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Mohon pilih ${cekObjKeys[arrKosong[0]]}`,
        });
      } else {
        this.answerSelectedShipping();
      }
    },
    answerSelectedShipping() {
      $("#myModal").modal("hide");
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda ingin memilih pengiriman ini ?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Ya",
          denyButtonText: `Tidak`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isLoading = true;
            this.runSelectedShipping();
          }
          // else {
          //   this.$swal.fire("Changes are not saved", "", "info");
          // }
        });
    },
    runSelectedShipping() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=b0nh1v0ih43m0dt743tli9pg940lmurc");

      const raw = JSON.stringify(this.paramsJadwalKirim);

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}checkout/selectedShipping`, requestOptions)
        .then((response) => response.text())
        .then((res) => {
          const newRes = JSON.parse(res);
          if (newRes.status.code === 200) {
            this.isLoading = false;
            // $("#myModal").modal("hide");
            this.getDataPayment();
          } else {
          }
        })
        .catch((error) => console.log("error", error));
    },
    showVoucherToko(id) {
      const data = this.dataCart.cart;
      this.itemCart = data[id];

      $("#myModalVoucherToko").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );
    },
    useVoucherToko(id, storeId) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=b0nh1v0ih43m0dt743tli9pg940lmurc");

      const raw = JSON.stringify({
        voucherId: id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}voucher/claimVoucherStore`, requestOptions)
        .then((response) => response.json())
        .then((newRes) => {
          if (newRes.status.code === 200) {
            this.pilihVoucherToko(id, storeId);
          } else {
            this.$swal.fire({
              icon: "warning",
              title: "Warning",
              text: newRes.status.message,
            });
          }
        })
        .catch((error) => console.log("error", error));
    },
    pilihVoucherToko(id, storeId) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=b0nh1v0ih43m0dt743tli9pg940lmurc");

      const raw = JSON.stringify({
        voucherId: id,
        storeId: storeId,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}checkout/selectedVoucherStore`, requestOptions)
        .then((response) => response.json())
        .then((newRes) => {
          if (newRes.status.code === 200) {
            this.$swal
              .fire({
                icon: "success",
                title: "Sukses",
                text: newRes.status.message,
              })
              .then(() => {
                this.getDataPayment();
                $("#myModalVoucherToko").modal("hide");
              });
          } else {
            this.$swal.fire({
              icon: "warning",
              title: "Warning",
              text: newRes.status.message,
            });
          }
        })
        .catch((error) => console.log("error", error));
    },
    showVoucherJaja(id) {
      const data = this.dataCart.voucherJaja;
      this.itemVoucherJaja = data[id];

      $("#myModalVoucherJaja").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );
    },
    useVoucherJaja(id) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=b0nh1v0ih43m0dt743tli9pg940lmurc");

      const raw = JSON.stringify({
        voucherId: id,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}voucher/claimVoucherJaja`, requestOptions)
        .then((response) => response.json())
        .then((newRes) => {
          if (newRes.status.code === 200) {
            this.pilihVoucherJaja(id);
          } else {
            this.$swal.fire({
              icon: "warning",
              title: "Warning",
              text: newRes.status.message,
            });
          }
        })
        .catch((error) => console.log("error", error));
    },
    pilihVoucherJaja(id) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=b0nh1v0ih43m0dt743tli9pg940lmurc");

      const raw = JSON.stringify({
        voucherId: id,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}checkout/selectedVoucherJaja`, requestOptions)
        .then((response) => response.json())
        .then((newRes) => {
          if (newRes.status.code === 200) {
            this.$swal
              .fire({
                icon: "success",
                title: "Sukses",
                text: newRes.status.message,
              })
              .then(() => {
                this.getDataPayment();
                $("#myModalVoucherJaja").modal("hide");
              });
          } else {
            this.$swal.fire({
              icon: "warning",
              title: "Warning",
              text: newRes.status.message,
            });
          }
        })
        .catch((error) => console.log("error", error));
    },
    goToDetailOrder() {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda ingin melanjutkan pembayaran ?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Ya",
          denyButtonText: `Tidak`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runToDetailOrder();
            // this.runSelectedShipping()
          }
          // else if (result.isDenied) {
          //   this.$swal.fire("Changes are not saved", "", "info");
          // }
        });
    },
    runToDetailOrder() {
      this.isLoading = true;
      $("#myModalLoading").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );

      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=ughnqbo93gbdgdfrjrvoh6tbcnpl9cnq");

      const noted = $(".note_customer")
        .map(function () {
          return $(this).val();
        })
        .get();
      const data = noted.map((a, b) => {
        return {
          note: a,
        };
      });

      var raw = JSON.stringify({
        platform: "website",
        cart: data,
        koin: this.isCoin == 1 ? true : false,
        // "koin": document.getElementsByClassName('gunakanKoin')[0].checked
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log(
        "koin",
        document.getElementsByClassName("gunakanKoin")[0].checked
      );

      fetch(
        `${this.domainURL}checkout?is_non_physical=${
          this.is_non_physical ? 1 : 0
        }`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            localStorage.removeItem("is_non_physical");
            this.isLoading = false;
            $("#myModalLoading").modal("hide");

            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                this.$router.push({ path: `/order/${res.data}` });
              });
          } else {
            this.isLoading = false;
            $("#myModalLoading").modal("hide");
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.status.message,
              footer: "",
            });
          }
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>
<style scope>
.close {
  font-size: 2rem;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
}

.modal-header {
  background: #64b0c9 !important;
  color: white;
  font-weight: bold;
  border-radius: 1rem 1rem 0 0;
}

.modal-content {
  border-radius: 1rem;
}
.style-text {
  text-align: left;
}
/* .list-group-item {
        border: 0px !important;
    } */
.cardPertama {
  margin-top: -8%;
}
.shadow-custom {
  /* box-shadow: 0 .5rem 1rem rgba(0,0,0,.30)!important; */
  box-shadow: rgba(0, 0, 0, 0.5) 8px 8px 10px -8px !important;
}
.mt-minus-13 {
  margin-top: -7% !important;
}
.mt-plus {
  margin-top: 2% !important;
}
.mt-4-custom {
  margin-top: 4rem !important;
}
</style>
<template>
  <div>
    <div class="container p-3" v-if="isFinish">
      <div class="container shadow-custom header-info rounded">
        <div
          class="d-flex justify-content-start bd-highlight py-2 mb-3 text-white"
        >
          <div class="mr-1 bd-highlight font-weight-bold">Product</div>
          <div class="mr-1 bd-highlight">/</div>
          <div class="mr-1 bd-highlight">{{ dataDetailProduk.name }}</div>
        </div>
      </div>

      <!-- add to cart DLL -->
      <div
        class="
          container
          bg-white
          rounded
          p-2
          mb-4
          shadow-custom
          card-detail-product
        "
        style="border-radius: 10px !important"
      >
        <div class="row style-text pb-2">
          <div class="col-md-4 col-12">
            <div class="rounded card-pdx ml-2 mr-2 mt-3">
              <div class="row">
                <div class="col-12">
                  <div class="img[0]-pdx img-cover-product">
                    <img
                      id="gambarUtama"
                      :src="detailProdukImageFirst"
                      alt=""
                      class="img-fluid center-img"
                      style=""
                    />
                  </div>
                </div>
                <div class="col-12">
                  <flickity
                    v-if="Object.keys(detailProdukImage).length > 0"
                    ref="flickity"
                    class="flickity"
                    :options="flickityOptions"
                  >
                    <div class="d-flex justify-content-start bd-highlight mb-3">
                      <div
                        v-for="(item, index) in detailProdukImage"
                        :key="index"
                        class="p-2 bd-highlight"
                      >
                        <div
                          class="
                            card
                            text-center
                            img-cover-child
                            shadow-custom
                            card-category
                            mb-4
                            mt-4
                          "
                        >
                          <img @click="getSrc(item)" :src="item" />
                        </div>
                      </div>
                    </div>
                  </flickity>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-7 col-12">
            <div class="col-md-10 ml-2 mt-3">
              <div>
                <h3 class="d-flex justify-content-start text-info">
                  <b>{{ dataDetailProduk.name }}</b>
                </h3>
              </div>
              <div
                class="d-flex justify-content-start mb-2"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <img
                  src="https://nimda.jaja.id/asset/front/images/file/48a772150603bc7b6207732b7786e4c7.png"
                  width="40"
                  height="40"
                  alt=""
                />
                &nbsp;
                <span class="ml-1 font-weight-bold">
                  {{ dataDetailCat.name }}
                </span>
              </div>
              <div
                :class="
                  dataDetailProduk.amountSold
                    ? `d-flex justify-content-start mb-5 `
                    : `d-none`
                "
              >
                <span class="ml-1">
                  {{ dataDetailProduk.amountSold }} Terjual
                </span>
                <!-- <small>{{dataDetailProduk.amountSold}} Terjual</small> -->
              </div>
              <strike :class="dataDetailProduk.isDiscount ? `` : `d-none`">
                <small>{{ dataDetailProduk.price }}</small>
              </strike>
              <!-- diskon -->
              <div class="d-flex justify-content-start mb-5">
                <span>
                  <span class="h3 text-warning font-weight-bold mr-1">{{
                    dataDetailProduk.isDiscount
                      ? dataDetailProduk.priceDiscount
                      : dataDetailProduk.price
                  }}</span>
                  <span
                    :class="
                      dataDetailProduk.isDiscount
                        ? `badge badge-danger`
                        : `d-none`
                    "
                  >
                    {{ dataDetailProduk.discount }} %</span
                  >
                </span>
              </div>
              <!-- kondisi -->
              <div class="row">
                <div class="col-md-3">
                  <div class="d-flex flex-row">
                    <div class="p-2 font-weight-bold">Kondisi</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-flex flex-row">
                    <div class="p-2 font-weight-bold">
                      {{ dataDetailProduk.condition }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- kuantitas -->
              <div class="row">
                <div class="col-md-3">
                  <div class="d-flex flex-row mb-1">
                    <div class="p-2 font-weight-bold">Kuantitas</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-flex flex-row">
                    <div class="p-2">
                      <b-form-spinbutton
                        class=""
                        v-model="kuantitas"
                        inline
                        min="1"
                        :max="dataDetailProduk.stock"
                        v-if="!dataDetailProduk.isNonPhysical"
                      ></b-form-spinbutton>
                      <b-form-spinbutton
                        class=""
                        v-model="kuantitas"
                        inline
                        min="1"
                        :max="1"
                        v-if="dataDetailProduk.isNonPhysical"
                      ></b-form-spinbutton>
                      &nbsp;&nbsp; Tersisa
                      <b>{{ dataDetailProduk.stock }}</b> buah
                      <!-- <button class="mr-4 border border-dark pl-3 pr-3 py-1 rounded" type="button">-</button> 
										<span class="mr-4 font-weight-bold">1</span>
										<button class="border border-dark pl-3 pr-3 py-1 rounded" type="button">+</button> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- variant -->
              <div class="row">
                <div class="col-md-3">
                  <div
                    class="d-flex flex-row mb-1"
                    v-if="dataDetailProduk.variant"
                    :variant="dataDetailProduk.variant"
                  >
                    <div class="p-2 font-weight-bold">Variant</div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="d-flex flex-row"
                    v-if="dataDetailProduk.variant"
                    :variant="dataDetailProduk.variant"
                  >
                    <div class="p-2">
                      <div style="margin-left: -10px">
                        <b-form-group>
                          <b-form-radio-group
                            id="btn-radios-2"
                            v-model="selectedVariant"
                            :options="options"
                            button-variant="outline-primary"
                            size="sm"
                            name="radio-btn-outline"
                            buttons
                          ></b-form-radio-group>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-add-cart">
                <div
                  class="child-cart mb-4"
                  v-if="!dataDetailProduk.isNonPhysical"
                >
                  <div class="mr-4">
                    <a
                      @click="cekLSToken()"
                      class="
                        btn
                        text-dark
                        border border-danger
                        font-weight-bold
                        btn-sm
                        desktop-only
                      "
                      style="color: #fff"
                      ><i class="fas fa-cart-plus"></i> Tambah ke Keranjang
                    </a>
                  </div>
                  <div class="">
                    <a
                      @click="beliSekarang()"
                      class="btn btn-danger btn-sm desktop-only"
                      style="color: #fff"
                      >Beli Sekarang
                    </a>
                  </div>
                </div>
                <div
                  class="child-cart mb-4"
                  v-if="dataDetailProduk.isNonPhysical"
                >
                  <div class="">
                    <a
                      @click="beliSekarangNonPhysic()"
                      class="btn btn-danger btn-sm desktop-only"
                      style="color: #fff"
                      >Beli Sekarang
                    </a>
                  </div>
                </div>
                <div class="child-cart">
                  <div class="mr-4">
                    <a @click="tambahWislist()">
                      <i
                        style="color: grey"
                        class="fas fa-heart"
                        v-if="isWishlist == false"
                      ></i>
                      <i
                        style="color: red"
                        class="fas fa-heart"
                        v-if="isWishlist == true"
                      ></i>
                    </a>
                  </div>
                  <span class="dropdown">
                    <a
                      type=""
                      data-toggle="dropdown"
                      class="mr-3"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i style="color: grey" class="fas fa-share-alt clsc"></i>
                    </a>
                    <span
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      &emsp;
                      <ShareNetwork
                        network="facebook"
                        :url="
                          'https://sandbox.jaja.id/home/DetailProduk/' +
                          dataDetailProduk.slug
                        "
                        title="Jaja.id 1st Marketplace for Your Hobbies"
                        description="Yuk beli barang-barang kebutuhan hobi di Jaja.id"
                      >
                        <i class="fab fah fa-lg fa-facebook"></i>
                      </ShareNetwork>
                      &emsp;
                      <ShareNetwork
                        network="whatsapp"
                        :url="
                          'https://sandbox.jaja.id/home/DetailProduk/' +
                          dataDetailProduk.slug
                        "
                        title="Jaja.id 1st Marketplace for Your Hobbies"
                        description="Yuk beli barang-barang kebutuhan hobi di Jaja.id"
                      >
                        <i
                          class="fab fah fa-lg fa-whatsapp"
                          style="color: green"
                        ></i>
                      </ShareNetwork>
                      &emsp;
                      <ShareNetwork
                        network="twitter"
                        :url="
                          'https://sandbox.jaja.id/home/DetailProduk/' +
                          dataDetailProduk.slug
                        "
                        title="Jaja.id 1st Marketplace for Your Hobbies"
                        description="Yuk beli barang-barang kebutuhan hobi di Jaja.id"
                      >
                        <i class="fab fah fa-lg fa-twitter"></i>
                      </ShareNetwork>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- detail toko -->
      <div
        class="container shadow-custom rounded bg-white"
        style="border-radius: 10px !important"
      >
        <div class="d-flex justify-content-start bd-highlight pt-3 mb-3 mr-4">
          <div
            class="font-weight-bold text-white"
            style="display: flex; align-items: center; justify-content: center"
          >
            <router-link :to="'/home/DetailToko/' + dataDetailStore.slug">
              <img
                class="rounded-circle square-80 imguseravatar"
                :src="dataDetailStore.image"
                alt="avatar"
              />
            </router-link>
          </div>
          <div class="mr-auto bd-highlight mt-4 ml-4">
            <div class="d-flex justify-content-start bd-highlight">
              <router-link :to="'/home/DetailToko/' + dataDetailStore.slug">
                <div class="bd-highlight text-product">
                  {{ dataDetailStore ? dataDetailStore.name : `` }}
                </div>
              </router-link>
            </div>
            <div class="d-flex justify-content-start bd-highlight">
              <div class="bd-highlight">
                <small
                  ><img
                    src="https://jaja.id/asset/home/logo/loc.png"
                    class=""
                    width="12px"
                    alt=""
                  />
                  {{ dataDetailStore ? dataDetailStore.location : `` }}</small
                >
              </div>
            </div>
          </div>
          <div class="bd-highlight">
            <div
              class="d-flex align-items-center bd-highlight mb-3"
              style="height: 100px"
            >
              <div class="bd-highlight">
                <button
                  type="button"
                  class="btn bg-primary float-left text-white br-button-jaja"
                  style="background: #42b78f !important; font-weight: bold"
                  @click="chatStore()"
                  id="chat-toko"
                >
                  <i class="fas fa-comment-dots"></i>
                  &nbsp; Chat Penjual
                </button>
              </div>
              <div class="p-2 bd-highlight">
                <router-link
                  :to="'/home/DetailToko/' + dataDetailStore.slug"
                  class="btn btn-warning text-white font-weight-bold"
                >
                  Lihat Toko
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- deksripsi produk -->
      <div
        class="container shadow-custom rounded bg-white py-2 pl-4 pr-4 mb-3"
        style="border-radius: 10px !important"
      >
        <div class="d-flex align-items-center bd-highlight">
          <div
            class="
              bd-highlight
              bg-warning
              py-1
              pl-2
              pr-2
              mt-2
              mb-2
              rounded
              text-white
              font-weight-bold
            "
          >
            Spesifikasi Produk
          </div>
        </div>
        <div class="d-flex justify-content-start bd-highlight mb-3">
          <div class="bd-highlight">
            <div class="satu text-left">
              <div>Kategori</div>
              <div>Brand</div>
              <div>Stok</div>
              <div>Berat</div>
              <div>Dikirim dari</div>
            </div>
          </div>
          <div class="ml-4 bd-highlight">
            <div class="dua text-left">
              <div>{{ dataDetailCat.name }}</div>
              <div>{{ dataDetailStore.name }}</div>
              <div>{{ dataDetailProduk.stock }}</div>
              <div>{{ dataDetailProduk.weight }} Gram</div>
              <div>{{ dataDetailStore.location }}</div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center bd-highlight">
          <div
            class="
              bd-highlight
              bg-warning
              py-1
              pl-2
              pr-2
              mt-2
              mb-2
              rounded
              text-white
              font-weight-bold
            "
          >
            Deskripsi Produk
          </div>
        </div>
        <div class="text-justify mb-2">{{ dataDetailProduk.description }}</div>
      </div>

      <!-- penilaian -->
      <div
        class="container shadow-custom rounded bg-white py-2 pl-4 pr-4 mb-3"
        style="border-radius: 10px !important"
      >
        <div class="d-flex align-items-center bd-highlight">
          <div
            class="
              bd-highlight
              bg-warning
              py-1
              pl-2
              pr-2
              mt-2
              mb-3
              rounded
              text-white
              font-weight-bold
            "
          >
            Penilaian Produk
          </div>
        </div>

        <div class="d-flex justify-content-start bd-highlight mb-3">
          <div class="bd-highlight mr-auto">
            <div class="text-left">
              <h3 class="text-danger font-weight-bold">{{ rating }} dari 5</h3>
            </div>
            <div class="text-left">
              <b-form-rating
                v-model="rating"
                readonly
                variant="warning"
                size="lg"
                style="border: 0px !important; padding-left: 0px !important"
              ></b-form-rating>
            </div>
          </div>
          <div class="bd-highlight mr-2">
            <b-form-group>
              <b-form-radio-group
                v-model="selectedReview"
                :options="filterReview"
                button-variant="py-1 btn-outline-warning-custom pl-3 pr-3 rounded ml-3"
                size="sm"
                name="radio-btn-outline"
                buttons
                class=""
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="bd-highlight ml-auto"></div>
        </div>

        <div
          class="border border rounded py-4 mt-4 mb-4"
          v-if="totalDataReview == 0"
        >
          <h4 class="py-4 mt-4 mb-4">Belum Ada Ulasan</h4>
        </div>
        <div
          class="border border rounded py-4 mt-4 mb-4"
          style="
            border-right: 0px !important;
            border-left: 0px !important;
            border-bottom: 0px !important;
          "
          v-else
        >
          <div v-for="(item, index) in itemReview" :key="index">
            <div
              class="row p-3 mr-2 ml-2"
              style="border-bottom: 1px solid rgba(0, 0, 0, 0.09)"
            >
              <div class="col-md-2">
                <div
                  class="font-weight-bold text-white"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <img
                    class="rounded-circle square-80 imguseravatar shadow"
                    :src="item.customerImage"
                    alt="avatar"
                  />
                </div>
              </div>
              <div
                class="col-md-8"
                style="display: flex; justify-content: left"
              >
                <h6 class="text-left mb-4">
                  <b style="color: #65b0c9 !important">{{
                    item.customerName
                  }}</b>
                  <b-form-rating
                    v-model="item.rate"
                    readonly
                    variant="warning"
                    style="
                      border: 0px !important;
                      padding-left: 0px !important;
                      width: 100px;
                    "
                  ></b-form-rating>
                  <p style="text-align: left">
                    {{ item.comment }}
                  </p>
                  <p
                    v-if="Object.keys(item.image).length > 0"
                    style="text-align: left"
                  >
                    <img
                      class=""
                      :src="item.image"
                      alt="image review"
                      style="
                        width: 100%;
                        height: 100%;
                        max-width: 200px;
                        max-height: 200px;
                      "
                    />
                  </p>
                </h6>
              </div>
              <div class="col-md-2">
                <p style="text-align: right">
                  {{ item.createdAt }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- produk lain -->
      <div
        class="container rounded bg-default py-2 pl-4 mb-4"
        style="margin-top: 20px"
      >
        <div class="mt-4 mb-4" style="margin-left: -15px !important">
          <h3 class="text-left mb-4">Produk Lain di toko ini</h3>
          <cardSlider
            :data="dataDetailProduk.otherProduct"
            style="margin-left: -15px !important"
          />
        </div>
      </div>
    </div>
    <div class="container p-3" v-if="!isFinish">
      <div class="container shadow-custom header-info rounded">
        <div
          class="d-flex justify-content-start bd-highlight py-2 mb-3 text-white"
        >
          <div class="mr-1 bd-highlight">
            <content-placeholders :rounded="true">
              <content-placeholders-text
                class=""
                :lines="1"
                style="width: 15vw; height: 20px"
              />
            </content-placeholders>
          </div>
        </div>
      </div>

      <!-- add to cart DLL -->
      <div
        class="
          container
          bg-white
          rounded
          p-2
          mb-4
          shadow-custom
          card-detail-product
        "
        style="border-radius: 10px !important"
      >
        <div class="row style-text pb-2">
          <div class="col-md-4 col-12">
            <div class="rounded card-pdx ml-2 mr-2 mt-3">
              <div class="row">
                <div class="col-12">
                  <div class="img[0]-pdx img-cover-product">
                    <content-placeholders :rounded="true">
                      <content-placeholders-img
                        class="img-fluid center-img"
                        style="width: 15vw; height: 40vh"
                      />
                    </content-placeholders>
                  </div>
                </div>
                <div class="col-12">
                  <div class="d-flex justify-content-start bd-highlight mb-3">
                    <div
                      v-for="index in 3"
                      :key="index"
                      class="p-2 bd-highlight"
                    >
                      <div
                        class="
                          text-center
                          img-cover-child
                          card-category
                          mb-4
                          mt-4
                        "
                      >
                        <content-placeholders :rounded="true">
                          <content-placeholders-img
                            class="img-fluid center-img"
                            style="width: 15vw; height: 8vh"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-7 col-12">
            <div class="col-md-10 ml-2 mt-3">
              <div>
                <h3 class="d-flex justify-content-start text-info">
                  <b>
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 200px; height: 20px"
                      />
                    </content-placeholders>
                  </b>
                </h3>
              </div>
              <div
                class="d-flex justify-content-start mb-2"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <span class="ml-1 font-weight-bold">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 150px; height: 20px"
                    />
                  </content-placeholders>
                </span>
              </div>
              <div class="d-flex justify-content-start mb-5">
                <span class="ml-1">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                </span>
              </div>
              <div class="d-flex justify-content-start mb-5">
                <span>
                  <span class="h3 text-warning font-weight-bold mr-1">
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 200px; height: 20px"
                      />
                    </content-placeholders>
                  </span>
                </span>
              </div>
              <!-- kondisi -->
              <div class="row">
                <div class="col-md-3">
                  <div class="d-flex flex-row">
                    <div class="p-2 font-weight-bold">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 80px; height: 20px"
                        />
                      </content-placeholders>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-flex flex-row">
                    <div class="p-2 font-weight-bold">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 80px; height: 20px"
                        />
                      </content-placeholders>
                    </div>
                  </div>
                </div>
              </div>
              <!-- kuantitas -->
              <div class="row">
                <div class="col-md-3">
                  <div class="d-flex flex-row mb-1">
                    <div class="p-2 font-weight-bold">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 80px; height: 20px"
                        />
                      </content-placeholders>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-flex flex-row">
                    <div class="p-2">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 100px; height: 20px"
                        />
                      </content-placeholders>
                    </div>
                  </div>
                </div>
              </div>
              <!-- variant -->
              <div class="row">
                <div class="col-md-3">
                  <div class="d-flex flex-row mb-1">
                    <div class="p-2 font-weight-bold">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 80px; height: 20px"
                        />
                      </content-placeholders>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-flex flex-row">
                    <div class="p-2">
                      <content-placeholders :rounded="true">
                        <content-placeholders-text
                          :lines="1"
                          style="width: 80px; height: 20px"
                        />
                      </content-placeholders>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-add-cart">
                <div class="child-cart">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 250px; height: 20px"
                    />
                  </content-placeholders>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- detail toko -->
      <div
        class="container shadow-custom rounded bg-white"
        style="border-radius: 10px !important"
      >
        <div class="d-flex justify-content-start bd-highlight pt-3 mb-3 mr-4">
          <div
            class="font-weight-bold text-white"
            style="display: flex; align-items: center; justify-content: center"
          >
            <content-placeholders :rounded="true">
              <content-placeholders-img
                style="width: 80px; height: 80px"
                class="rounded-circle square-80 imguseravatar"
              />
            </content-placeholders>
          </div>
          <div class="mr-auto bd-highlight mt-4 ml-4">
            <div class="d-flex justify-content-start bd-highlight">
              <div class="bd-highlight text-product">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 200px; height: 20px"
                  />
                </content-placeholders>
              </div>
            </div>
            <div class="d-flex justify-content-start bd-highlight">
              <div class="bd-highlight">
                <small>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                </small>
              </div>
            </div>
          </div>
          <div class="bd-highlight">
            <div
              class="d-flex align-items-center bd-highlight mb-3"
              style="height: 100px"
            >
              <div class="bd-highlight">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 100px; height: 20px"
                  />
                </content-placeholders>
              </div>
              <div class="p-2 bd-highlight">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 100px; height: 20px"
                  />
                </content-placeholders>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- deksripsi produk -->
      <div
        class="container shadow-custom rounded bg-white py-2 pl-4 pr-4 mb-3"
        style="border-radius: 10px !important"
      >
        <div class="d-flex align-items-center bd-highlight">
          <div
            class="
              bd-highlight
              py-1
              pl-2
              pr-2
              mt-2
              mb-2
              rounded
              text-white
              font-weight-bold
            "
          >
            <content-placeholders :rounded="true">
              <content-placeholders-text
                :lines="1"
                style="width: 200px; height: 20px"
              />
            </content-placeholders>
          </div>
        </div>
        <div class="d-flex justify-content-start bd-highlight mb-3">
          <div class="bd-highlight">
            <div class="satu text-left">
              <div>
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 100px; height: 20px"
                  />
                </content-placeholders>
              </div>
              <div>
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 100px; height: 20px"
                  />
                </content-placeholders>
              </div>
              <div>
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 100px; height: 20px"
                  />
                </content-placeholders>
              </div>
              <div>
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 100px; height: 20px"
                  />
                </content-placeholders>
              </div>
              <div>
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 100px; height: 20px"
                  />
                </content-placeholders>
              </div>
            </div>
          </div>
          <div class="ml-4 bd-highlight">
            <div class="dua text-left">
              <div>
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 100px; height: 20px"
                  />
                </content-placeholders>
              </div>
              <div>
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 150px; height: 20px"
                  />
                </content-placeholders>
              </div>
              <div>
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 50px; height: 20px"
                  />
                </content-placeholders>
              </div>
              <div>
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 80px; height: 20px"
                  />
                </content-placeholders>
              </div>
              <div>
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 100px; height: 20px"
                  />
                </content-placeholders>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center bd-highlight">
          <div
            class="
              bd-highlight
              py-1
              pl-2
              pr-2
              mt-2
              mb-2
              rounded
              text-white
              font-weight-bold
            "
          >
            <content-placeholders :rounded="true">
              <content-placeholders-text
                :lines="1"
                style="width: 200px; height: 20px"
              />
            </content-placeholders>
          </div>
        </div>
        <div class="text-justify mb-2">
          <content-placeholders :rounded="true">
            <content-placeholders-heading />
          </content-placeholders>
        </div>
      </div>

      <!-- penilaian -->
      <div
        class="container shadow-custom rounded bg-white py-2 pl-4 pr-4 mb-3"
        style="border-radius: 10px !important"
      >
        <div class="d-flex align-items-center bd-highlight">
          <div
            class="
              bd-highlight
              py-1
              pl-2
              pr-2
              mt-2
              mb-3
              rounded
              text-white
              font-weight-bold
            "
          >
            <content-placeholders :rounded="true">
              <content-placeholders-text
                :lines="1"
                style="width: 200px; height: 20px"
              />
            </content-placeholders>
          </div>
        </div>

        <div class="d-flex justify-content-start bd-highlight mb-3">
          <div class="bd-highlight mr-auto">
            <div class="text-left">
              <h3 class="text-danger font-weight-bold">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    style="width: 200px; height: 20px"
                  />
                </content-placeholders>
              </h3>
            </div>
            <div class="text-left">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="1"
                  style="width: 150px; height: 20px"
                />
              </content-placeholders>
            </div>
          </div>
          <div class="bd-highlight mr-2">
            <b-form-group>
              <b-form-radio-group
                :options="filterReviewPlaceholder"
                button-variant="py-1 btn-outline-warning-custom pl-3 pr-3 rounded ml-3"
                size="sm"
                name="radio-btn-outline"
                buttons
                class=""
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div class="bd-highlight ml-auto"></div>
        </div>

        <div
          class="border border rounded py-4 mt-4 mb-4"
          style="
            border-right: 0px !important;
            border-left: 0px !important;
            border-bottom: 0px !important;
          "
        >
          <div v-for="(item, index) in itemReview" :key="index">
            <div
              class="row p-3 mr-2 ml-2"
              style="border-bottom: 1px solid rgba(0, 0, 0, 0.09)"
            >
              <div class="col-md-2">
                <div
                  class="font-weight-bold text-white"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <content-placeholders :rounded="true">
                    <content-placeholders-img
                      class="rounded-circle square-80 imguseravatar shadow"
                      style="width: 80px; height: 80px"
                    />
                  </content-placeholders>
                </div>
              </div>
              <div
                class="col-md-8"
                style="display: flex; justify-content: left"
              >
                <h6 class="text-left mb-4">
                  <b style="color: #65b0c9 !important">
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 200px; height: 20px"
                      />
                    </content-placeholders>
                  </b>
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 100px; height: 20px"
                    />
                  </content-placeholders>
                  <p style="text-align: left">
                    <content-placeholders :rounded="true">
                      <content-placeholders-heading />
                    </content-placeholders>
                  </p>
                  <p style="text-align: left">
                    <content-placeholders :rounded="true">
                      <content-placeholders-img
                        style="
                          width: 100%;
                          height: 80px;
                          max-width: 200px;
                          max-height: 200px;
                        "
                      />
                    </content-placeholders>
                  </p>
                </h6>
              </div>
              <div class="col-md-2">
                <p style="text-align: right">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 200px; height: 20px"
                    />
                  </content-placeholders>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- produk lain -->
      <div
        class="container rounded bg-default py-2 pl-4 mb-4"
        style="margin-top: 20px"
      >
        <div class="mt-4 mb-4" style="margin-left: -15px !important">
          <h3 class="text-left mb-4">Produk Lain di toko ini</h3>
          <cardSlider
            :data="dataDetailProduk.otherProduct"
            style="margin-left: -15px !important"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Flickity from "vue-flickity";
import cardSlider from "../../components/card/cardslider";
import firebase from "firebase/app";

export default {
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        wrapAround: false,
        freeScroll: false,
      },
      selectedReview: "",
      filterReview: [],
      filterReviewPlaceholder: [
        { text: "Semua" },
        { text: "5 Bintang" },
        { text: "4 Bintang" },
        { text: "3 Bintang" },
        { text: "2 Bintang" },
        { text: "1 Bintang" },
      ],
      itemReview: [],
      totalDataReview: 0,
      rating: 0,
      kuantitas: 1,
      options: [],
      selectedVariant: "",
      isWishlist: "",
      dataDetailProduk: {},
      dataDetailStore: {},
      dataDetailCat: {},
      detailProdukImage: [],
      detailProdukImageFirst: "",
      dataHtmlImage: ""[0],
      paramAdd: {
        productId: "",
        flashSaleId: "",
        lelangId: "",
        variantId: "",
        qty: 1,
      },
      isFinish: false,
    };
  },
  components: {
    Flickity,
    cardSlider,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  created() {
    this.getDetailProduct();
    this.getRating();
  },
  watch: {
    selectedReview() {
      this.getRating();
    },
  },
  methods: {
    getDetailProduct() {
      axios
        .get(`product/${this.$route.params.id}?variant_group=0`)
        .then((res) => {
          this.isFinish = true;
          this.dataDetailProduk = res.data.data;
          this.dataDetailStore = res.data.data.store;
          this.dataDetailCat = res.data.data.category;
          this.options = res.data.data.variant.map((item) => {
            return {
              value: item.id,
              text: item.name,
            };
          });
          this.isWishlist = res.data.data.isWishlist;
          this.rating = res.data.data.rating;
          this.detailProdukImage = res.data.data.image;
          this.detailProdukImageFirst = res.data.data.image[0];
          this.paramAdd.productId = res.data.data.id;
          this.paramAdd.variantId = res.data.data.variant.length
            ? res.data.data.variant
            : 0;
          const isi = res.data.data.image.map((item) => {
            return `<div class="p-2 bd-highlight">
							<img onclick="" src="${item}" style="width: 100px; height: 100px;">
						</div>`;
          });
          this.dataHtmlImage = isi.toString().replaceAll(",", "");
        });
    },
    getSrc(res) {
      document.getElementById(
        "gambarUtama"
      ).src = require("@/assets/loading.gif");
      setTimeout(() => {
        this.detailProdukImageFirst = res;
      }, 200);
    },
    cekLSToken() {
      this.$swal.fire({
        html: '<img src="https://jaja.id/asset/home/logo/Loading_2.gif" alt="">', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        productId: this.dataDetailProduk.id,
        flashSaleId: "",
        lelangId: "",
        variantId: this.selectedVariant,
        qty: this.kuantitas,
      });
      // var raw = JSON.stringify(this.paramAdd);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          console.log(
            "🚀 ~ file: DetaliProduk.vue ~ line 432 ~ cekLSToken ~ res",
            res
          );

          if (res.status.code === 200) {
            this.$swal.fire("Sukses", "Berhasil menambahkan data", "success");
            this.$store.dispatch("getInfo");
          } else {
            this.$swal.fire("Warning", res.status.message, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
    beliSekarang() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        productId: this.dataDetailProduk.id,
        flashSaleId: "",
        lelangId: "",
        variantId: this.selectedVariant,
        qty: this.kuantitas,
      });
      // var raw = JSON.stringify(this.paramAdd);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.$router.push("/cart/cart");
            this.$store.dispatch("getInfo");
          } else {
            this.$swal.fire("Warning", res.status.message, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
    beliSekarangNonPhysic() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        productId: this.dataDetailProduk.id,
        flashSaleId: "",
        lelangId: "",
        variantId: this.selectedVariant,
        qty: this.kuantitas,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            localStorage.setItem("is_non_physical", true);
            setTimeout(() => {
              this.$router.push("/payment");
            }, 500);
          } else {
            this.$swal.fire("Warning", res.status.message, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
    tambahWislist() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id_produk: this.dataDetailProduk.id,
      });
      // var raw = JSON.stringify(this.paramAdd);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/addWishlist`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                location.reload();
              });
            this.$store.dispatch("getInfo");
          }
        })
        .catch((error) => console.log("error", error));
    },
    getRating() {
      axios
        .get(
          `product/review/${this.$route.params.id}?page=1&limit=10&status=${this.selectedReview}`
        )
        .then((res) => {
          this.filterReview = res.data.data.filters.map((item) => {
            return {
              value: item.value,
              text: item.title + "   " + "(" + item.total + ") ",
            };
          });
          // this.filterReview = res.data.data.filters
          this.itemReview = res.data.data.items;
          this.totalDataReview = res.data.data.totalData;
        });
    },
    chatStore() {
      const dataProfile = this.user;
      const dataDetailStore = this.dataDetailProduk.store;
      const dataDetailProduct = this.dataDetailProduk;

      $("#chat-toko").attr("disabled", "true");
      $("#chat-toko").html('<i class="fas fa-circle-notch fa-spin"></i>');

      let getFirebaseTimestamp = firebase.database.ServerValue.TIMESTAMP;
      const database = firebase.database();
      let chatRoom = `${dataProfile.uid}${dataDetailStore.uid}`;

      database
        .ref(`friend/${dataDetailStore.uid}/${dataProfile.uid}`)
        .set({
          chat: chatRoom,
          name: dataProfile.name,
          message: {
            text: "",
            time: getFirebaseTimestamp,
          },
          amount: 0,
        })
        .then(() => {
          database
            .ref(`friend/${dataProfile.uid}/${dataDetailStore.uid}`)
            .set({
              chat: chatRoom,
              name: dataDetailStore.name,
              message: {
                text: "",
                time: getFirebaseTimestamp,
              },
              amount: 0,
            })
            .then(() => {
              const paramObjChat = {
                name: dataDetailStore.name,
                chat: chatRoom,
                id: dataDetailStore.uid,
                product: {
                  name: dataDetailProduct.name,
                  slug: dataDetailProduct.slug,
                  image: dataDetailProduct.image.length
                    ? dataDetailProduct.image[0]
                    : "https://seller.jaja.id/asset/images/img-thumbnail.png",
                  priceDiscount: dataDetailProduct.discount,
                  priceFirst: dataDetailProduct.price,
                  priceLast: dataDetailProduct.isDiscount
                    ? dataDetailProduct.priceDiscount
                    : dataDetailProduct.price,
                },
              };
              localStorage.setItem("paramToChat", JSON.stringify(paramObjChat));
              this.$router.push("/chat/");
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-warning {
  background-color: #fdb738 !important;
}

.btn-warning {
  color: #212529;
  background-color: #fdb738;
  border-color: #fdb738;
}
.header-info {
  background-image: linear-gradient(
    90deg,
    rgb(40, 93, 166) 0%,
    rgb(0, 118, 193) 0%,
    rgb(0, 174, 239) 97%
  );
}

.img-cover-product img {
  height: 300px;
}

.img-cover-child {
  width: 50px;
  height: 50px;
}
.img-cover-child img {
  object-fit: contain;
}

.card-detail-product {
  box-shadow: 10px 10px 5px #8382826c;
}

.mt-price {
  margin-bottom: 8% !important;
}

.mb-price {
  margin-bottom: 9% !important;
}
.card-add-cart {
  /* padding-top: 5%; */
  flex: 1; /* height: 10%; */
  display: flex;
  flex-direction: column;
}

.child-cart {
  display: flex;
  flex-direction: row;
  margin-top: auto;
}

.satu {
  width: 170px;
}

.imguseravatar {
  object-fit: contain;
  width: 100px;
  height: 100px;
  border: 4px solid #fff;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
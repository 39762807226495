<template>
    <div class="container p-3">
        <div class="row">
            <div class="col-md-3">
                <sideBar />
            </div>
            <div class="col-md-9">
                <div class="d-flex flex-column text-left bd-highlight mb-3 shadow-custom card-product">
                    <div class="py-3 px-2 bd-highlight card-header-danakembali">
                        <div class="d-flex justify-content-start bd-highlight ">
                            <div class="ml-3 bd-highlight mr-auto">
                                <h5 class="text-white font-weight-bold d-block">Pemberitahuan Saya</h5>
                            </div>
                        </div>
                    </div>
                    <div class=" bd-highlight bg-white p-4 parent-card-body-danakembali shadow-custom">
                        <div v-if="dataNotif.length > 0">
                            <div v-for="(item, index) in dataNotif" :key="index" style="width: 100%" class="d-flex align-items-start flex-column card-body-danakembali">
                                <li  class="list-group-item hover-primary "  :class="item.read == 'T'? ` bg-light-blue ` : ` ` " style="width: 100%">               
                                    <a type="button" @click="goToDetailPemberitahuan(item.id_notif, item.invoice)" class="text-dark">
                                        <div class="row">
                                            <div class="col-md-0">                                
                                                <span class="ml-2 mt-2 center-middle-custom">
                                                    <img src="https://nimda.jaja.id/asset/images/icon-jaja/Icon%20caution-10.png"  width="40" v-if="item.title == 'Komplain Pesanan'">
                                                    <img src="https://nimda.jaja.id/asset/images/icon-jaja/Icon Cart-57.png"  width="40" v-else/>
                                                </span>
                                            </div>
                                            <div  class="col">
                                                <p class="mb-0"><small><b>{{item.title}}</b></small></p>
                                                <p class="mb-0"> <small>{{item.text}}</small></p>
                                                <small class="mb-0 clsa">{{item.created_date}}</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </div>
                        </div>
                        <div class="row" v-else-if="isFinish">
                            <div class="col-md-12 col-12 carousel-cell is-selected">
                                <div class="CheckShowCheck card-product mb-3" style="background: white !important;">
                                    <div class="text-center">
                                        <div></div>
                                        <img slot="image" src="https://nimda.jaja.id/asset/icon-jaja/notfound.png" class="img-fluid" style="max-height: 400px; max-width: 100%; width: auto; border-radius: 10px;"/>
                                        
                                        <div class="text-dark">
                                            <div class="keterangan ResetKet_JajaID">
                                                <h4 class="text-product one-line fsxx text-center" style="margin-top: -20px">Looks like the data you are looking for is empty.</h4>	
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container mt-4" v-else>
                            <div class="row" v-for='index in 4' :key='index'>
                                <div class="col-2">
                                    <content-placeholders :rounded="true">
                                    <content-placeholders-img style="width: 100px; height: 50px; margin-bottom: 20px;"/>
                                    </content-placeholders>
                                </div>
                                <div class="col-10">
                                    <content-placeholders :rounded="true">
                                    <content-placeholders-heading style="margin-bottom: 20px;"/>
                                    </content-placeholders>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script type="text/javascript" src="https://app.midtrans.com/snap/snap.js" data-client-key="Mid-client-S9f6KNmuVrQre0GQ"></script>
<script>

    import {mapGetters} from 'vuex';
    
    import VueBase64FileUpload from 'vue-base64-file-upload';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    // import 'vue2-datepicker/locale/zh-cn';
    import sideBar from '../../components/sidebar/sideBar'
    export default {
		data() {
			return {
                dataNotif: [],
                isFinish: false
                }
        },
        components:{
            sideBar,
            DatePicker,
            VueBase64FileUpload
        },
		computed: {
            ...mapGetters({globalToken:'globalTokenGetters'}),
            ...mapGetters({user:'user'}),
        },
       mounted() {
            this.getPemberitahuan()
        },
		methods: {
            getPemberitahuan() {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", this.globalToken);
                myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${this.domainURL}notifikasi/${this.user.id}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                    if(res.status.code === 200) {
                        this.isFinish = true
                        this.dataNotif = res.data.notifikasi
                        // console.log(res.data.notifikasi);
                    }
                })
                .catch(error => console.log('error', error));
            },
            goToDetailPemberitahuan(id, invoice) {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", this.globalToken);
                myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };

                fetch(`${this.domainURL}notifikasi/updateNotif?id_notif=${id}`, requestOptions)
                .then(response => response.json())
                .then(res => {
                    if(res.status.code === 200) {
                        this.$router.push(`/order/${invoice}`)
                    }
                })
                .catch(error => console.log('error', error));
            },
		}
    }
</script>
<style lang="scss" scoped>
    .iconPayment {
        width:50%;
        margin-right:5%
    }
    .mx-datepicker {
        width: 100%;
    }
    .parent-card-body-danakembali {
        border-radius:  0 0 10px 10px ;
    }
    .card-header-danakembali {
        background: rgb(100, 175, 200);
        border-radius: 10px 10px 0 0 ;
    }


    .card-body-danakembali {
        width:100%;
        height:auto;
        border: 1px solid  rgb(100, 175, 200);
        border-radius: 10px ;

    }

    .kotak-profil-1 {
        margin-right:15%;
    }
    .bg-jaja {
        background: rgb(100, 175, 200);
    }
</style>
import { render, staticRenderFns } from "./lupa-password-verification.vue?vue&type=template&id=b6039202&scoped=true&"
import script from "./lupa-password-verification.vue?vue&type=script&lang=js&"
export * from "./lupa-password-verification.vue?vue&type=script&lang=js&"
import style0 from "./lupa-password-verification.vue?vue&type=style&index=0&id=b6039202&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6039202",
  null
  
)

export default component.exports
<template>
  <section>
    <div v-if="recordsLength > 0">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <h4 style="text-align: left; margin-top: 20px">
              <b>Keranjang Belanjaku</b>
            </h4>
          </div>
          <div class="col-6">
            <div class="tombol float-right" style="margin-top: 15px">
              <button
                @click="belanjaLagi()"
                class="btn btn-warning float-right btn btn-warning float-right"
                style="color: white"
              >
                <i class="fas fa-arrow-left"></i> &nbsp; <b>Belanja Lagi</b>
              </button>
            </div>
          </div>
        </div>
        <div
          v-for="(item, index) in dataCart.items"
          :key="index"
          class="card-product shadow-custom mb-4 mt-4"
        >
          <div class="card-header br-card-header-jaja text-left">
            <div class="custom-control custom-checkbox d-inline">
              <input
                @change="checkListProduct(item.store.id + ':store')"
                type="checkbox"
                class="custom-control-input"
                :id="'ceklisTokoDekstop' + index"
                :checked="item.isSelected"
              />
              <label
                class="custom-control-label"
                :for="'ceklisTokoDekstop' + index"
              >
                <img
                  src="https://nimda.jaja.id/asset/icon-jaja/icon-cart.png"
                  alt="icon"
                  class="mr-2 mb-1"
                  style="width: 16px; height: 16px; margin-left: 30px"
                /><b>
                  <router-link
                    :to="'/home/DetailToko/' + item.store.slug"
                    class="text-white"
                    >{{ item.store.name }}</router-link
                  >
                </b>
              </label>
            </div>
          </div>

          <table
            class="table tabel-striped Table_JajaID mb-0 desktop-only ml-1 mr-1"
          >
            <tbody class="HeaderCartBody_JajaID" id="show-all-cart">
              <tr class="Barang_JajaID">
                <td class="text-center MiddleCenter" style="width: 5%"></td>
                <td class="MiddleCenter" style="width: 30%">
                  <div class="media">
                    <div
                      class="media-body"
                      style="
                        margin-top: auto;
                        margin-bottom: auto;
                        text-align: left;
                      "
                    >
                      <b>Produk</b>
                    </div>
                  </div>
                </td>
                <td style="vertical-align: middle">
                  <b class="text-dark"> &emsp; Variant</b>
                </td>
                <td style="vertical-align: middle">
                  <b class="text-dark"> &emsp; Harga</b>
                </td>
                <td
                  class="MiddleCenter"
                  style="
                    width: 20%;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                  "
                >
                  <div>
                    <b class="text-dark text-center">Kuantitas</b>
                  </div>
                </td>
                <td
                  class="text-center my-product-total MiddleCenter"
                  style="vertical-align: middle"
                >
                  <input
                    type="hidden"
                    id="getHarga-5303"
                    value="36000"
                    data-pilih="T"
                  /><span id="harga-5303"
                    ><b class="text-dark"> &emsp; Sub Total</b></span
                  >
                </td>
                <td class="" style="width: 10%; vertical-align: middle"></td>
              </tr>
              <tr
                v-for="(a, b) in item.products"
                :key="b"
                class="Barang_JajaID"
              >
                <td class="text-center MiddleCenter" style="width: 5%">
                  <div class="custom-control custom-checkbox">
                    <input
                      @change="checkListProduct(a.cartId + ':cart')"
                      type="checkbox"
                      class="custom-control-input check-product"
                      :id="'cartProduk' + a.cartId"
                      :checked="a.isSelected"
                    />
                    <label
                      class="custom-control-label"
                      :for="'cartProduk' + a.cartId"
                    ></label>
                  </div>
                </td>
                <td class="MiddleCenter" style="width: 30%">
                  <div class="media">
                    <div class="img-cart-item mr-2">
                      <img
                        :src="a.image"
                        height="60px"
                        width="60px"
                        style="object-fit: contain"
                      />
                    </div>
                    <div
                      class="media-body"
                      style="
                        margin-top: auto;
                        margin-bottom: auto;
                        text-align: left;
                      "
                    >
                      <router-link
                        :to="'/home/DetailProduk/' + a.slug"
                        style="color: #64b0c9"
                      >
                        <b>{{ a.name }}</b>
                      </router-link>
                    </div>
                  </div>
                </td>
                <td style="vertical-align: middle" v-if="a.variant != null">
                  <b style="color: #64b0c9"> &emsp; {{ a.variant }}</b>
                </td>
                <td style="vertical-align: middle" v-else>
                  <b style="color: #64b0c9"> &emsp; -</b>
                </td>
                <td style="vertical-align: middle">
                  <b style="color: #64b0c9">
                    &emsp;
                    <strike :class="a.isDiscount ? `` : `d-none`">
                      <small>{{ a.priceCurrencyFormat }}</small>
                    </strike>
                    &nbsp;
                    <span
                      :class="a.isDiscount ? `badge badge-danger` : `d-none`"
                    >
                      {{ a.discount }} %</span
                    >
                    <br />
                    <span class="text-primary-jaja font-weight-bold mr-1">{{
                      a.isDiscount
                        ? a.priceDiscountCurrencyFormat
                        : a.priceCurrencyFormat
                    }}</span>
                  </b>
                </td>
                <td
                  class="MiddleCenter"
                  style="
                    width: 20%;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                  "
                >
                  <center>
                    <div class="d-flex justify-content-center bd-highlight">
                      <div class="p-2 bd-highlight">
                        <span
                          class="minus btn"
                          @click="
                            addQty(
                              a.productId +
                                ':' +
                                a.variantId +
                                ':' +
                                a.qty +
                                ':less'
                            )
                          "
                          style="
                            color: #212529;
                            background-color: white;
                            border-color: grey;
                            border-radius: 10px;
                          "
                          >-</span
                        >
                      </div>
                      <div class="p-2 bd-highlight">
                        <input
                          type="number pl-1"
                          min="1"
                          class="count"
                          :value="a.qty"
                          readonly
                          style="
                            color: #212529;
                            background-color: #f8f9fa;
                            border-radius: 10px;
                            text-align: center;
                          "
                        />
                      </div>
                      <div class="p-2 bd-highlight">
                        <span
                          class="plus btn"
                          @click="
                            addQty(
                              a.productId +
                                ':' +
                                a.variantId +
                                ':' +
                                a.qty +
                                ':add'
                            )
                          "
                          style="
                            color: #212529;
                            background-color: white;
                            border-color: grey;
                            border-radius: 10px;
                          "
                          >+</span
                        >
                      </div>
                    </div>
                    <small class="text-muted"
                      ><b
                        >Stok :
                        <span class="stokAvailable">{{
                          a.stockRemaining
                        }}</span>
                      </b>
                    </small>
                  </center>
                </td>
                <td
                  class="text-center my-product-total MiddleCenter"
                  style="vertical-align: middle"
                >
                  <input
                    type="hidden"
                    id="getHarga-5303"
                    value="36000"
                    data-pilih="T"
                  /><span id="harga-5303"
                    ><b style="color: #64b0c9">
                      &emsp; {{ a.subTotalCurrencyFormat }}</b
                    ></span
                  >
                </td>
                <td class="" style="width: 10%; vertical-align: middle">
                  <a
                    @click="hapusCart(a.cartId)"
                    class="btn btn-danger"
                    style="border-radius: 5px"
                  >
                    <i class="fas fa-trash-alt text-white"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container">
        <pagination
          style="display: flex; align-items: center; justify-content: center"
          :records="recordsLength"
          v-model="page"
          :per-page="perPage"
          @paginate="getDataCart"
        >
        </pagination>
      </div>
      <div class="container">
        <div class="row card-product shadow-custom p-3">
          <div class="col-md-8 text-left">
            <h5 class="font-weight-bold">
              Total Harga
              <span class="text-secondary-jaja font-weight-bold">
                {{ dataCart.totalCartCurrencyFormat }}
              </span>
            </h5>
          </div>
          <div class="col-md-4">
            <div class="tombol">
              <button
                @click="cekIsHasAddress(dataCart.isHasAddress)"
                class="btn btn-primary float-right btn btn-primary float-right"
                style="
                  color: #fff;
                  background-color: #64b0c9 !important;
                  border-color: #64b0c9 !important;
                  font-weight: bold;
                "
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-4" v-else-if="isFinish">
      <div class="row">
        <div class="col-md-12 col-12 carousel-cell is-selected">
          <div
            class="CheckShowCheck card-product p-3 mb-3 shadow-custom"
            style="background: white !important"
          >
            <div class="">
              <div></div>
              <img
                slot="image"
                src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                class="img-fluid"
                style="
                  max-height: 400px;
                  max-width: 100%;
                  width: auto;
                  border-radius: 10px;
                "
              />

              <div class="text-dark">
                <div class="keterangan ResetKet_JajaID">
                  <h4
                    class="text-product one-line fsxx text-center"
                    style="margin-top: -20px"
                  >
                    Looks like your cart is empty.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-4" v-else>
      <div class="row" v-for="index in 4" :key="index">
        <div class="col-2">
          <content-placeholders :rounded="true">
            <content-placeholders-img
              style="width: 160px; height: 50px; margin-bottom: 20px"
            />
          </content-placeholders>
        </div>
        <div class="col-10">
          <content-placeholders :rounded="true">
            <content-placeholders-heading style="margin-bottom: 20px" />
          </content-placeholders>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dataCart: {},
      page: 1,
      perPage: 5,
      recordsLength: 0,
      pageSizes: [12, 24, 32],
      isFinish: false,
    };
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
  },
  created() {
    this.getDataCart();
  },
  methods: {
    getDataCart: function (page) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}cart?page=${this.page}&limit=${this.perPage}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.dataCart = res.data;
            this.recordsLength = res.data.totalData;
          }
          this.isFinish = true;
        })
        .catch((error) => console.log("error", error));
    },
    handlePageSizeChange(event) {
      this.perPage = event.target.value;
      this.page = 1;
      this.getDataCart(this.page);
    },
    addQty(params) {
      const arr = params.split(":");
      const productId = arr[0];
      const variantId = arr[1];
      const qty = arr[2];
      const action = arr[3];

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        productId: productId,
        variantId: variantId,
        qty: qty,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart/qty?action=${action}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.getDataCart();
            // this.$swal
            //   .fire(
            //     "Berhasil",
            //     `Cart berhasil di ${
            //       action == "add" ? `tambahkan` : `dikurangi`
            //     }`,
            //     "success"
            //   )
            //   .then(() => {
            //     this.getDataCart();
            //   });
          } else {
            this.getDataCart();
            // this.$swal
            //   .fire("Warning", res.status.message, "warning")
            //   .then(() => {
            //     this.getDataCart();
            //   });
          }
        })
        .catch((error) => console.log("error", error));
    },
    hapusCart(id) {
      this.$swal
        .fire({
          title: "Warning",
          text: "Apakah anda yakin akan menghapus data?",
          icon: "warning",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Hapus",
          denyButtonText: `Batalkan`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.runDeleteCart(id);
          }
        });
    },
    runDeleteCart(id) {
      this.$swal.fire({
        html: '<img src="https://jaja.id/asset/home/logo/Loading_2.gif" alt="">', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart/${id}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.$swal
              .fire({
                title: "Sukses",
                text: "Berhasil menghapus data",
                icon: "success",
                timer: 2000,
                showCancelButton: false,
                showConfirmButton: true,
              })
              .then(() => {
                this.getDataCart();
              });
          } else {
            this.$swal.fire("Gagal", `${res.status.code}`, "warning");
          }
        })
        .catch((error) => console.log("error", error));
    },
    checkListProduct(data) {
      const isi = data.split(":");
      const params =
        isi[1] == "cart"
          ? {
              cartId: isi[0],
              storeId: "",
            }
          : {
              cartId: "",
              storeId: isi[0],
            };
      console.log(params);

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(params);

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}cart/selected`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.getDataCart();
          }
        })
        .catch((error) => console.log("error", error));
    },
    cekIsHasAddress(isHasAddress) {
      isHasAddress
        ? this.cekChooseProduct()
        : this.$swal
            .fire({
              title: "Alamat Kosong",
              text: "Silahkan isi alamat terlebih dahulu",
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: "Isi Alamat",
              denyButtonText: `Batalkan`,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.push("/tambahalamat");
              }
            });
    },
    cekChooseProduct() {
      this.dataCart.totalProductSelected
        ? this.pindahHalaman()
        : this.$swal.fire(
            "Produk Belum Dipilih",
            `Silahkan pilih produk terlebih dahulu`,
            "warning"
          );
    },
    pindahHalaman() {
      this.$router.push("/payment");
    },
    belanjaLagi() {
      this.$router.push("/");
    },
    ...mapActions({
      getShipping: "getShipping",
    }),
  },
};
</script>
<style>
.count {
  width: 50%;
  height: 100%;
}
</style>
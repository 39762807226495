<template>
    <div class="row">
        
        
    </div>

</template>
<script>
    export default {
        computed:{
            recommendedGetters(){
                return this.$store.getters.recommendedGetters
            },
            homeGetters() {
                return this.$store.getters.homeGetters
            }
        },
    }
</script>
<template>
  <div>
    <div class="container p-3" v-if="isFinish">
      <div class="row">
        <div class="col-md-3">
          <sideBar />
        </div>
        <div class="col-md-9">
          <div class="card-product shadow-custom br-card-jaja">
            <div class="card-header br-card-header-jaja">
              <!-- <h6 class="d-flex">Alamat Saya</h6> -->
              <div class="d-flex justify-content-between bd-highlight">
                <div class="bd-highlight center-middle-custom">
                  <h5 class="d-flex text-white font-weight-bold">
                    Alamat Saya
                  </h5>
                </div>
                <div class="bd-highlight center-middle-custom">
                  <button
                    type="button"
                    @click="toAddAddress"
                    class="btn btn-primary font-weight-bold btn-warning-custom"
                  >
                    Tambah Alamat
                  </button>
                </div>
              </div>
            </div>
            <div></div>
            <div class="card-body">
              <div class="data-alamat" v-if="location.length > 0">
                <div v-for="(item, index) in location" :key="index" class="">
                  <small class="float-right">
                    <a
                      @click="toEditAddress(item.id)"
                      class="
                        tobepointer
                        btn btn-warning btn-sm
                        text-white
                        font-weight-bold
                      "
                      >Ubah</a
                    >
                    <a
                      @click="deleteAddress(item.id)"
                      class="
                        ml-3
                        btn btn-danger btn-sm
                        font-weight-bold
                        tobepointer
                      "
                      >Hapus</a
                    >
                    <div>
                      <b-form-checkbox
                        switch
                        size="lg"
                        class="mt-5"
                        @change="changePrimaryAddr(item.id)"
                        :checked="item.is_primary"
                        :disabled="item.is_primary"
                        style="text-align: right"
                      >
                        <small
                          class="font-weight-bold text-primary-jaja"
                          v-if="item.is_primary"
                          >Alamat Utama</small
                        >
                        <small class="font-weight-bold text-secondary" v-else
                          >Alamat Utama</small
                        >
                      </b-form-checkbox>
                    </div>
                  </small>

                  <p class="d-flex">
                    <img
                      src="https://nimda.jaja.id/asset/icon-jaja/location.png"
                      style="width: 20px"
                    />
                    &nbsp; {{ item.label }}
                  </p>
                  <div class="d-flex justify-content-start bd-highlight">
                    <div class="bd-highlight">
                      {{ item.nama_penerima }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-start bd-highlight">
                    <div class="bd-highlight">
                      {{
                        item.alamat_lengkap.length > 50
                          ? item.alamat_lengkap.slice(0, 90).concat("...")
                          : item.alamat_lengkap
                      }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-start bd-highlight">
                    <div class="bd-highlight">
                      <span>Hp. {{ item.no_telepon }}</span>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <div class="row" v-else>
                <div class="col-md-12 col-12 carousel-cell is-selected">
                  <div
                    class="CheckShowCheck card-product mb-3"
                    style="background: white !important"
                  >
                    <div class="text-center">
                      <div></div>
                      <img
                        slot="image"
                        src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                        class="img-fluid"
                        style="
                          max-height: 400px;
                          max-width: 100%;
                          width: auto;
                          border-radius: 10px;
                        "
                      />

                      <div class="text-dark">
                        <div class="keterangan ResetKet_JajaID">
                          <h4
                            class="text-product one-line fsxx text-center"
                            style="margin-top: -20px"
                          >
                            Looks like the data you are looking for is empty.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="addAddress">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Form Tambah Alamat</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <!-- {{user}} -->
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="isi nama anda"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="number"
                        name="phoneNumber"
                        class="form-control"
                        placeholder="isi Handphone anda"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="isi Email anda"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="number"
                        name="phoneNumber"
                        class="form-control"
                        placeholder="isi Handphone anda"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="isi nama anda"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="number"
                        name="phoneNumber"
                        class="form-control"
                        placeholder="isi Handphone anda"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="isi Email anda"
                      />
                    </div>
                    <div class="form-group">
                      <div
                        class="d-flex justify-content-between bd-highlight mb-3"
                      >
                        <div class="p-2 bd-highlight">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="gender"
                              id="flexRadioDefault1"
                              value="kantor"
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Alamat Kantor
                            </label>
                          </div>
                        </div>
                        <div class="p-2 bd-highlight">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="gender"
                              id="flexRadioDefault2"
                              value="rumah"
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault2"
                            >
                              Alamat Rumah
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="number"
                        name="phoneNumber"
                        class="form-control"
                        placeholder="isi Handphone anda"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <input type="text" class="latit" />
                    <input type="text" class="longi" />
                    <!-- <mapGoggle /> -->
                  </div>
                </div>
              </form>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
              <button type="button" class="btn btn-info">Submit</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container p-3" v-if="!isFinish">
      <div class="row">
        <div class="col-md-3">
          <sideBar />
        </div>
        <div class="col-md-9">
          <div class="card-product shadow-custom br-card-jaja">
            <div class="card-header br-card-header-jaja">
              <!-- <h6 class="d-flex">Alamat Saya</h6> -->
              <div class="d-flex justify-content-between bd-highlight">
                <div class="bd-highlight center-middle-custom">
                  <h5 class="d-flex text-white font-weight-bold">
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 200px"
                      />
                    </content-placeholders>
                  </h5>
                </div>
                <div class="bd-highlight center-middle-custom">
                  <content-placeholders :rounded="true">
                    <content-placeholders-img
                      style="width: 150px; height: 30px"
                    />
                  </content-placeholders>
                </div>
              </div>
            </div>
            <div></div>
            <div class="card-body">
              <div class="data-alamat">
                <div v-for="index in 3" :key="index" class="">
                  <small class="float-right">
                    <content-placeholders :rounded="true">
                      <content-placeholders-img
                        style="width: 150px; height: 30px"
                      />
                    </content-placeholders>
                    <div class="mt-5 float-right">
                      <content-placeholders :rounded="true">
                        <content-placeholders-img
                          style="width: 100px; height: 30px"
                        />
                      </content-placeholders>
                    </div>
                  </small>

                  <p class="d-flex">
                    <content-placeholders :rounded="true">
                      <content-placeholders-img
                        style="width: 100px; height: 30px"
                      />
                    </content-placeholders>
                  </p>
                  <div class="d-flex justify-content-start bd-highlight">
                    <div class="bd-highlight">
                      <content-placeholders :rounded="true">
                        <content-placeholders-heading style="width: 250px" />
                      </content-placeholders>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script type="text/javascript" src="https://app.midtrans.com/snap/snap.js" data-client-key="Mid-client-S9f6KNmuVrQre0GQ"></script>
<script>
import { mapGetters } from "vuex";

import VueBase64FileUpload from "vue-base64-file-upload";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import sideBar from "../../components/sidebar/sideBar";
import mapGoggle from "../../components/mapcomponent/mapcomp.vue";

export default {
  data() {
    return {
      customImageMaxSize: 3,
      tanggal: null,
      reqUpdateProfil: {
        name: "",
        phoneNumber: null,
        email: "",
        gender: "",
        birthDate: "",
        photo: "",
      },
      dataProfil: {},
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      isFinish: false,
      location: [],
    };
  },
  components: {
    sideBar,
    DatePicker,
    VueBase64FileUpload,
    mapGoggle,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  beforeMount() {
    this.getLoc();
  },
  methods: {
    getLoc() {
      this.location = this.user.location;
      this.isFinish = true;
    },
    toAddAddress() {
      this.$router.push("/tambahalamat");
    },
    toEditAddress(id) {
      this.$router.push(`/editalamat/${id}`);
    },
    deleteAddress(id) {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda ingin akan menghapus alamat ini ?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Hapus",
          denyButtonText: `Batalkan`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.runDeleteAddress(id);
          }
        });
    },
    runDeleteAddress(id) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}user/deleteAddress?id_alamat=${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.$swal
              .fire("Sukses", "Berhasil manghapus data", "success")
              .then(() => {
                this.$store.dispatch("get_user");
              });
          } else {
            alert("gagal");
          }
        })
        .catch((error) => console.log("error", error));
    },
    tambahAlamat() {
      $("#addAddress").modal("show");
    },
    changePrimaryAddr(data) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        addressId: data,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/changePrimaryAddress`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.$swal
              .fire("Sukses", "Berhasil Mengubah Data", "success")
              .then(() => {
                location.reload();
              });
          }
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>
<style>
.iconPayment {
  width: 50%;
  margin-right: 5%;
}
.mx-datepicker {
  width: 100%;
}
</style>
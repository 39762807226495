<template>
  <div class="row">
    <div class="container p-3">
      <div class="row">
        <div class="col-md-3 desktop-only">
          <sideBar />
        </div>

        <!-- CONTENT -->

        <div class="col-md-9" v-if="isFinish">
          <div class="d-flex justify-content-between bd-highlight">
            <div class="p-2 bd-highlight">
              <span class="font-weight-bold h5">Status Pesanan</span>
            </div>
            <div class="p-2 bd-highlight"></div>
            <div
              class="
                p-2
                bd-highlight
                bg-primary-jaja
                statusorder
                text-center text-white
                rounded
                font-weight-bold
              "
            >
              {{ statusInfo }}
            </div>
          </div>

          <div class="d-flex justify-content-between bd-highlight">
            <div class="p-2 bd-highlight mt-2 mb-2 text-left">
              <span class="font-weight-bold h5">#{{ jjdOrInv }}</span>
              <br />
              <span class="font-weight-bold p text-left"
                >Tanggal pemesanan
                <span style="color: red">{{ dataOrder.createdAt }}</span></span
              >
            </div>
            <div v-if="statusOrder == 'notPaid'"></div>
            <div class="center-middle-custom" v-else>
              <a
                :href="dataOrder.downloadOrderPdf"
                class="
                  p-2
                  bd-highlight
                  bg-primary-jaja
                  mt-2
                  mb-2
                  statusorder
                  text-center text-white
                  rounded
                  font-weight-bold
                "
              >
                <i class="fa fa-download"></i><span> Invoice</span>
              </a>
            </div>
          </div>

          <div class="row">
            <div class="col-md-7">
              <div
                v-if="dataTracking.tracking && dataTracking.tracking.length"
                class="mb-3"
              >
                <div class="card">
                  <div class="card-header br-card-header-jaja">
                    <div class="d-flex justify-content-start bd-highlight">
                      <div class="bd-highlight text-white text-bold mr-auto">
                        Informasi Pengiriman
                      </div>
                      <div class="bd-highlight text-white text-bold">
                        <router-link :to="'/tracking/' + this.$route.params.id">
                          <button
                            class="btn btn-sm btn-warning font-weight-bold"
                          >
                            Detail
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 text-left">
                        <div class="text-bold">
                          {{ dataTracking.items[0].shippingSelected.name }} -
                          {{ dataTracking.trackingId }}
                        </div>
                        <div class="text-primary-jaja text-bold">
                          {{ dataTracking.tracking[0].description }}
                        </div>
                        <div>
                          {{ dataTracking.tracking[0].date }}
                          {{ dataTracking.tracking[0].time }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="alamat"
                v-for="(item, index) in dataItemProduk"
                :key="index"
              >
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja mb-3">
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left fsx text-white text-bold">{{
                        item.invoice
                      }}</span>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5 text-left">
                          <span
                            ><b>{{ item.address.receiverName }}</b> <br />{{
                              item.address.phoneNumber
                            }}</span
                          >
                        </div>
                        <div class="col-md-7 text-left">
                          <span class="">{{ item.address.address }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5 text-left">
                          <span><b>Catatan</b> </span>
                        </div>
                        <div class="col-md-7 text-left">
                          <span class="">{{ item.note }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="card br-card-jaja">
                      <div class="card-header bg-secondary-jaja">
                        <router-link
                          :to="'/home/DetailToko/' + item.store.slug"
                          class="mb-0 float-left fsx text-white text-bold"
                        >
                          <img
                            src="https://nimda.jaja.id/asset/icon-jaja/icon-cart.png"
                            alt="icon"
                            class="mr-2 mb-1"
                            style="width: 16px; height: 16px"
                          />
                          {{ item.store.name }}
                        </router-link>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="card-body">
                            <div
                              class="row pb-2"
                              v-for="(a, b) in item.products"
                              :key="b"
                            >
                              <div class="col-md-2">
                                <div class="media">
                                  <img
                                    :src="a.image"
                                    class="img-thumbnail img-responsive"
                                    width="45px"
                                    height="40px"
                                  />
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="row">
                                  <div class="col-md-5">
                                    <router-link
                                      :to="'/home/DetailProduk/' + a.slug"
                                    >
                                      <span
                                        ><a
                                          class="
                                            d-flex
                                            text-dark text-bold text-left
                                          "
                                          >{{ a.name }}
                                        </a></span
                                      >
                                    </router-link>
                                    <div class="row">
                                      <div class="col-md-12">
                                        <span class="float-left text-dark"
                                          >{{ a.qty }} X
                                        </span>
                                        <span
                                          class="
                                            float-left
                                            text-primary-jaja text-bold
                                          "
                                        >
                                          &nbsp;
                                          {{
                                            a.isDiscount
                                              ? a.priceDiscountCurrencyFormat
                                              : a.priceCurrencyFormat
                                          }}
                                          &nbsp;
                                        </span>
                                        <span
                                          class="
                                            float-left
                                            text-primary-jaja text-bold
                                          "
                                        >
                                          <strike>
                                            {{
                                              a.isDiscount
                                                ? a.priceCurrencyFormat
                                                : a.priceDiscountCurrencyFormat
                                            }}
                                          </strike>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="row" v-if="a.variant">
                                      <div class="col-md-12">
                                        <span class="float-left text-dark"
                                          >Variant :</span
                                        >
                                        <span
                                          class="
                                            float-left
                                            text-primary-jaja text-bold
                                          "
                                        >
                                          &nbsp;
                                          {{ a.variant }}
                                          &nbsp;
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="
                                      col-md-4
                                      text-primary-jaja text-bold
                                      align-self-end
                                    "
                                  >
                                    <span class="text-primary-jaja"
                                      ><b>
                                        {{
                                          a.isDiscount
                                            ? a.subTotalCurrencyFormat
                                            : a.subTotalCurrencyFormat
                                        }}</b
                                      ></span
                                    >
                                  </div>
                                  <div
                                    class="
                                      col-md-3
                                      text-primary-jaja text-bold
                                      align-self-end
                                      p-0
                                    "
                                  >
                                    <div
                                      v-for="(item, index) in dataItemProduk"
                                      :key="index"
                                    >
                                      <div
                                        v-if="
                                          statusOrder === 'done' &&
                                          item.isRating
                                        "
                                      >
                                        <button
                                          type="button"
                                          class="
                                            btn btn-sm btn-primary
                                            bg-warning
                                            text-white
                                            font-weight-bold
                                            br-button-jaja
                                          "
                                          data-dismiss="modal"
                                          style="
                                            background: #fdb738;
                                            border-color: #fdb738;
                                            font-size: 13px;
                                          "
                                          @click="beriRatingmModal(b)"
                                        >
                                          Beri Rating
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr class="m-0" />

                      <div
                        class="
                          card-footer
                          text-muted
                          bg-white
                          br-card-footer-jaja
                        "
                      >
                        <div class="row">
                          <div class="col-md-12 mb-2 mt-2">
                            <div class="row">
                              <div class="col-md-6 col-6">
                                <button
                                  type="button"
                                  class="
                                    btn btn-sm
                                    bg-primary
                                    float-left
                                    text-white
                                    br-button-jaja
                                  "
                                  style="
                                    background: #42b78f !important;
                                    font-weight: bold;
                                  "
                                  @click="chatStore(index)"
                                  id="chat-toko"
                                >
                                  <i class="fas fa-comment-dots"></i>
                                  &nbsp; Chat Penjual Sekarang
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="row desktop-only mb-2 mt-2"
                          v-if="item.voucherDiscount != 0"
                        >
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left"
                              >Voucher Toko</a
                            >
                            <span class="float-right">
                              <b
                                ><span class="float-right text-danger-jaja">{{
                                  item.voucherDiscountCurrencyFormat
                                }}</span>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div
                          class="row desktop-only mb-2 mt-2"
                          v-if="item.voucherDiscount == 0"
                        >
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left"
                              >Voucher Toko</a
                            >
                            <span class="float-right">
                              <b
                                ><span class="float-right">{{
                                  item.voucherDiscountCurrencyFormat
                                }}</span>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left"
                              >Sub Total</a
                            >
                            <span class="float-right">
                              <b>{{ item.totalCurrencyFormat }}</b>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          card-footer
                          text-muted
                          bg-white
                          br-card-footer-jaja
                        "
                        v-if="item.shippingSelected.code != null"
                      >
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left"
                              >Send Time (<b>{{
                                item.shippingSelected.sendTime
                              }}</b
                              >)</a
                            >
                            <span
                              class="float-center"
                              v-if="item.shippingSelected.dateSendTime != null"
                            >
                              <b
                                ><span class="float-center">{{
                                  item.shippingSelected.dateSendTime
                                }}</span>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left"
                              >Pengiriman (<b>{{
                                item.shippingSelected.name
                              }}</b
                              >)</a
                            >
                            <span class="float-center">
                              <b
                                ><span class="float-center">{{
                                  item.shippingSelected.description
                                }}</span>
                              </b>
                            </span>
                            <span class="float-right">
                              <b>{{
                                item.shippingSelected.priceCurrencyFormat
                              }}</b>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="ringkasanBelanja">
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja">
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left fsx text-white text-bold"
                        >Ringkasan Belanja</span
                      >
                      <!-- {{dataOrder}} -->
                    </div>
                    <div class="card-body">
                      <ul class="list-group">
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              Subtotal (
                              <span class="text-primary-jaja text-bold"
                                >{{ dataOrder.totalAllProduct }} Produk</span
                              >
                              )
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              {{ dataOrder.subTotalCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">Ongkos</div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              {{ dataOrder.shippingCostCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">Biaya Penanganan</div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              0
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.voucherDiscountJaja > 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              {{ dataOrder.voucherDiscountJajaDesc }}
                            </div>
                            <div
                              class="bd-highlight text-danger-jaja text-bold"
                            >
                              {{ dataOrder.voucherDiscountJajaCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.voucherDiscountJaja == 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">Potongan Jaja.id</div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              0
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.coinCurrencyFormat != 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">Koin Jaja.id</div>
                            <div
                              class="bd-highlight text-danger-jaja text-bold"
                            >
                              {{ dataOrder.coinCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 p-0 mb-3"
                          v-if="dataOrder.coinCurrencyFormat == 0"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">Koin Jaja.id</div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              {{ dataOrder.coinCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 pl-0 pr-0"
                          style="border-top: 1px solid grey !important"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">TOTAL</div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              {{ dataOrder.totalCurrencyFormat }}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="card-footer bg-white border-0 br-card-footer-jaja"
                    >
                      <div>
                        <div
                          :class="dataTokenMidtrans ? `d-none` : ``"
                          v-if="statusOrder === 'notPaid'"
                        >
                          <!-- <div class="card card-metodePembayaran bg-primary text-center p-2 mb-2 mt-2" @click="showPaymentMethod('first')">
                                                        <span id="metodePembayaran" class="mb-0 fsx text-center text-white font-weight-bold">Pilih Metode Pembayaran</span>
                                                    </div> -->
                          <button
                            type="button"
                            @click="showPaymentMethod('first')"
                            class="
                              btn btn-md btn-primary btn-block
                              bg-primary-jaja
                              br-color-primary-jaja
                              text-white
                              font-weight-bold
                              br-button-jaja
                            "
                          >
                            Pilih Metode Pembayaran
                          </button>
                        </div>
                        <div
                          :class="dataTokenMidtrans ? `row` : `d-none row`"
                          v-if="statusOrder === 'notPaid'"
                        >
                          <div class="col-md-6 pr-2">
                            <button
                              type="button"
                              @click="buyWithMidtrans()"
                              class="
                                btn btn-md btn-primary btn-block
                                bg-primary-jaja
                                br-color-primary-jaja
                                text-white
                                font-weight-bold
                                br-button-jaja
                              "
                            >
                              Bayar Sekarang
                            </button>
                          </div>
                          <div class="col-md-6 pl-0">
                            <button
                              type="button"
                              @click="showPaymentMethod('second')"
                              class="btn btn-secondary font-weight-bold"
                              data-dismiss="modal"
                              style="background: #fdb738; border-color: #fdb738"
                            >
                              Ganti Pembayaran
                            </button>
                          </div>
                        </div>
                        <div v-if="statusOrder === 'notPaid'">
                          <button
                            type="button"
                            class="
                              p-2
                              mb-2
                              mt-2
                              btn btn-md btn-primary btn-block
                              bg-danger-jaja
                              br-color-danger-jaja
                              text-white
                              font-weight-bold
                              br-button-jaja
                            "
                            @click="cancelOrderNotPaid()"
                          >
                            Batalkan Pesanan
                          </button>
                        </div>
                        <div v-if="statusOrder === 'waitConfirm'">
                          <button
                            type="button"
                            class="
                              p-2
                              mb-2
                              mt-2
                              btn btn-md btn-primary btn-block
                              bg-danger-jaja
                              br-color-danger-jaja
                              text-white
                              font-weight-bold
                              br-button-jaja
                            "
                            @click="cancelOrderWaitConfirm()"
                          >
                            Batalkan Pesanan
                          </button>
                        </div>

                        <div
                          v-if="
                            statusOrder === 'sent' && dataOrder.complain != true
                          "
                        >
                          <button
                            type="button"
                            class="
                              p-2
                              mb-2
                              mt-2
                              btn btn-md btn-primary btn-block
                              text-white
                              bg-success-jaja
                              br-color-success-jaja
                              font-weight-bold
                              br-button-jaja
                            "
                            @click="terimaPesananConfirm()"
                          >
                            Terima Pesanan
                          </button>
                        </div>
                        <div
                          v-if="
                            statusOrder === 'sent' && dataOrder.complain != true
                          "
                        >
                          <button
                            type="button"
                            class="
                              p-2
                              mb-2
                              mt-2
                              btn btn-md btn-primary btn-block
                              bg-danger-jaja
                              br-color-danger-jaja
                              text-white
                              font-weight-bold
                              br-button-jaja
                            "
                            @click="komplainPesananModal()"
                          >
                            Komplain Pesanan
                          </button>
                        </div>
                        <div
                          v-if="
                            statusOrder === 'sent' && dataOrder.complain == true
                          "
                        >
                          <router-link
                            :to="'/order/detail-komplain/' + jjdOrInv"
                            class="
                              p-2
                              mb-2
                              mt-2
                              btn btn-md btn-primary btn-block
                              bg-danger-jaja
                              br-color-danger-jaja
                              text-white
                              font-weight-bold
                              br-button-jaja
                            "
                          >
                            Menunggu Solusi Komplain
                          </router-link>
                        </div>
                        <!-- <div v-for="(item, index) in dataItemProduk" :key="index">
                                                <div v-if="statusOrder === 'done' && item.isRating == false">
                                                    <button type="button" class="p-2 mb-2 mt-2 btn btn-md btn-primary btn-block bg-warning text-white font-weight-bold br-button-jaja" data-dismiss="modal" style="background: #fdb738; border-color: #fdb738" @click="beriRatingmModal()" >Beri Rating 
                                                    </button>
                                                </div>
                                                </div> -->
                        <div v-if="statusOrder === 'canceled'" class="mb-3">
                          <button
                            type="button"
                            class="
                              p-2
                              mb-2
                              mt-2
                              btn btn-md btn-primary btn-block
                              bg-danger-jaja
                              br-color-danger-jaja
                              text-white
                              font-weight-bold
                              br-button-jaja
                            "
                          >
                            Pesanan Telah Dibatalkan
                          </button>
                          <div
                            class="d-flex justify-content-between bd-highlight"
                            v-if="dataOrder.cancelBy"
                          >
                            <div class="bd-highlight">Dibatalkan Oleh</div>
                            <div
                              class="bd-highlight text-danger-jaja text-bold"
                            >
                              {{ dataOrder.cancelBy }}
                            </div>
                          </div>
                          <div
                            class="d-flex justify-content-between bd-highlight"
                            v-if="dataOrder.cancelReason"
                          >
                            <div class="bd-highlight">Alasan Dibatalkan</div>
                            <div
                              class="bd-highlight text-danger-jaja text-bold"
                            >
                              {{ dataOrder.cancelReason }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="metodePembayaran"> -->
            </div>
          </div>
        </div>

        <div class="col-md-9" v-if="!isFinish">
          <div class="card-product shadow custom mb-3 p-3">
            <div class="d-flex justify-content-between bd-highlight">
              <div class="p-2 bd-highlight">
                <span class="font-weight-bold h5">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      :centered="true"
                      style="width: 120px"
                    />
                  </content-placeholders>
                </span>
              </div>
              <div class="p-2 bd-highlight"></div>
              <div
                class="
                  p-2
                  bd-highlight
                  statusorder
                  text-center text-white
                  rounded
                  font-weight-bold
                "
              >
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    :centered="true"
                    style="width: 100px"
                  />
                </content-placeholders>
              </div>
            </div>

            <div class="d-flex justify-content-between bd-highlight">
              <div class="p-2 bd-highlight mt-2 mb-2 text-left">
                <span class="font-weight-bold h5">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      :centered="true"
                      style="width: 100px"
                    />
                  </content-placeholders>
                </span>
              </div>
              <div class="center-middle-custom p-2">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="1"
                    :centered="true"
                    style="width: 120px"
                  />
                </content-placeholders>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-7">
              <div class="alamat">
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja mb-3">
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left fsx text-white text-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            :centered="true"
                            style="width: 120px"
                          />
                        </content-placeholders>
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-5 text-left">
                          <span>
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                :centered="true"
                                style="width: 120px"
                              />
                            </content-placeholders>
                          </span>
                        </div>
                        <div class="col-md-7 text-left">
                          <span class="">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="3"
                                :centered="true"
                                style="width: 150px"
                              />
                            </content-placeholders>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5 text-left">
                          <span
                            ><b>
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </b>
                          </span>
                        </div>
                        <div class="col-md-7 text-left">
                          <span class="">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text
                                :lines="1"
                                :centered="true"
                                style="width: 120px"
                              />
                            </content-placeholders>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="card br-card-jaja">
                      <div class="card-header bg-secondary-jaja">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            :centered="true"
                            style="width: 120px"
                          />
                        </content-placeholders>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="card-body">
                            <div class="row pb-2">
                              <div class="col-md-2">
                                <div class="media">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-img
                                      style="width: 45px; height: 40px"
                                    />
                                  </content-placeholders>
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="row">
                                  <div class="col-md-5">
                                    <span
                                      ><a
                                        class="
                                          d-flex
                                          text-dark text-bold text-left
                                        "
                                      >
                                        <content-placeholders :rounded="true">
                                          <content-placeholders-text
                                            :lines="1"
                                            style="width: 100px"
                                          />
                                        </content-placeholders> </a
                                    ></span>
                                    <div class="row">
                                      <div class="col-md-12">
                                        <span class="float-left text-dark">
                                          <content-placeholders :rounded="true">
                                            <content-placeholders-text
                                              :lines="1"
                                              style="width: 80px"
                                            />
                                          </content-placeholders>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="
                                      col-md-4
                                      text-primary-jaja text-bold
                                      align-self-end
                                    "
                                  >
                                    <span class="text-primary-jaja"
                                      ><b>
                                        <content-placeholders :rounded="true">
                                          <content-placeholders-text
                                            :lines="1"
                                            style="width: 100px"
                                          />
                                        </content-placeholders> </b
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr class="m-0" />

                      <div
                        class="
                          card-footer
                          text-muted
                          bg-white
                          br-card-footer-jaja
                        "
                      >
                        <div class="row">
                          <div class="col-md-12 mb-2 mt-2">
                            <div class="row">
                              <div class="col-md-6 col-6">
                                <content-placeholders :rounded="true">
                                  <content-placeholders-img
                                    style="width: 200px; height: 30px"
                                  />
                                </content-placeholders>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b
                                ><span class="float-right text-danger-jaja">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text
                                      :lines="1"
                                      style="width: 100px"
                                    />
                                  </content-placeholders>
                                </span>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b
                                ><span class="float-right text-danger-jaja">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text
                                      :lines="1"
                                      style="width: 100px"
                                    />
                                  </content-placeholders>
                                </span>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b
                                ><span class="float-right text-danger-jaja">
                                  <content-placeholders :rounded="true">
                                    <content-placeholders-text
                                      :lines="1"
                                      style="width: 100px"
                                    />
                                  </content-placeholders>
                                </span>
                              </b>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          card-footer
                          text-muted
                          bg-white
                          br-card-footer-jaja
                        "
                      >
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b>
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 120px"
                                  />
                                </content-placeholders>
                              </b>
                            </span>
                          </div>
                        </div>
                        <div class="row desktop-only mb-2 mt-2">
                          <div class="col-md-12">
                            <a class="text-dark cslight float-left">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </a>
                            <span class="float-right">
                              <b>
                                <content-placeholders :rounded="true">
                                  <content-placeholders-text
                                    :lines="1"
                                    style="width: 100px"
                                  />
                                </content-placeholders>
                              </b>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5" v-if="!isFinish">
              <div class="ringkasanBelanja">
                <div class="infoalamat">
                  <div class="card shadow-custom br-card-jaja">
                    <div class="card-header br-card-header-jaja">
                      <span class="mb-0 float-left fsx text-white text-bold">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="1"
                            :centered="true"
                            style="width: 120px"
                          />
                        </content-placeholders>
                      </span>
                      <!-- {{dataOrder}} -->
                    </div>
                    <div class="card-body">
                      <ul class="list-group">
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li class="list-group-item border-0 p-0 mb-3">
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 70px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                        <li
                          class="list-group-item border-0 pl-0 pr-0"
                          style="border-top: 1px solid grey !important"
                        >
                          <div
                            class="d-flex justify-content-between bd-highlight"
                          >
                            <div class="bd-highlight">
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 120px"
                                />
                              </content-placeholders>
                            </div>
                            <div
                              class="bd-highlight text-primary-jaja text-bold"
                            >
                              <content-placeholders :rounded="true">
                                <content-placeholders-text
                                  :lines="1"
                                  :centered="true"
                                  style="width: 100px"
                                />
                              </content-placeholders>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="
                        card-footer
                        bg-white
                        border-0
                        br-card-footer-jaja
                        mb-2
                      "
                    >
                      <div>
                        <content-placeholders :rounded="true" :centered="true">
                          <content-placeholders-img
                            style="width: 200px; height: 40px"
                          />
                        </content-placeholders>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="metodePembayaran"> -->
            </div>
          </div>
        </div>

        <!-- END CONTENT -->
      </div>
    </div>

    <div class="modal" id="myModalLoading">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body" id="list-pengiriman">
            <div v-if="isLoading" class="text-center">
              <img src="https://jaja.id/asset/home/logo/Loading_2.gif" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- pilih metode pembayaran -->
    <div
      id="myModalCommon"
      class="modal fade"
      role="dialog"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">
              Pilih Metode Pembayaran
            </h5>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              :disabled="isLoading"
            >
              &times;
            </button>
          </div>
          <div class="modal-body">
            <div v-if="isLoading" class="text-center">
              <img src="https://jaja.id/asset/home/logo/Loading_2.gif" alt="" />
            </div>
            <div v-else>
              <ul
                class="nav nav-tabs nav-justified mb-2"
                id="myTab"
                role="tablist"
              >
                <li
                  v-for="(item, index) in dataCommon"
                  :key="index"
                  class="nav-item"
                >
                  <a
                    :class="[
                      index === 0
                        ? 'nav-link tab-payment-active active link_0 py-2 px-0'
                        : 'nav-link tab-payment-active  link_0 py-2 px-0',
                    ]"
                    id="0-tab"
                    @click="setActiveLabel(index)"
                    data-toggle="tab"
                    href="#kolom0"
                    role="tab"
                    aria-controls=""
                    aria-selected="true"
                  >
                    {{ item.payment_type_label }}
                  </a>
                </li>
              </ul>
              <div
                v-for="(item, index) in dataCommonPerIndex"
                :key="index"
                class="mb-2"
              >
                <div
                  class="p-2"
                  style="
                    border-radius: #64b0c9 !important;
                    border: 2px solid #64b0c9;
                    border-radius: 10px;
                  "
                >
                  <div class="row">
                    <div
                      class="col-md-2"
                      style="
                        width: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: right;
                      "
                    >
                      <input
                        :id="`radio${index}`"
                        type="radio"
                        name="payment_type"
                        value=""
                        :checked="index == 0"
                      />
                    </div>
                    <div
                      class="col-md-4"
                      style="
                        width: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: left;
                      "
                    >
                      <img
                        class="iconPayment"
                        :src="`https://jaja.id/asset/payment/transfer/${item.payment_sub_label}.png`"
                      />
                    </div>
                    <div class="col-md-6 text-left">
                      <label
                        for="0"
                        class="w-100 rounded p-2 mb-0 pointer font-weight-bold"
                        >{{ item.payment_sub_label }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-secondary font-weight-bold"
              data-dismiss="modal"
              style="background: #fdb738; border-color: #fdb738"
            >
              Close
            </button>
            <button
              :disabled="isLoading"
              @click="getParamsMidtrans()"
              type="button"
              class="btn btn-primary font-weight-bold"
              style="background: #64b0c9; border-color: #64b0c9"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="myModalRating">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">Beri Rating</h5>
            <button
              :disabled="isLoading"
              type="button"
              class="close"
              data-dismiss="modal"
            >
              &times;
            </button>
          </div>
          <div class="modal-body" id="list-pengiriman">
            <div v-if="isLoading" class="text-center">
              <img src="https://jaja.id/asset/home/logo/Loading_2.gif" alt="" />
            </div>
            <form enctype="multipart/form-data" v-else>
              <div class="card-body">
                <div class="">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="media">
                        <img
                          :src="itemProduct.image"
                          class="img-thumbnail img-responsive"
                          width="45px"
                          height="40px"
                        />
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-12">
                          <router-link
                            :to="'/home/DetailProduk/' + itemProduct.slug"
                          >
                            <span
                              ><a
                                class="d-flex text-dark text-bold text-left"
                                >{{ itemProduct.name }}</a
                              ></span
                            >
                          </router-link>
                          <div class="row">
                            <div class="col-md-12">
                              <span class="float-left text-dark">
                                <input
                                  type="hidden"
                                  :value="itemProduct.productId"
                                  id="productId"
                                />
                                <b-form-rating
                                  v-model="rating"
                                  variant="warning"
                                  style="
                                    border: 0px !important;
                                    padding-left: 0px !important;
                                    width: 100px;
                                  "
                                >
                                </b-form-rating>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <span class="d-flex text-dark text-bold text-left mb-1"
                        >Review</span
                      >
                      <ul
                        class="list-group mb-2"
                        style="
                          border-radius: 10px;
                          border: 2px solid rgb(100, 176, 201);
                        "
                      >
                        <li
                          class="list-group-item"
                          style="border: 0px !important"
                        >
                          <div class="form-group">
                            <b-form-group
                              v-slot="{ ariaDescribedby }"
                              style="text-align: left !important"
                            >
                              <b-form-checkbox-group
                                @change="selectCommentCustom"
                                v-model="selected"
                                :options="options"
                                :aria-describedby="ariaDescribedby"
                                button-variant="py-1 btn-outline-warning-custom-checkbox rounded ml-1"
                                size="sm"
                                name="radio-btn-outline"
                                buttons
                              >
                              </b-form-checkbox-group>
                            </b-form-group>
                            <textarea
                              class="form-control"
                              rows="3"
                              v-model="review"
                              style="margin-bottom: 0rem !important"
                            >
                            </textarea>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <span class="d-flex text-dark text-bold text-left mb-1"
                        >Upload Photo</span
                      >
                      <ul
                        class="list-group mb-2"
                        style="
                          border-radius: 10px;
                          border: 2px solid rgb(100, 176, 201);
                        "
                      >
                        <li
                          class="list-group-item"
                          style="border: 0px !important"
                        >
                          <div class="form-group">
                            <b-form-group>
                              <b-form-file
                                @change="onFileImageChange"
                                id="file-image"
                                style="margin-bottom: 0rem !important"
                              ></b-form-file>
                              <div id="preview">
                                <img
                                  class="mt-3"
                                  v-if="urlImage"
                                  :src="urlImage"
                                />
                              </div>
                            </b-form-group>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <span class="d-flex text-dark text-bold text-left mb-1"
                        >Upload Video</span
                      >
                      <ul
                        class="list-group mb-2"
                        style="
                          border-radius: 10px;
                          border: 2px solid rgb(100, 176, 201);
                        "
                      >
                        <li
                          class="list-group-item"
                          style="border: 0px !important"
                        >
                          <div
                            class="form-group"
                            style="margin-bottom: 0rem !important"
                          >
                            <b-form-group>
                              <b-form-file
                                accept="video/*"
                                @change="onFileVideoChange"
                                id="file-video"
                                style="margin-bottom: 0rem !important"
                              >
                              </b-form-file>
                              <div id="preview">
                                <video
                                  class="mt-3"
                                  :src="urlVideo"
                                  width="320"
                                  height="240"
                                  controls
                                  v-show="urlVideo"
                                ></video>
                              </div>
                            </b-form-group>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-secondary font-weight-bold"
              data-dismiss="modal"
              style="background: #fdb738; border-color: #fdb738"
            >
              Close
            </button>
            <button
              :disabled="isLoading"
              type="button"
              @click="runBeriRating"
              class="btn btn-primary font-weight-bold"
              style="background: #64b0c9; border-color: #64b0c9"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="myModalComplain" class="modal fade" role="dialog">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">Pilih Poduct Komplain</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="modal-body body-complain">
            <div class="card">
              <div class="card-body">
                <table
                  class="table"
                  v-for="(item, index) in dataItemProduk"
                  :key="index"
                >
                  <thead>
                    <tr>
                      <th scope="col">Nomer</th>
                      <th scope="col" class="text-left">Gambar</th>
                      <th scope="col" class="text-left">Product</th>
                      <th scope="col">Variant</th>
                      <th scope="col">Pilih</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(a, b) in item.products" :key="b">
                      <td>{{ b + 1 }}</td>
                      <td class="text-left">
                        <img
                          class="img-fluid"
                          style="width: 100px; height: auto"
                          :src="a.image"
                        />
                      </td>
                      <td class="text-left">{{ a.name }}</td>
                      <td v-if="a.variant">{{ a.variant }}</td>
                      <td v-if="!a.variant">-</td>
                      <td>
                        <div class="custom-control custom-checkbox d-inline">
                          <input
                            @click="pilihProductComplain(a.productId, b)"
                            type="checkbox"
                            :id="'pilihProduct' + b"
                            class="custom-control-input"
                            :value="a.productId"
                          />
                          <label
                            class="custom-control-label"
                            :for="'pilihProduct' + b"
                          ></label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-secondary font-weight-bold"
              data-dismiss="modal"
              style="background: #fdb738; border-color: #fdb738"
            >
              Close
            </button>
            <button
              :disabled="isLoading"
              type="button"
              @click="komplainPesananLanjutanModal()"
              class="btn btn-primary font-weight-bold"
              style="background: #64b0c9; border-color: #64b0c9"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="myModalLanjutComplain" class="modal fade" role="dialog">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">Komplain Poduct</h5>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div
            class="modal-body body-lanjut-complain"
            style="max-height: calc(100vh - 210px); overflow-y: auto"
          >
            <div v-if="isLoading" class="text-center">
              <img src="https://jaja.id/asset/home/logo/Loading_2.gif" alt="" />
            </div>
            <div v-else class="row text-left">
              <div class="col-md-5">
                <div class="mb-2 mt-2">
                  <h5 class="font-weight-bold">
                    Pilih Salah Satu Komplain atau Lainnya
                  </h5>
                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="form-check">
                        <label
                          class="
                            form-check-label
                            text-primary-jaja
                            font-weight-bold
                          "
                        >
                          <input
                            @change="pilihInputComplain('tidakSesuai')"
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                          />Barang Tidak Sesuai
                        </label>
                      </div>
                      <div id="tidakSesuai" class="mt-3 ml-3 d-none">
                        <div>
                          <div class="md-form">
                            <label for="form10" class="font-weight-bold"
                              >1. Pilih Judul Komplain</label
                            >
                          </div>
                          <ul
                            class="list-group list-complain-pilih ml-3"
                            style="
                              height: 150px;
                              overflow: auto;
                              border: 1px solid rgba(0, 0, 0, 0.125);
                            "
                          >
                            <li
                              class="list-group-item pl-4"
                              v-for="(item, index) in listJudulComplain"
                              :key="index"
                            >
                              <input
                                @change="getJudulComplain(item)"
                                id="ceklis_judul"
                                class="form-check-input me-1"
                                type="radio"
                                name="pilih_judul"
                                aria-label="..."
                                :value="item"
                              />
                              {{ item }}
                            </li>
                          </ul>
                        </div>
                        <div class="mt-3">
                          <div class="md-form">
                            <label for="form10" class="font-weight-bold"
                              >2. Masukan Alasan Komplain</label
                            >
                            <textarea
                              id="form10"
                              class="md-textarea form-control ml-3"
                              rows="3"
                              cols="50"
                              placeholder="Alasan Komplain"
                              v-model="arrReqComplain.komplain"
                              style="width: 95%"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="form-check">
                        <label
                          class="
                            form-check-label
                            text-primary-jaja
                            font-weight-bold
                          "
                        >
                          <input
                            @change="pilihInputComplain('lainnya')"
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                          />Lainnya
                        </label>
                      </div>
                      <div id="lainnya" class="mt-3 d-none ml-3">
                        <div class="md-form">
                          <label for="form10" class="font-weight-bold"
                            >1. Masukan Judul Komplain</label
                          >
                          <input
                            type="text"
                            class="form-control ml-3"
                            placeholder="Judul Komplain"
                            aria-label="Username"
                            aria-describedby="material-addon1"
                            v-model="arrReqComplain.judul_komplain"
                            style="width: 95%"
                          />
                        </div>
                        <div class="md-form mt-3">
                          <label for="form10" class="font-weight-bold"
                            >2. Masukan Alasan Komplain</label
                          >
                          <textarea
                            id="form10"
                            class="md-textarea form-control ml-3"
                            rows="3"
                            placeholder="Alasan Komplain"
                            v-model="arrReqComplain.komplain"
                            style="width: 95%"
                          ></textarea>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-7">
                <div class="mb-2 mt-2">
                  <h5 class="font-weight-bold">Lengkapi Bukti Complain :</h5>
                  <div class="mt-4">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Unggah Foto</label>
                      <b-form-group>
                        <b-form-file
                          accept="image/*"
                          @change="onFileImageChangeComplain"
                          id="file-image-compain"
                          style="margin-bottom: 0rem !important"
                        >
                        </b-form-file>
                        <div id="preview">
                          <img
                            class="mt-3"
                            v-if="urlImageComplain"
                            :src="urlImageComplain"
                          />
                        </div>
                      </b-form-group>
                      <!-- <input id="fotoComplain" @change="uploadFotoComplain(event)" type="file" class="form-control" aria-describedby="emailHelp">
                                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                    </div>
                    <div class="form-group">
                      <label for="exampleInputEmail1">Unggah Video</label>
                      <b-form-group>
                        <b-form-file
                          accept="video/*"
                          @change="onFileVideoChangeComplain"
                          id="file-video-compain"
                          style="margin-bottom: 0rem !important"
                        >
                        </b-form-file>
                        <div id="preview">
                          <video
                            class="mt-3"
                            :src="urlVideoComplain"
                            width="320"
                            height="240"
                            controls
                            v-show="urlVideoComplain"
                          ></video>
                        </div>
                      </b-form-group>
                      <!-- <input type="file" id="videoComplain" @change="uploadVideoComplain(event)"  class="form-control" aria-describedby="emailHelp">
                                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              :disabled="isLoading"
              type="button"
              class="btn btn-secondary font-weight-bold"
              data-dismiss="modal"
              style="background: #fdb738; border-color: #fdb738"
            >
              Close
            </button>
            <button
              :disabled="isLoading"
              type="button"
              @click="runningComplain()"
              class="btn btn-primary font-weight-bold"
              style="background: #64b0c9; border-color: #64b0c9"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<!-- <script type="text/javascript" src="https://app.midtrans.com/snap/snap.js" data-client-key="Mid-client-S9f6KNmuVrQre0GQ"></script>
<script src="https://app.sandbox.midtrans.com/snap/snap.js" data-client-key="Mid-client-S9f6KNmuVrQre0GQ"></script> -->
<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import firebase from "firebase/app";

export default {
  data() {
    return {
      selected: [], // Must be an array reference!
      options: [
        { text: "Barang Sesuai Pesanan", value: "Barang Sesuai Pesanan" },
        { text: "Respon Penjual Cepat", value: "Respon Penjual Cepat" },
        { text: "Pengiriman Cepat", value: "Pengiriman Cepat" },
      ],
      review: "",
      urlImage: null,
      urlVideo: null,
      statusOrder: {},
      statusInfo: {},
      goToMidtransPage: {},
      dataTokenMidtrans: {},
      dataOrder: {},
      jjdOrInv: {},
      dataAddress: {},
      dataItemProduk: {},
      dataCommon: {},
      dataCommonPerIndex: {},
      totalOrder: 0,
      paramSubmitPayment: {
        payment_type: "",
        payment_type_label: "",
        payment_sub: "",
        payment_sub_label: "",
        payment_fee: "",
        payment_form: "",
      },
      dataParamsMidtrans: {},
      dataUser: {},
      dataTracking: [],
      dataParamsGetSnapToken: {
        transaction_details: {
          gross_amount: "",
          order_id: "",
        },
        customer_details: {
          email: "",
          first_name: "",
          last_name: "",
          phone: null,
        },
        enabled_payments: [],
        credit_card: "",
      },
      dataToken: {},
      paramsSnapTokenUpdate: {
        total_pembayaran: "",
        fee: "",
        id_invoice: "",
        dataPayment: {
          payment_type: "",
          payment_type_label: "",
          payment_sub: "",
          payment_sub_label: "",
          payment_fee: "",
          payment_form: "",
        },
        token: "",
        order_code: "",
        order_id: "",
        va_or_code_or_link: "",
      },
      dataUpdateSnapToken: {},
      firstMethodOrNot: 0,
      rating: 5,
      isLoading: false,
      productId: [],
      arrReqComplain: {
        invoice: "",
        jenis_komplain: "",
        judul_komplain: "",
        komplain: "",
        video: "",
        images: [],
        productsComplain: [],
      },
      listJudulComplain: [
        "Barang rusak",
        "Barang tidak berfungsi",
        "Ukuran barang tidak sesuai",
        "Warna barang tidak sesuai",
        "Barang tidak sesuai deskripsi",
        "Barang tidak original",
        "Barang tidak lengkap",
        "Barang tidak dikirim",
      ],
      urlImageComplain: null,
      urlVideoComplain: null,
      dataProduct: [],
      itemProduct: {},
      resultAllPayment: {},
      isFinish: false,
      // rates: [
      //     {
      //         "productId": "",
      //         "rate": "",
      //         "comment": "",
      //         "images": [],
      //         "video": ""
      //     }
      // ]
    };
  },
  components: {
    sideBar,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  beforeMount() {
    this.getDataOrder();
  },
  mounted() {
    let midtransScript = document.createElement("script");
    midtransScript.setAttribute("src", "https://app.midtrans.com/snap/snap.js");
    midtransScript.setAttribute(
      "data-client-key",
      "Mid-client-S9f6KNmuVrQre0GQ"
    );
    document.head.appendChild(midtransScript);
  },
  methods: {
    getDataOrder() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}order/${this.$route.params.id}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          this.isFinish = true;
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.dataTracking = res.data;
            this.goToMidtransPage =
              res.data.orderPaymentRecent.payment_va_or_code_or_link;
            this.statusOrder = res.data.status;
            this.statusInfo = res.data.statusTitle;
            this.dataTokenMidtrans = res.data.orderPaymentRecent.token;
            this.dataAddress = res.data.address;
            this.dataOrder = res.data;
            this.jjdOrInv = this.$route.params.id;
            this.dataItemProduk = res.data.items;
            this.dataProduct = res.data.items[0].products.map((products) => {
              return products;
            });
            this.totalOrder = res.data.total;
            this.getDataCommon();

            // console.log('this.dataProduct', this.dataItemProduk);
          }
        })
        .catch((error) => console.log("error", error));
    },
    getDataCommon() {
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=7huq0r7m8qr8msgk411sjl5khur7d7hv");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `https://jaja.id/front/api/common/methodPayment/${this.totalOrder}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          const newData = res.filter((item, index) => {
            if (item.payment_type !== "card") {
              return item;
            }
          });

          this.dataCommon = newData;
        })
        .catch((error) => console.log("error", error));
    },
    showPaymentMethod(cek) {
      if (cek === "first") {
        this.firstMethodOrNot = 1;
      } else {
        this.firstMethodOrNot = 0;
      }
      $("#myModalCommon").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );
      this.dataCommonPerIndex = this.dataCommon[0].subPayment;
      this.paramSubmitPayment.payment_type = this.dataCommon[0].payment_type;
      this.paramSubmitPayment.payment_type_label =
        this.dataCommon[0].payment_type_label;
      this.paramSubmitPayment.payment_sub =
        this.dataCommonPerIndex[0].payment_sub;
      this.paramSubmitPayment.payment_sub_label =
        this.dataCommonPerIndex[0].payment_sub_label;
      this.paramSubmitPayment.payment_fee = this.dataCommonPerIndex[0].fee;
      this.paramSubmitPayment.payment_form =
        this.dataCommonPerIndex[0].payment_form;
    },
    setActiveLabel(index) {
      document.getElementById("radio0").checked = true;
      this.dataCommonPerIndex = this.dataCommon[index].subPayment;
      this.paramSubmitPayment.payment_type =
        this.dataCommon[index].payment_type;
      this.paramSubmitPayment.payment_type_label =
        this.dataCommon[index].payment_type_label;
      this.paramSubmitPayment.payment_sub =
        this.dataCommonPerIndex[0].payment_sub;
      this.paramSubmitPayment.payment_sub_label =
        this.dataCommonPerIndex[0].payment_sub_label;
      this.paramSubmitPayment.payment_fee = this.dataCommonPerIndex[0].fee;
      this.paramSubmitPayment.payment_form =
        this.dataCommonPerIndex[0].payment_form;
    },
    getParamsMidtrans() {
      this.isLoading = true;
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=1s6f2fevft22dk5gln0bh2hf8h5qa21v");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const url = `https://jaja.id/backend/payment/getPayment/${this.$route.params.id}`;

      fetch(url, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          this.dataParamsMidtrans = res;
          this.dataUser = this.user;
          this.setParamsSnap();
        })
        .catch((error) => console.log("error", error));
    },
    setParamsSnap() {
      this.dataParamsGetSnapToken.transaction_details.gross_amount =
        this.dataParamsMidtrans.orderPaymentRecent.grand_total;
      this.dataParamsGetSnapToken.transaction_details.order_id =
        this.dataParamsMidtrans.orderPaymentRecent.payment_id;
      this.dataParamsGetSnapToken.customer_details.email = this.dataUser.email;
      this.dataParamsGetSnapToken.customer_details.first_name =
        this.dataUser.name;
      this.dataParamsGetSnapToken.customer_details.last_name = "";
      // this.dataParamsGetSnapToken.customer_details.phone = this.dataUser.phoneNumber
      this.dataParamsGetSnapToken.customer_details.phone = null;
      this.dataParamsGetSnapToken.enabled_payments.push(
        this.paramSubmitPayment.payment_sub
      );
      this.getSnapToken();
    },
    getSnapToken() {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=69bd52hul0vqar30rmn5p65naskaco6h");

      let raw = JSON.stringify(this.dataParamsGetSnapToken);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const urlgetSnapToken = `${this.domainURL}payment/getSnapToken/${this.$route.params.id}?isFirstMethod=${this.firstMethodOrNot}`;

      fetch(urlgetSnapToken, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.error_messages?.length) {
            this.$swal.fire({
              icon: "warning",
              title: "Warning",
              text: `Sudah tidak dapat mengganti metode pembayaran`,
              showConfirmButton: true,
            });
          } else {
            this.dataToken = res;
            this.updateSnapToken();
          }
        })
        .catch((error) => console.log("error", error));
    },
    updateSnapToken() {
      this.paramsSnapTokenUpdate.dataPayment.payment_type =
        this.paramSubmitPayment.payment_type;
      this.paramsSnapTokenUpdate.dataPayment.payment_type_label =
        this.paramSubmitPayment.payment_type_label;
      this.paramsSnapTokenUpdate.dataPayment.payment_sub =
        this.paramSubmitPayment.payment_sub;
      this.paramsSnapTokenUpdate.dataPayment.payment_sub_label =
        this.paramSubmitPayment.payment_sub_label;
      this.paramsSnapTokenUpdate.dataPayment.payment_fee =
        this.paramSubmitPayment.payment_fee;
      this.paramsSnapTokenUpdate.dataPayment.payment_form =
        this.paramSubmitPayment.payment_form;
      this.paramsSnapTokenUpdate.total_pembayaran =
        this.dataParamsMidtrans.orderPaymentRecent.grand_total;
      this.paramsSnapTokenUpdate.fee =
        this.dataParamsMidtrans.orderPaymentRecent.fee;
      this.paramsSnapTokenUpdate.id_invoice = "";
      this.paramsSnapTokenUpdate.token = this.dataToken.token;
      this.paramsSnapTokenUpdate.order_code = "";
      this.paramsSnapTokenUpdate.order_id =
        this.dataParamsMidtrans.orderPaymentRecent.order_id;
      this.paramsSnapTokenUpdate.va_or_code_or_link =
        this.dataToken.redirect_url;

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=69bd52hul0vqar30rmn5p65naskaco6h");

      let raw = JSON.stringify(this.paramsSnapTokenUpdate);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}payment/snap_token_update`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          this.dataUpdateSnapToken = res;
          this.getDataOrder();
          setTimeout(() => {
            this.isLoading = false;
            $("#myModalCommon").modal("hide");
            snap.pay(res.token);
          }, 3000);
        })
        .catch((error) => console.log("error", error));
    },
    buyWithMidtrans() {
      //   window.open(this.goToMidtransPage);
      var myHeaders = new Headers();
      myHeaders.append("Cookie", "ci_session=nvha2jep6gogidt9rmcle1par0j8ul4f");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      var url =
        "https://jaja.id/backend/payment/getPayment/" + this.$route.params.id;
      // console.log('getPaymentUrl', url);
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          snap.pay(result.orderPaymentRecent.token);
          // console.log('payment_va_or_code_or_link', result.orderPaymentRecent.payment_va_or_code_or_link);
          // if (String(result.orderPaymentRecent.payment_va_or_code_or_link).includes('qr-code')) {
          //     setqrcode(result.orderPaymentRecent.payment_va_or_code_or_link)
          // }
        })
        .catch((error) => console.log("error", error));
    },
    //   setPayment(orderId){
    //     const gthis = this
    //     const tokenMidtrans = this.orderData.tokenMidtrans
    //     const invoice_no = this.orderData.invoiceNumber.split('-')[1]
    //     snap.pay(tokenMidtrans, {
    //         onSuccess: function(result){
    //             console.log('success');console.log('success',result);
    //             gthis.$axios.put('https://elibx.jaja.id/checkout/set-proccess-payment', {
    //                 invoice:String(invoice_no)
    //             }).then(() => {
    //                 gthis.$axios.post('checkout/set-payment', {
    //                     "orderId": orderId,
    //                     }).then((res)=>{
    //                     });
    //             })
    //         },
    //         onPending: function(result){console.log('pending');console.log(result);},
    //         onError: function(result){console.log('error');console.log(result);},
    //         onClose: function(){console.log('customer closed the popup without finishing the payment');}
    //     });
    // },

    gantiPembayaran() {
      var paramPayMD = {
        order_id: this.dataOrder.orderPaymentRecent.order_id,
        id_token: this.dataOrder.orderPaymentRecent.id_token,
      };

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=7glbt8ufb9pgaiv13v6fm7bbbr33k91d");

      var raw = JSON.stringify(paramPayMD);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}payment/new_invoice`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setTimeout(() => {
            this.getParamsMidtrans();
          }, 50);
        })
        .catch((error) => console.log("error", error));
    },
    cancelOrderNotPaid() {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda ingin membatalkan pesanan ini?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Ya",
          denyButtonText: `Tidak`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runCancelOrderNotPaid();
          }
        });
    },
    cancelOrderWaitConfirm() {
      this.$swal
        .fire({
          icon: "info",
          title: "Apakah anda ingin membatalkan pesanan ini?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Ya",
          denyButtonText: `Tidak`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runCancelOrderWaitConfirm();
          }
        });
    },
    runCancelOrderNotPaid() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `https://jaja.id/backend/order/batalBelumbayar?order_id=${this.$route.params.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (
            result &&
            Object.keys(result).length &&
            result.status.code == 200
          ) {
            this.$swal.fire("Sukses", "Pesanan berhasil dibatalkan", "success");
            setTimeout(() => this.getDataOrder(), 200);
          } else {
            this.$swal.fire("Gagal", "Pesanan gagal dibatalkan", "danger");
          }
        });
    },
    runCancelOrderWaitConfirm() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `https://jaja.id/backend/order/batalMenungguKonfirmasi?invoice=${this.$route.params.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (
            result &&
            Object.keys(result).length &&
            result.status.code == 200
          ) {
            this.$swal.fire("Sukses", "Pesanan berhasil dibatalkan", "success");
            setTimeout(() => this.getDataOrder(), 200);
          } else {
            this.$swal.fire("Gagal", "Pesanan gagal dibatalkan", "warning");
          }
        });
    },
    terimaPesananConfirm() {
      this.$swal
        .fire({
          title: "Apakah anda ingin menerima pesanan ini?",
          icon: "info",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Ya",
          denyButtonText: `Tidak`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.runTerimaPesanan();
          }
        });
    },
    runTerimaPesanan() {
      this.isLoading = true;
      $("#myModalLoading").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=7vgloal55kn733tsqch0v7lh1tfrcilq");

      var formdata = new FormData();
      formdata.append("invoice", this.$route.params.id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${this.domainURL}order/pesananDiterima`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.isLoading = false;
            $("#myModalLoading").modal("hide");
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                this.getDataOrder();
              });
          } else {
            this.$swal
              .fire("Warning", res.status.message, "warning")
              .then(() => {
                this.getDataOrder();
              });
          }
        })
        .catch((error) => console.log("error", error));
    },
    beriRatingmModal(id) {
      const data = this.dataProduct;
      this.itemProduct = data[id];
      $("#myModalRating").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );
    },

    selectCommentCustom() {
      this.review = this.selected;
    },
    onFileImageChange(e) {
      const file = e.target.files[0];
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.urlImage = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    onFileVideoChange(e) {
      const file = e.target.files[0];
      this.createBase64Video(file);
    },
    createBase64Video(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.urlVideo = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    runBeriRating() {
      this.isLoading = true;

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        invoice: this.$route.params.id,
        rates: [
          {
            productId: document.getElementById("productId").value,
            rate: this.rating,
            comment: this.review + " ",
            images: [this.urlImage],
            video: this.urlVideo,
          },
        ],
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      console.log(raw);

      fetch(`${this.domainURL}order/rate`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status.code === 200) {
            this.isLoading = false;
            $("#myModalRating").modal("hide");
            this.$swal
              .fire("Sukses", res.status.message, "success")
              .then(() => {
                location.reload();
              });
          } else {
            this.$swal
              .fire("Warning", res.status.message, "warning")
              .then(() => {
                this.getDataOrder();
              });
          }
        })
        .catch((error) => console.log("error", error));
    },
    showSnapPay() {
      alert("ok");
      // this.getDataOrder()
      snap.pay(this.dataUpdateSnapToken.token, {
        onSuccess: function (result) {
          console.log("success");
          console.log(result);
        },
        onPending: function (result) {
          console.log("pending");
          console.log(result);
        },
        onError: function (result) {
          console.log("error");
          console.log(result);
        },
        onClose: function () {},
      });
    },
    komplainPesananModal() {
      $("#myModalComplain").modal(
        { backdrop: "static", keyboard: false },
        "show"
      );
    },
    pilihProductComplain(e, b) {
      document.getElementById("pilihProduct" + b).checked
        ? this.arrReqComplain.productsComplain.push(
            document.getElementById("pilihProduct" + b).value
          )
        : this.arrReqComplain.productsComplain.map((item, index) => {
            if (item == document.getElementById("pilihProduct" + b).value) {
              this.arrReqComplain.productsComplain.splice(index, 1);
            }
          });
    },
    komplainPesananLanjutanModal() {
      $("#myModalComplain").modal("hide");
      setTimeout(() => {
        $("#myModalLanjutComplain").modal({
          backdrop: "static",
          keyboard: false,
        });
      }, 100);
    },
    pilihInputComplain(slug) {
      if (slug == "lainnya") {
        $(`#lainnya`).removeClass("d-none");
        $(`#tidakSesuai`).addClass("d-none");
        this.arrReqComplain.jenis_komplain = "lainnya";
      } else {
        $(`#tidakSesuai`).removeClass("d-none");
        $(`#lainnya`).addClass("d-none");
        this.arrReqComplain.jenis_komplain = "barang";
      }
    },
    lanjutComplain() {
      const arr = [
        "Barang rusak",
        "Barang tidak berfungsi",
        "Ukuran barang tidak sesuai",
        "Warna barang tidak sesuai",
        "Barang tidak sesuai deskripsi",
        "Barang tidak original",
        "Barang tidak lengkap",
        "Barang tidak dikirim",
      ];

      // arr.forEach(function(a,b) {
      // 	console.log(a,b)
      // })
      const html = arr.map((item) => {
        return `<li @click="ambilTextLi('${item.replaceAll(
          " ",
          "_"
        )}')" class="list-group-item">
                        <input class="ceklis_judul" id="${item.replaceAll(
                          " ",
                          "_"
                        )}" class="form-check-input me-1" type="radio" name="pilih_judul" aria-label="...">
                        ${item}
                    </li>`;
      });
      $(".list-complain-pilih").html(html.toString().replaceAll(",", ""));

      this.arrReqComplain.productsComplain.length
        ? komplainPesananLanjutanModal()
        : this.$swal.fire(
            "",
            "Silahkan pilih produk yang akan dikomplain",
            "warning"
          );
    },
    ambilTextLi(slug) {
      this.arrReqComplain.judul_komplain = slug.replaceAll("_", " ");
      document.getElementById(slug).checked = true;
    },
    getJudulComplain(item) {
      this.arrReqComplain.judul_komplain = item;
      // console.log(this.arrReqComplain.judul_komplain);
    },
    uploadFotoComplain(event) {
      var reader = new FileReader();
      this.arrReqComplain.imagesShow = event.target.files[0];
      reader.onload = function () {
        var output = document.getElementById("fotoComplain");
        output.src = reader.result;
        this.arrReqComplain.images.push(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      // console.log('arrReqComplain', arrReqComplain)
    },
    uploadVideoComplain(event) {
      var reader = new FileReader();
      reader.onload = function () {
        var output = document.getElementById("videoComplain");
        output.src = reader.result;
        this.arrReqComplain.video = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    cekValuComplain() {
      const arrValParams = Object.values(this.arrReqComplain);
      const arrKeyParams = Object.keys(this.arrReqComplain);
      arrKeyParams.map((item, index) => {
        if (typeof arrValParams[index] !== "object") {
          if (item !== "video") {
            arrValParams[index]
              ? ``
              : this.$swal.fire(
                  ``,
                  `Mohon isi ${item.replaceAll("_", "")}`,
                  "warning"
                );
            return;
          }
        }
      });
      cekValueObject();
    },
    cekValueObject() {
      const arrValParams = Object.values(this.arrReqComplain);
      const arrKeyParams = Object.keys(this.arrReqComplain);
      const cekIsiKosong = [];
      console.log(arrKeyParams);
      arrKeyParams.map((item, index) => {
        if (typeof arrValParams[index] !== "string") {
          if (item == "images") {
            if (arrValParams[index].length < 1) {
              this.$swal.fire("", `Mohon upload ${item}`, "warning");
              return;
            } else {
              runningComplain();
            }
          }
        }
      });
    },
    onFileImageChangeComplain(e) {
      const file = e.target.files[0];
      this.createBase64ImageComplain(file);
    },
    createBase64ImageComplain(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.urlImageComplain = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    onFileVideoChangeComplain(e) {
      const file = e.target.files[0];
      this.createBase64VideoComplain(file);
    },
    createBase64VideoComplain(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.urlVideoComplain = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    runningComplain() {
      this.isLoading = true;

      this.arrReqComplain.invoice = this.$route.params.id;
      // this.arrReqComplain.jenis_komplain = 'barang'
      // this.arrReqComplain.judul_komplain = document.getElementById("ceklis_judul").value
      this.arrReqComplain.video = this.urlVideoComplain;
      this.arrReqComplain.images = [this.urlImageComplain];

      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        complain: [this.arrReqComplain],
      });

      // console.log('raw', raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      // console.log(raw);

      fetch(`${this.domainURL}order/complain`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status.code === 200) {
            this.isLoading = false;

            $("#myModalComplain").modal("hide");
            $("#myModalLanjutComplain").modal("hide");
            this.$swal.fire("Sukses", result.status.message, "success");
            this.getDataOrder();
          } else {
            this.isLoading = false;
            this.$swal.fire("", result.status.message, "danger");
          }
        })
        .catch((error) => {});
    },

    chatStore(index) {
      const dataProfile = this.user;
      const dataDetailStore = this.dataItemProduk[0].store;
      const invoice = this.dataItemProduk[0].invoice;
      const dataDetailProduct = this.dataItemProduk[0].products[0];
      $("#chat-toko").attr("disabled", "true");
      $("#chat-toko").html('<i class="fas fa-circle-notch fa-spin"></i>');

      let getFirebaseTimestamp = firebase.database.ServerValue.TIMESTAMP;
      const database = firebase.database();
      let chatRoom = `${dataProfile.uid}${dataDetailStore.uid}`;
      database
        .ref(`friend/${dataDetailStore.uid}/${dataProfile.uid}`)
        .set({
          chat: chatRoom,
          name: dataProfile.name,
          message: {
            text: "",
            time: getFirebaseTimestamp,
          },
          amount: 0,
        })
        .then(() => {
          database
            .ref(`friend/${dataProfile.uid}/${dataDetailStore.uid}`)
            .set({
              chat: chatRoom,
              name: dataDetailStore.name,
              message: {
                text: "",
                time: getFirebaseTimestamp,
              },
              amount: 0,
            })
            .then(() => {
              const paramObjChat = {
                name: dataDetailStore.name,
                chat: chatRoom,
                id: dataDetailStore.uid,
                order: {
                  imageOrder: dataDetailProduct.image,
                  invoice: invoice,
                  status: this.statusInfo,
                },
              };
              localStorage.setItem("paramToChat", JSON.stringify(paramObjChat));
              this.$router.push("/chat/");
            });
        });

      // console.log('ini', getFirebaseTimestamp)
      // console.log('index', index)
    },
  },
};
</script>


<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

.iconPayment {
  width: 50%;
  margin-right: 5%;
}

.card {
  border: 0px !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background-color: #65b0c9;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: bold;
  border-radius: 20px 20px 0 0;
  border: 2px solid #65b0c9;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #65b0c9;
  font-weight: bold;
  border-radius: 20px 20px 0 0;
  border: 2px solid #65b0c9;
}

.nav-link:hover {
  color: #fff;
  background-color: #65b0c9;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: bold;
  border-radius: 20px 20px 0 0;
  border: 2px solid #65b0c9;
}
</style>
<style lang="scss" scoped>
.close {
  font-size: 2rem;
  color: #fff;
  text-shadow: 0 1px 0 #fff;
}

.modal-header {
  background: #64b0c9 !important;
  color: white;
  font-weight: bold;
  border-radius: 1rem 1rem 0 0;
}

.modal-content {
  border-radius: 1rem;
}
</style>
<template>
  <div class="body-home">
    <section class="body-home">
      <div class="container box-header-slider">
        <div v-if="homeGetters.banner">
          <div v-if="homeGetters.banner">
            <carousel-3d
              :perspective="0"
              :space="400"
              :display="3"
              :autoplay="true"
              :autoplay-timeout="5000"
            >
              <slide
                v-for="(item, i) in homeGetters.banner"
                v-bind:key="item.id"
                :index="i"
                v-bind:style="{ backgroundImage: 'url(' + item.image + ')' }"
                style="width: 819px; border: 1px solid grey"
              >
              </slide>
            </carousel-3d>
          </div>
          <content-placeholders :rounded="true" v-else>
            <br />
            <content-placeholders-img />
            <br />
          </content-placeholders>
        </div>

        <div>
          <div class="d-flex justify-content-between bd-highlight mb-3">
            <div class="p-2 bd-highlight text-white font-weight-bold">
              <div class="body-keunggulan">
                <span
                  class="
                    bungkus-icon
                    bg-white
                    rounded-circle
                    p-2
                    border border-warning
                  "
                >
                  <img
                    src="https://nimda.jaja.id/asset/icon-jaja/fast-delivery.png"
                    width="24px"
                    alt=""
                    style="
                      -ms-transform: scalex(1.3) scaleY(1.3);
                      transform: scalex(1.3) scaleY(1.3);
                    "
                /></span>
                <span class="warnabirujaja bungkus-text birujaja-keunggulan"
                  ><small class="font-weight-bold" style="font-size: 106%"
                    >Pengiriman Cepat</small
                  >
                </span>
              </div>
            </div>
            <div class="p-2 bd-highlight text-white font-weight-bold">
              <div class="body-keunggulan">
                <span
                  class="
                    bungkus-icon
                    bg-white
                    rounded-circle
                    p-2
                    border border-warning
                  "
                  ><img
                    src="https://nimda.jaja.id/asset/icon-jaja/free-delivery.png"
                    width="24px"
                    alt=""
                    style="
                      -ms-transform: scalex(1.3) scaleY(1.3);
                      transform: scalex(1.3) scaleY(1.3);
                    "
                /></span>
                <span class="warnabirujaja bungkus-text birujaja-keunggulan">
                  <small class="font-weight-bold" style="font-size: 106%"
                    >Gratis Ongkir</small
                  >
                </span>
              </div>
            </div>
            <div class="p-2 bd-highlight text-white font-weight-bold">
              <div class="body-keunggulan">
                <span
                  class="
                    bungkus-icon
                    bg-white
                    rounded-circle
                    p-2
                    border border-warning
                  "
                  ><img
                    src="https://nimda.jaja.id/asset/icon-jaja/verified.png"
                    width="24px"
                    alt=""
                    style="
                      -ms-transform: scalex(1.3) scaleY(1.3);
                      transform: scalex(1.3) scaleY(1.3);
                    "
                /></span>
                <span class="warnabirujaja bungkus-text birujaja-keunggulan"
                  ><small class="font-weight-bold" style="font-size: 106%"
                    >Pembayaran Aman</small
                  >
                </span>
              </div>
            </div>
            <div class="p-2 bd-highlight text-white font-weight-bold">
              <div class="body-keunggulan">
                <span
                  class="
                    bungkus-icon
                    bg-white
                    rounded-circle
                    p-2
                    border border-warning
                  "
                  ><img
                    src="https://nimda.jaja.id/asset/icon-jaja/24-hours-support.png"
                    width="24px"
                    alt=""
                    style="
                      -ms-transform: scalex(1.3) scaleY(1.3);
                      transform: scalex(1.3) scaleY(1.3);
                    "
                /></span>
                <span class="warnabirujaja bungkus-text birujaja-keunggulan"
                  ><small class="font-weight-bold" style="font-size: 106%"
                    >Pelayanan 24 Jam</small
                  ></span
                >
              </div>
            </div>
            <div class="p-2 bd-highlight text-white font-weight-bold">
              <div class="body-keunggulan">
                <span
                  class="
                    bungkus-icon
                    bg-white
                    rounded-circle
                    p-2
                    border border-warning
                  "
                  ><img
                    src="https://nimda.jaja.id/asset/icon-jaja/100-percent.png"
                    width="24px"
                    alt=""
                    style="
                      -ms-transform: scalex(1.3) scaleY(1.3);
                      transform: scalex(1.3) scaleY(1.3);
                    "
                /></span>
                <span class="warnabirujaja bungkus-text birujaja-keunggulan"
                  ><small class="font-weight-bold" style="font-size: 106%"
                    >100% Original</small
                  >
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="homeGetters.categoryChoice">
          <flickity
            v-if="Object.keys(homeGetters.categoryChoice).length > 0"
            ref="flickity"
            class="flickity"
            :options="flickityOptions"
          >
            <div
              v-for="(item, index) in homeGetters.categoryChoice"
              :key="index"
              class="carousel-cell"
            >
              <router-link :to="'/kategori/' + item.slug">
                <div
                  class="
                    card
                    text-center
                    card-category-testing
                    shadow-custom-sm
                    mb-4
                    mt-4
                  "
                >
                  <div class="card-body card-body-category bg-white">
                    <img
                      alt=""
                      width="40"
                      :src="item.icon"
                      class="lazy"
                      style="margin-top: -9px"
                    />
                    <br />
                    <p class="mb-0">
                      <b>{{ item.name }}</b>
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
          </flickity>
          <flickity
            v-else
            ref="flickity"
            class="flickity"
            :options="flickityOptions"
          >
            <div v-for="index in 4" :key="index" class="carousel-cell" style="">
              <div class="" style="width: 260px; margin-right: 20px">
                <content-placeholders :rounded="true">
                  <content-placeholders-img width="40" class="lazy" style="" />
                </content-placeholders>
              </div>
            </div>
          </flickity>
        </div>

        <div>
          <div class="p-4">
            <router-link :to="'/gift/'">
              <img
                class="banner-gift tobepointer"
                src="https://nimda.jaja.id/asset/gift/banner-gift-01.jpg"
                alt=""
              />
            </router-link>
          </div>
        </div>

        <div v-if="homeGetters.trending">
          <h5
            class="d-flex font-weight-bold mt-4 ml-2"
            v-if="homeGetters.trending.length > 0"
          >
            <b>Pencarian Tranding</b>
          </h5>
          <flickity
            v-if="homeGetters.trending"
            ref="flickity"
            class="flickity"
            :options="flickityOptions"
          >
            <div
              v-for="(item, index) in homeGetters.trending"
              :key="index"
              class="carousel-cell mb-4"
            >
              <router-link :to="'/home/DetailProduk/' + item.slug">
                <div class="p-2 bd-highlight">
                  <div
                    class="card card-tranding bg-white p-3 shadow-custom mr-1"
                  >
                    <div class="kotak-img">
                      <img
                        class="card-img-top"
                        :src="item.image"
                        alt="Card image cap"
                        style="
                          max-height: 100%;
                          max-width: 100%;
                          width: auto;
                          border-radius: 15px;
                        "
                      />
                    </div>
                    <div class="kotak-body-rekom">
                      <h5 class="title-card d-flex" style="color: #65b0c9">
                        <b>{{
                          item.name.length > 13
                            ? item.name.slice(0, 13).concat("...")
                            : item.name
                        }}</b>
                      </h5>

                      <h4 class="card-text d-flex">{{ item.price }}</h4>
                      <p class="card-text d-flex totalcari-tranding">
                        {{ item.totalSeen }}+Dicari
                      </p>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </flickity>
          <cardPlaceholder v-else />
        </div>

        <div v-if="homeGetters.basedOnSearch">
          <h5
            class="d-flex font-weight-bold mt-1 ml-2 mb-2"
            v-if="homeGetters.basedOnSearch.length > 0"
          >
            Pencarian Populer
          </h5>
          <flickity
            v-if="homeGetters.basedOnSearch"
            ref="flickity"
            class="flickity"
            :options="flickityOptions"
          >
            <div
              v-for="(item, index) in homeGetters.basedOnSearch"
              :key="index"
              class="carousel-cell mb-4"
              style="padding-left: 10px; padding-top: 10px"
            >
              <router-link :to="'/home/DetailProduk/' + item.slug">
                <div
                  class="d-flex mb-3 mr-3 p-3 shadow-custom rounded"
                  style="background: white; border-radius: 20px !important"
                >
                  <div
                    class="bd-highlight"
                    style="background: white; border-radius: 20px"
                  >
                    <div class="kotak-img-populer">
                      <img
                        class="image-populer p-2"
                        :src="item.image"
                        alt="Card image cap"
                        style="
                          max-height: 100%;
                          max-width: 100%;
                          width: auto;
                          border-radius: 15px;
                        "
                      />
                    </div>
                  </div>
                  <div class="bd-highlight">
                    <div class="kotak-text-populer">
                      <div class="p-2">
                        <h5 class="judul-populer d-flex">{{ item.name }}</h5>
                        <div class="harga-dan-loc-populer">
                          <p class="">{{ item.price }}</p>
                          <p class="">
                            <small
                              ><img
                                src="https://jaja.id/asset/home/logo/loc.png"
                                class="img-location"
                            /></small>
                            {{ item.location }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </flickity>
          <cardPlaceholder v-else />
        </div>

        <div>
          <h5 class="d-flex font-weight-bold mt-1 ml-2">Rekomendasi</h5>
          <p class="d-flex ml-2">Produk yang mungkin anda sukai</p>

          <cardSlider :data="recommendedGetters.items" />
        </div>

        <br />
      </div>
    </section>
  </div>
</template>

<script>
import Flickity from "vue-flickity";
import axios from "axios";
import cardSlider from "../../components/card/cardslider";
import cardPlaceholder from "../../components/card/cardplaceholder";
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  data() {
    return {
      slides: 7,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        wrapAround: false,
        freeScroll: false,
      },
    };
  },
  components: {
    Flickity,
    cardSlider,
    Carousel3d,
    Slide,
    cardPlaceholder,
  },
  mounted() {
    // console.log('axios.defaults.baseURL', axios.defaults.baseURL)
  },
  computed: {
    recommendedGetters() {
      return this.$store.getters.recommendedGetters;
    },
    homeGetters() {
      // setTimeout(() => '', 2000)
      return this.$store.getters.homeGetters;
    },
  },
};
</script>
<style scoped>
.carousel-3d-slide.current {
  border-radius: 10px;
}
.carousel-3d-slide.right-1 {
  border-radius: 10px;
}
.carousel-3d-slide.left-1 {
  border-radius: 10px;
}

/* .box-header-slider {
      margin-top:15%;
   } */

.box-slider {
  height: 350px;
  /* margin-top: 20px;
      margin-bottom: 20px; */
}

.body-home {
  /* jaja */
  /* background: #eff6f8 */
  /* ebh */
  background: #f2f2f2;
}
.image-banner {
  height: 240%;
  width: 100%;
  object-fit: contain;
}

.card-category-banner {
  margin-left: 20px;
}

.card-category-testing {
  margin-right: 20px;
  width: 260px;
  height: 90px;
  border: 3px solid #a0cadb;
  border-radius: 10px;
}

.card-category-testing div p {
  color: #65b0c9;
}

.card-body-category {
  border-radius: 10px;
}

.card-category {
  /* width:250px; */
  margin-left: 20px;
}
.body-keunggulan {
  position: relative;
}
.body-keunggulan .bungkus-icon {
  position: absolute;
  top: -12px;
}
.bungkus-icon span {
  border: 4px #ffc107 solid !important;
}
.body-keunggulan .bungkus-text {
  /* position:absolute; */
  margin-left: 30px;
  /* width:200px; */
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
}

.banner-gift {
  width: 105%;
  position: relative;
  left: -20px;
}
.kotak-img {
  height: 260px;
  width: 195px;
}

.kotak-img img {
  border-radius: 20px 20px 0 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-rekom {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  border: 0.1px;
  width: 215px;
  height: 325px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.kotak-img-rekom {
  /* background:yellow; */
  height: 60%;
  width: 90%;
  align-self: center;
  padding: 10px 0;
}
.kotak-img-rekom img {
  border-radius: 20px 20px 0 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  /* margin-left:20px; */
}
.kotak-body-rekom {
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  height: 40%;
  border-radius: 0 0 20px 20px;
}
.title-card {
  width: 90%;
}

.judul-tranding {
  font-size: 15px;
  color: #65b0c9;
  font-weight: bold;
}

.card-tranding {
  /* border-radius: 5% 5% 0 0; */
  border-radius: 20px;
}

.totalcari-tranding {
  color: #fdb738;
}

.totalcari-rekom {
  color: #fdb738 !important;
}

a:hover {
  text-decoration: none;
}

.flex-kotak-populer {
  /* border:1px solid rgb(221, 213, 213); */
  border-radius: 20px;
}

.kotak-img-populer {
  width: 150px;
  height: 150px;
  background: white;
}

.image-populer {
  width: 150px;
  height: 160px;
  object-fit: cover;
  border-radius: 15% 15% 0 0;
}

.kotak-text-populer {
  width: 150px;
  height: 150px;
  background: white;
}
.judul-populer {
  text-align: left;
  color: #65b0c9;
  font-size: 17px;
  font-weight: bold;
}
.harga-dan-loc-populer {
  position: absolute;
  top: 55%;
}
.harga-dan-loc-populer p:nth-child(1) {
  text-align: right;
  color: #e0a800;
  font-weight: bold;
  font-size: 22px;
}
.harga-dan-loc-populer p:nth-child(2) {
  text-align: left;
  position: relative;
  top: -10px;
}
</style>


<style lang="scss" scoped>
.birujaja-keunggulan {
  background: #a0cadb;
}

.border-warning {
  border: 3px solid #ffc107 !important;
}

.carousel-3d-slide.current {
  opacity: 1 !important;
  visibility: visible !important;
  transform: none !important;
  z-index: 999;
  position: relative;
  background-repeat: no-repeat;
}

.carousel-3d-slide {
  opacity: 0.5 !important;
}

.carousel-3d-container {
  .carousel-3d-slide {
    margin-left: -65%;
  }
}
</style>
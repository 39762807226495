<template>
    <div class="mrgn-top">
        <section class="">
            <div class="container p-3">
                <div class="row">
                    <div class="col-md-3">

                        <cardFIlter :fungsiFilterLocation="fungsiFilterLocation" :fungsi="fungsiJancuk" :dataFilter="dataFilter" :dataSort="dataSort" :dataFilterLokasi="dataFilterLokasi"  :resetOptions="resetOptions"/>
                            <!-- Html Filter -->
                            <!-- <div class="filter-product mb-4">
                              
                                <div class="" v-for="(item, index) in dataFilter" :key="index">
                                    <div v-if="item.slug === 'location'">
                                        <div class="d-flex justify-content-between bd-highlight bg-filter header-filter">
                                            <div class="p-2 bd-highlight ml-3 mt-2 font-weight-bold kotak-filter">Filter</div>
                                            <div class="p-2 bd-highlight kotak-tombol-reset">
                                                <a href="" class="btn btn-sm mr-3 mt-2 font-weight-bold">Reset</a>
                                            </div>
                                        </div>
                                        <ul class="ul-list-filter p-4 bg-filter">
                                            <li class="bg-filter">
                                            <label class="d-flex font-weight-bold text-label" for="">{{item.name}}</label>
                                                <select @change="filterProduk(item.slug)" class="browser-default custom-select">
                                                    <option> - Pilih Kategori - </option>
                                                    <option v-for="(a,b) in item.items" :key="b" :value="a.value">{{a.name}}</option>
                                                </select>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="kotak-kondisi bg-filter p-2">
                                    <label class="d-flex ml-3 font-weight-bold text-label" for="">Kondisi</label>
                                    <div class="d-flex justify-content-between bd-highlight">
                                        <div class="p-2 ml-2 bd-highlight">
                                            <div class="form-check">
                                                <input @change="filterProduk('condition')" class="form-check-input" type="radio" name="kondisibarang" id="condition-baru" value="baru" checked>
                                                <label class="form-check-label text-label" for="condition-baru">
                                                    Baru
                                                </label>
                                            </div>
                                        </div>
                                        <div class="p-2 mr-5 bd-highlight">
                                            <div class="form-check">
                                                <input @change="filterProduk('condition')" class="form-check-input" type="radio" name="kondisibarang" id="condition-bekas" value="bekas">
                                                <label class="form-check-label text-label" for="condition-bekas">
                                                    Bekas
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="kotak-stock bg-filter p-2">
                                    <label class="d-flex ml-3 font-weight-bold text-label" for="">Stock</label>
                                    <div class="d-flex justify-content-between bd-highlight">
                                        <div class="p-2 ml-2 bd-highlight">
                                            <div class="form-check">
                                                <input @change="filterProduk('preorder')" class="form-check-input" type="radio" name="exampleRadios" id="preorder-Y" value="Y" >
                                                <label class="form-check-label text-label label-order" for="preorder-Y">
                                                    <small>Pre Order</small> 
                                                </label>
                                            </div>
                                        </div>
                                        <div class="p-2 mr-2 bd-highlight">
                                            <div class="form-check">
                                                <input @change="filterProduk('preorder')" class="form-check-input" type="radio" name="exampleRadios" id="preorder-T" value="T" checked>
                                                <label class="form-check-label text-label label-order-ready" for="preorder-T">
                                                     Ready Stok
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div> -->

                            <!-- <div class="short-product bg-filter">
                                <div v-for="(item, index) in dataSort" :key="index" class="form-check-block d-flex mb-1">
                                    <label class="form-check-label">
                                        <input @change="sortProduk()" type="radio" class="form-check-input" :value="item.value" name="condition" :checked="index == 0">{{item.name}}
                                    </label>
                                </div>
                            </div> -->
                        <div>
                            
                    </div>
                        <!-- <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li class="page-item disabled">
                                <a class="page-link" href="#" tabindex="-1">Previous</a>
                                </li>
                                <li v-for="(item, index) in dataPage" :key="index" class="page-item"><a @click="setPage(item)" class="page-link">{{item}}</a></li>
                                <li class="page-item">
                                <a class="page-link" href="#">Next</a>
                                </li>
                            </ul>
                        </nav> -->
                     
                        </div>
                        <div class="col-md-9 body-home">
                        <div class="placeholder" style="display: none;">
                            <div class="ph-item" style="margin:0;">
                                <div class="ph-col-12">
                                    <div class="ph-picture" style="height:500px"></div>
                                </div>
                            </div>
                        </div>

                        <cardProduk :isFinish="isFinish" :data="dataKategory" :totalData="recordsLength"/>
                        <br>
                        <pagination style="display: flex; align-items: center; justify-content: center;" :records="recordsLength" v-model="page" :per-page="perPage" @paginate="getKategori">
                        </pagination>
                        
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  
  import cardProduk from '../../components/card/cardproduct'
  import cardFIlter from '../../components/filtercategory/filtercategory'
  export default {
    data(){
      return {
        dataKategory:{},
        dataFilter:{},
        dataSort:{},
        dataPage:{},
        reqData:{
			'page':1,
			'limit':20,
			'keyword':'',
			'filter_price':'',
			'filter_location':'',
			'filter_condition':'',
			'filter_preorder':'',
			'filter_brand':'',
			'sort':''
		},
        page: 1,
        perPage: 12,
        recordsLength: 0,
        pageSizes: [12, 24, 32],
        isFinish:false,
        dataFilterLokasi:[]
      }
    },
    created() {
        this.getKategori(this.page);
    },
    components: {
        cardProduk,
        cardFIlter
	},
    computed: {
      ...mapGetters({isLoggedIn:'isLoggedIn'}),
      ...mapGetters({globalToken:'globalTokenGetters'}),
    },
    beforeMount(){
        this.getKategori()
    },
    methods: {
        resetOptions: function () {
            this.reqData.filter_price = '';
            this.reqData.filter_location = '';
            this.reqData.filter_condition = '';
            this.reqData.filter_preorder = '';
            this.reqData.filter_brand = '';
            this.reqData.sort = '';
            
            var location_list = document.getElementsByClassName('locationProduct');
            for (var i_loc = 0; i_loc < location_list.length; i_loc++) {
                location_list[i_loc].selected = false;
            }

            var condition_list = document.getElementsByClassName('conditionProduct');
            for (var i_con = 0; i_con < condition_list.length; i_con++) {
                condition_list[i_con].checked = false;
            }

            var preorder_list = document.getElementsByClassName('preorderProduct');
            for (var i_pre = 0; i_pre < preorder_list.length; i_pre++) {
                preorder_list[i_pre].checked = false;
            }

            var sort_list = document.getElementsByClassName('sortProduct');
            for (var i_sort = 0; i_sort < sort_list.length; i_sort++) {
                sort_list[i_sort].checked = false;
            }

            this.getKategori(this.page)
        },
        fungsiJancuk(slug){
            const sortOrNot = slug.split(":")[0];
            if(sortOrNot == "filterProduk") {
                const filterName = slug.split(":")[1];
                const valueFilter = slug.split(":")[2]
                if(filterName == "condition") {
                    this.reqData.filter_condition = valueFilter;
                    this.getKategori(this.page)
                } else {
                    this.reqData.filter_preorder = valueFilter
                    this.getKategori(this.page)
                }
            } else {
                const valueSort = slug.split(":")[1]
                this.reqData.sort = valueSort
                this.getKategori(this.page)
            }
        },
        fungsiFilterLocation(slug){
            this.reqData.filter_location = event.target.value;
            this.getKategori(this.page)
        },
        getKategori: function(page) {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "ci_session=2kdci4f3vuscde03fkaof4iabv7v2ovp");

            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };

            fetch(`${this.domainURL}product/category/${this.$route.params.id}?page=${this.page}&limit=${this.perPage}&keyword=${this.reqData.keyword}&filter_price=${this.reqData.filter_price}&filter_location=${this.reqData.filter_location}&filter_condition=${this.reqData.filter_condition}&filter_preorder=${this.reqData.filter_preorder}&filter_brand=${this.reqData.filter_brand}&sort=${this.reqData.sort}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                const res = JSON.parse(result)
                if(res.status.code === 200){
                    this.dataKategory = res.data.items
                    this.dataFilter = res.data.filters
                    this.dataSort = res.data.sorts
                    this.dataPage = res.data.totalPage
                    this.recordsLength = res.data.totalData
                    this.getLokasiSelect()
                }
                this.isFinish = true
            })
            .catch(error => console.log('error', error));
        },
        getLokasiSelect: function(page) {
            var myHeaders = new Headers();
            myHeaders.append("Cookie", "ci_session=2kdci4f3vuscde03fkaof4iabv7v2ovp");

            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };

            fetch(`${this.domainURL}product/category/${this.$route.params.id}?page=${this.page}&limit=${this.perPage}&keyword=&filter_price=&filter_location=&filter_condition=&filter_preorder=&filter_brand=&sort=`, requestOptions)
            .then(response => response.text())
            .then(result => {
                const res = JSON.parse(result)
                if(res.status.code === 200){
                    this.dataFilterLokasi = res.data.filters[0].items
                }
                this.isFinish = true
            })
            .catch(error => console.log('error', error));
        },
        handlePageSizeChange(event) {
            this.perPage = event.target.value;
            this.page = 1;
            this.getKategori(this.page)
            
        },

        setAltImg(event) { 
            event.target.src = "https://seller.jaja.id/asset/images/img-thumbnail.png" 
        },
        filterProduk(slug) {
			const stringS = 'filter_'
			const dataConcate = stringS.concat(slug);
			if(dataConcate == "filter_location") {
				this.reqData.filter_location = event.target.value
			} else if(dataConcate == "filter_condition") {
				this.reqData.filter_condition = event.target.value
			} else if(dataConcate == "filter_preorder"){
				this.reqData.filter_preorder = event.target.value
			}
			setTimeout(() => this.getKategori(), 200)
        }, 
        sortProduk() {
			this.reqData.sort = event.target.value
			setTimeout(() => this.getKategori(), 200)
        },
        setPage(page) {
			this.reqData.page = page
			setTimeout(() => this.getKategori(), 200)
		}

    }
}
</script>

<style scope>

.header-filter {
    border-radius:15px 15px 0 0;
}

.kotak-filter {
    color:#65b0c9;
    font-size:25px;
}

.kotak-tombol-reset a {
    background: rgb(224, 116, 116);
    color:white;
}

.ul-list-filter {
    list-style-type: none;
}

.kotak-kondisi {
    position:relative;
    top:-12px;
}

.kotak-stock {
    position:relative;
    top:-8.5px;
}

.short-product {
    padding: 15px 0 15px 0;
    position:relative;
    top:-28px;
    border-radius:0 0 15px 15px;
}
.short-product div {
    margin-left:17.5%;
}

.text-label {
    color:rgb(117, 116, 116);
}

.label-order {
    font-size:16px;
}

.label-order-ready {
    font-size:14px;
}

.bg-filter {
    background:#ffd68d;
}
.card-product {
    border-radius: 20px;;
}

.ImgSizeProduct {
    height:150px;
}
.ImgSizeProduct img {
    border-radius:20px 20px 0 0;
    /* border:1px solid rgb(224, 224, 224); */
}
.text-product {
    color:#65b0c9;
    font-weight:bold;
    text-align:left;
}
.text-price {
    color:#fdb739;
    font-weight:bold;
    text-align:left;
    font-size:20px;
}
.kotak-location {
    position:relative;
    display: flex;
    top:-10px;
}
.kotak-location small {
    position:absolute;
    left:15px;
}
.kotak-location img {
    width:14px;
    height: 14px;
    position:absolute;
}


/* PAGINATION CUSTOM BY MALA */

.page-link {
    width: 40px;
    height: 40px;
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-right: 5px;
    margin-left: 5px;
    color: #65b0c9;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 50%;
    font-weight: bold;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
    border-radius: 50%;
    font-weight: bold;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #65b0c9;
    border-color: #65b0c9;
    font-weight: bold;
}
</style>
<template>
  <div class="container p-3">
    <div class="row">
      <div class="col-md-3">
        <sideBar />
      </div>
      <div class="col-md-9">
        <div class="card-product shadow-custom br-card-jaja">
          <div class="card-header br-card-header-jaja">
            <div class="d-flex justify-content-between bd-highlight">
              <div class="pt-2 bd-highlight mr-auto">
                <h5 class="d-flex font-weight-bold text-white">Ubah Alamat</h5>
              </div>
              <div class="pt-1 bd-highlight">
                <h6 class="d-flex font-weight-bold">
                  <router-link to="/address">
                    <button
                      type="button"
                      class="btn btn-warning btn-sm text-white font-weight-bold"
                    >
                      <i class="fas fa-angle-left ver-middle"></i>&nbsp;<span
                        class="ver-middle"
                        >Kembali</span
                      >
                    </button>
                  </router-link>
                </h6>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="nama" class="float-left">Nama Penerima *</label>
                  <input
                    type="text"
                    class="form-control"
                    name="nama"
                    placeholder="Isi Nama Penerima Anda"
                    v-model="reqUpdateAddr.receiverName"
                  />
                </div>
                <div class="form-group">
                  <label for="no_telepon" class="float-left"
                    >Nomor Telepon *</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="no_telepon"
                    v-model="reqUpdateAddr.phoneNumber"
                    placeholder="Isi Nomer Telp Anda"
                  />
                </div>
                <div class="form-group">
                  <label for="province" class="float-left">Provinsi *</label>
                  <select
                    class="form-control"
                    id="province"
                    name="province"
                    @change="changeProvinsi"
                    v-model="reqUpdateAddr.provinceId"
                  >
                    <option value="">Silahkan Pilih Provinsi</option>
                    <option
                      v-for="i in dataProvinsi"
                      :key="i.province_id"
                      :value="i.province_id"
                    >
                      {{ i.province }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="kota" class="float-left">Kota *</label>
                  <select
                    class="form-control"
                    id="kota"
                    name="kota"
                    @change="changeKota"
                    v-model="reqUpdateAddr.cityId"
                  >
                    <option value="">Silahkan Pilih Kota</option>
                    <option
                      v-for="i in dataKota"
                      :key="i.city_id"
                      :value="i.city_id"
                    >
                      {{ i.city_name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="kecamatan" class="float-left">Kecamatan *</label>
                  <select
                    class="form-control"
                    id="kecamatan"
                    name="kecamatan"
                    @focus="getKecamatanKd()"
                    @change="changeKecamatan()"
                    v-model="reqUpdateAddr.districtId"
                  >
                    <option value="">Silahkan Pilih Kecamatan</option>
                    <option
                      v-for="i in dataKecamatan"
                      :key="i.kecamatan_id"
                      :value="i.kecamatan_id"
                      :data-rc="i.kecamatan_kd"
                    >
                      {{ i.kecamatan }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="kelurahan" class="float-left">Kelurahan *</label>
                  <select
                    class="form-control"
                    id="kelurahan"
                    name="kelurahan"
                    @change="changeKelurahan()"
                    v-model="reqUpdateAddr.subDistrictId"
                  >
                    <option value="">Silahkan Pilih Kelurahan</option>
                    <option
                      v-for="i in dataKelurahan"
                      :key="i.kelurahan_id"
                      :value="i.kelurahan_id"
                    >
                      {{ i.kelurahan_desa }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="kodepos" class="float-left">Kode Pos *</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="reqUpdateAddr.postalCode"
                    placeholder="isi Kode Pos Anda"
                    name="kodepos"
                  />
                </div>
                <div class="form-group">
                  <label for="jenisalamat" class="float-left"
                    >Jenis Alamat *</label
                  >
                  <br />
                  <div class="d-flex justify-content-between bd-highlight mb-3">
                    <div class="p-2 bd-highlight">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="jenisalamat"
                          id="alamatkantor"
                          value="Alamat Kantor"
                          @change="changeJenisAlamat"
                          :checked="reqUpdateAddr.label === 'Alamat Kantor'"
                        />
                        <label class="form-check-label" for="alamatkantor">
                          Alamat Kantor
                        </label>
                      </div>
                    </div>
                    <div class="p-2 bd-highlight">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="jenisalamat"
                          id="alamatrumah"
                          value="Alamat Rumah"
                          @change="changeJenisAlamat"
                          :checked="reqUpdateAddr.label === 'Alamat Rumah'"
                        />
                        <label class="form-check-label" for="alamatrumah">
                          Alamat Rumah
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="alamatlengkap" class="float-left"
                    >Alamat Lengkap *</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    name="alamatlengkap"
                    v-model="reqUpdateAddr.address"
                    placeholder="isi Alamat Rumah"
                  />
                </div>
                <div class="form-group">
                  <label for="alamatgmaps" class="float-left"
                    >Isi Alamat Google Maps *</label
                  >
                  <input type="hidden" class="latit" />
                  <input type="hidden" class="longi" />
                  <mapGoggle
                    :latlong="latlong"
                    :reqUpdateAddr="reqUpdateAddr"
                  />
                </div>
              </div>
              <div class="col-md-4"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="updateAddress"
              type="button"
              class="btn bg-primary-jaja text-white font-weight-bold"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import mapGoggle from "../../components/mapcomponent/mapcomp.vue";
export default {
  data() {
    return {
      addrById: {},
      reqUpdateAddr: {
        addressId: "",
        label: "",
        receiverName: "",
        phoneNumber: "",
        address: "",
        addressGoogle: "",
        latitude: "",
        longitude: "",
        provinceId: "",
        cityId: "",
        districtId: "",
        districtKd: "",
        subDistrictId: "",
        namaKelurahan: "",
        postalCode: "",
      },
      dataProvinsi: [],
      dataKota: [],
      dataKecamatan: [],
      dataKelurahan: [],
      kelurahan: {},
      provinsi: {},
      allKecamatan: {},
      dataFilterKecamatan: {},
      latlong: {
        lat: 0,
        lng: 0,
      },
    };
  },
  components: {
    sideBar,
    mapGoggle,
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  mounted() {
    this.getDataAddressById(),
      this.getProvinsi(),
      this.getKota(),
      this.getKecamatan(),
      this.getKelurahan();
  },
  methods: {
    getDataAddressById() {
      const address = this.user.location;
      const getAddress = address.filter((item) => {
        if (item.id == this.$route.params.id) {
          return item;
        }
      });
      this.addrById = getAddress[0];

      document.getElementsByClassName("introInput")[0].value =
        this.addrById.alamat_google;

      this.reqUpdateAddr.addressId = this.addrById.id;
      this.reqUpdateAddr.label = this.addrById.label;
      this.reqUpdateAddr.receiverName = this.addrById.nama_penerima;
      this.reqUpdateAddr.phoneNumber = this.addrById.no_telepon;
      this.reqUpdateAddr.address = this.addrById.alamat_lengkap;
      this.reqUpdateAddr.addressGoogle =
        document.getElementsByClassName("introInput")[0].value;
      // this.reqUpdateAddr.latitude = this.addrById.latitude
      // this.reqUpdateAddr.longitude = this.addrById.longitude
      this.reqUpdateAddr.provinceId = this.addrById.provinsi_id;
      this.reqUpdateAddr.cityId = this.addrById.kota_kabupaten_id;
      this.reqUpdateAddr.districtId = this.addrById.kecamatan_id;
      this.reqUpdateAddr.districtKd = this.addrById.kecamatan_kd;
      this.reqUpdateAddr.subDistrictId = this.addrById.kelurahan_id;
      this.reqUpdateAddr.namaKelurahan = this.addrById.kelurahan;
      this.reqUpdateAddr.postalCode = this.addrById.kode_pos;

      document.getElementsByClassName("latit")[0].value =
        this.addrById.latitude;
      document.getElementsByClassName("longi")[0].value =
        this.addrById.longitude;
      this.latlong.lat = this.addrById.latitude;
      this.latlong.lng = this.addrById.longitude;

      // console.log(this.user.location);
      // console.log(this.latlong);
    },
    getProvinsi() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(`${this.domainURL}master/province`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status === 200) {
            this.dataProvinsi = res.provinsi;
          }
        })
        .catch((error) => console.log("error", error));
    },
    changeProvinsi() {
      this.reqUpdateAddr.provinceId = event.target.value;
      this.getKota();
    },
    getKota() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}master/city?province=${this.reqUpdateAddr.provinceId}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status === 200) {
            this.dataKota = res.city;
          }
        })
        .catch((error) => console.log("error", error));
    },
    changeKota() {
      this.reqUpdateAddr.cityId = event.target.value;
      this.getKecamatan(this.reqUpdateAddr.cityId);
    },
    getKecamatan() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}master/kecamatan?city=${this.reqUpdateAddr.cityId}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status === 200) {
            this.dataKecamatan = res.kecamatan;
          }
        })
        .catch((error) => console.log("error", error));
    },
    getKecamatanKd() {
      var e = document.getElementById("kecamatan");
      var option = e.options[e.selectedIndex];
      var kecamatan_kd = option.getAttribute("data-rc");
      this.reqUpdateAddr.districtKd = kecamatan_kd;
      this.getKelurahan(kecamatan_kd);
    },
    changeKecamatan() {
      this.reqUpdateAddr.districtId = event.target.value;
    },
    getKelurahan(districtKd) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}master/kelurahan?kd_kec=${districtKd}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status === 200) {
            this.dataKelurahan = res.kelurahan;
          }
        })
        .catch((error) => console.log("error", error));
    },
    changeKelurahan() {
      this.reqUpdateAddr.subDistrictId = event.target.value;
    },
    changeJenisAlamat() {
      this.reqUpdateAddr.label = event.target.value;
    },

    updateAddress() {
      this.$swal.fire({
        html: '<img src="https://jaja.id/asset/home/logo/Loading_2.gif" alt="">', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading();
        },
      });

      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "ci_session=t3aj7ofie0camk7k1v2d6s8llbr7q3kb");

      var raw = JSON.stringify({
        addressId: this.reqUpdateAddr.addressId,
        label: this.reqUpdateAddr.label,
        receiverName: this.reqUpdateAddr.receiverName,
        phoneNumber: this.reqUpdateAddr.phoneNumber,
        address: this.reqUpdateAddr.address,
        addressGoogle: this.reqUpdateAddr.addressGoogle,
        latitude: this.reqUpdateAddr.latitude,
        longitude: this.reqUpdateAddr.longitude,
        provinceId: this.reqUpdateAddr.provinceId,
        cityId: this.reqUpdateAddr.cityId,
        districtId: this.reqUpdateAddr.districtId,
        subDistrictId: this.reqUpdateAddr.subDistrictId,
        postalCode: this.reqUpdateAddr.postalCode,
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${this.domainURL}user/address`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          document.getElementsByClassName("introInput")[0].value = "";
          this.$swal
            .fire("Sukses", "Berhasil mengubah data", "success")
            .then(() => {
              this.$store.dispatch("get_user");
            });
        })
        .catch((error) => console.log("error", error));
    },
  },
};
</script>
<style>
i.fa-angle-left {
  font-size: 20px;
}
.introInput {
  width: 100%;
}
</style>
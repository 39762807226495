<template>
    <div>
        <Banner />
        <ProdukTerlaris />
    </div>
</template>

<script>
import ProdukTerlaris from '../../components/homecomponents/ProdukTerlaris'
import Banner from '../../components/homecomponents/Banner'
    export default {
        components:{
            ProdukTerlaris,
            Banner,
        },
        computed:{
             homeGetters(){
                return this.$store.getters.homeGetters
            }
        }
    }
</script>
import { render, staticRenderFns } from "./register-verification.vue?vue&type=template&id=75700b20&scoped=true&"
import script from "./register-verification.vue?vue&type=script&lang=js&"
export * from "./register-verification.vue?vue&type=script&lang=js&"
import style0 from "./register-verification.vue?vue&type=style&index=0&id=75700b20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75700b20",
  null
  
)

export default component.exports
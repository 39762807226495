import { render, staticRenderFns } from "./pemberitahuan.vue?vue&type=template&id=9846620c&scoped=true&"
import script from "./pemberitahuan.vue?vue&type=script&lang=js&"
export * from "./pemberitahuan.vue?vue&type=script&lang=js&"
import style0 from "./pemberitahuan.vue?vue&type=style&index=0&id=9846620c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9846620c",
  null
  
)

export default component.exports
<template>
  <div class="container p-3">
    <div class="row">
      <div class="col-md-3">
        <sideBar />
      </div>
      <div class="col-md-9" v-if="isFinish">
        <div class="card-product shadow-custom br-card-jaja mb-2">
          <div class="card-header br-card-header-jaja">
            <div class="d-flex">
              <div class="">
                <h5 class="mt-2 text-white font-weight-bold">
                  Terakhir Dilihat
                </h5>
              </div>
            </div>
          </div>

          <div class="card-body br-card-footer-jaja">
            <div class="row">
              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-2">
                    Filter by Category
                  </div>
                  <div class="input-group">
                    <select
                      class="browser-default custom-select"
                      @change="fungsiFilterCategory"
                    >
                      <option value="">- Pilih Kategori -</option>
                      <option
                        v-for="(a, b) in dataFilterKategori"
                        :key="b"
                        :value="a.slug"
                      >
                        {{ a.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-3">
                    Filter by Kondisi
                  </div>
                  <div class="d-flex justify-content-between bd-highlight">
                    <div class="bd-highlight">
                      <div class="form-check">
                        <input
                          class="form-check-input kondisibarangbaru"
                          type="radio"
                          name="kondisibarangbaru"
                          id="kondisibarangbaru"
                          value="baru"
                          @change="fungsiFilterConditionBaru"
                        />
                        <label
                          class="form-check-label text-label text-size-jaja"
                          for="kondisibarangbaru"
                        >
                          <small class="font-weight-bold">Baru</small>
                        </label>
                      </div>
                    </div>
                    <div class="bd-highlight ml-4">
                      <div class="form-check">
                        <input
                          class="form-check-input kondisibarangbekas"
                          type="radio"
                          name="kondisibarangbekas"
                          id="kondisibarangbekas"
                          value="bekas"
                          @change="fungsiFilterConditionBekas"
                        />
                        <label
                          class="form-check-label text-label text-size-jaja"
                          for="kondisibarangbekas"
                        >
                          <small class="font-weight-bold">Bekas</small>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-3">Filter by Stok</div>
                  <div class="d-flex justify-content-between bd-highlight">
                    <div class="bd-highlight">
                      <div class="form-check">
                        <input
                          class="form-check-input preorder"
                          type="radio"
                          name="preorder"
                          id="preorder"
                          value="Y"
                          @change="fungsiFilterPreorder"
                        />
                        <label
                          class="form-check-label text-label text-size-jaja"
                          for="preorder"
                        >
                          <small class="font-weight-bold">Pre order</small>
                        </label>
                      </div>
                    </div>
                    <div class="bd-highlight">
                      <div class="form-check">
                        <input
                          class="form-check-input ready"
                          type="radio"
                          name="ready"
                          id="ready"
                          value="T"
                          @change="fungsiFilterReadyStock"
                        />
                        <label
                          class="form-check-label text-label text-size-jaja"
                          for="ready"
                        >
                          <small class="font-weight-bold">Ready Stok</small>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- FILTER -->
        </div>
        <!-- HEADER -->

        <div class="row mb-2">
          <div class="col-6"></div>
          <div class="col-6">
            <form class="form-inline p-2 float-right" @submit="fungsiSearch">
              <div class="form-group">
                <input
                  class="form-control shadow-custom"
                  type="text"
                  name="searchLastSeen"
                  placeholder="Search by keyword here..."
                  style="border-radius: 10px; width: 250px"
                  :v-model="reqData.keyword"
                  @keyup="fungsiSearch"
                />
                <img
                  src="https://nimda.jaja.id/asset/icon-jaja/luv.png"
                  style="width: 20px; margin-left: -30px"
                />
              </div>
            </form>
          </div>
        </div>

        <!-- <cardProduk :isFinish="isFinish" :data="lastseen.history" :totalData="recordsLength"/> -->

        <div class="row" v-if="Object.keys(lastseen.history).length > 0">
          <div
            v-for="(item, index) in lastseen.history"
            :key="index"
            class="col-md-3 col-6 carousel-cell is-selected"
          >
            <router-link :to="'/home/DetailProduk/' + item.slug">
              <div
                class="CheckShowCheck card-product p-3 mb-3 shadow-custom"
                style="background: white !important"
              >
                <div class="">
                  <div></div>
                  <vue-load-image>
                    <img
                      slot="image"
                      :src="item.image"
                      class="ImgSizeProduct img-product"
                      style="
                        max-height: 100%;
                        max-width: 100%;
                        width: auto;
                        border-radius: 10px;
                      "
                    />
                    <img
                      slot="preloader"
                      src="https://jaja.id/asset/home/logo/Loading_2.gif"
                      class="ImgSizeProduct img-product"
                    />
                    <div slot="error">
                      <img
                        src="https://nimda.jaja.id/asset/icon-jaja/jaja-logo%20bw.png"
                        class="ImgSizeProduct img-product"
                        style="
                          max-height: 100%;
                          max-width: 100%;
                          width: auto;
                          border-radius: 10px;
                        "
                      />
                    </div>
                  </vue-load-image>

                  <div class="text-dark">
                    <div class="keterangan ResetKet_JajaID">
                      <div class="text-product one-line fsxx pt-1">
                        {{
                          item.name.length > 15
                            ? item.name.slice(0, 15).concat("...")
                            : item.name
                        }}
                      </div>

                      <br />
                      <p class="text-price mt-2">
                        {{ item.isDiscount ? item.priceDiscount : item.price }}
                      </p>
                      <div class="kotak-location">
                        <img
                          src="https://jaja.id/asset/home/logo/loc.png"
                          class=""
                        />
                        <small class="">{{ item.location }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row" v-else-if="isFinish">
          <div class="col-md-12 col-12 carousel-cell is-selected">
            <div
              class="CheckShowCheck card-product p-3 mb-3 shadow-custom"
              style="background: white !important"
            >
              <div class="">
                <div></div>
                <img
                  slot="image"
                  src="https://nimda.jaja.id/asset/icon-jaja/notfound.png"
                  class="img-fluid"
                  style="
                    max-height: 400px;
                    max-width: 100%;
                    width: auto;
                    border-radius: 10px;
                  "
                />

                <div class="text-dark">
                  <div class="keterangan ResetKet_JajaID">
                    <h4
                      class="text-product one-line fsxx text-center"
                      style="margin-top: -20px"
                    >
                      Looks like the data you are looking for is empty.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div v-for="index in 12" :key="index" class="" style="padding: 10px">
            <content-placeholders :rounded="true">
              <content-placeholders-img style="width: 200px; heoght: 200px" />
              <content-placeholders-heading />
            </content-placeholders>
          </div>
        </div>
      </div>
      <div class="col-md-9" v-if="!isFinish">
        <!-- HEADER -->
        <div class="card-product shadow-custom br-card-jaja mb-2">
          <div class="card-header br-card-header-jaja">
            <div class="d-flex">
              <div class="">
                <h5 class="mt-2 text-white font-weight-bold">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="1"
                      style="width: 200px; height: 20px"
                    />
                  </content-placeholders>
                </h5>
              </div>
            </div>
          </div>

          <!-- FILTER -->
          <div class="card-body br-card-footer-jaja">
            <div class="row">
              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-2">
                    <content-placeholders :rounded="true" :centered="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 100%; height: 20px"
                      />
                    </content-placeholders>
                  </div>
                  <content-placeholders :rounded="true" :centered="true">
                    <content-placeholders-img
                      style="width: 100%; height: 30px"
                    />
                  </content-placeholders>
                </div>
              </div>

              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-2">
                    <content-placeholders :rounded="true" :centered="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 100%; height: 20px"
                      />
                    </content-placeholders>
                  </div>
                  <content-placeholders :rounded="true" :centered="true">
                    <content-placeholders-img
                      style="width: 100%; height: 30px"
                    />
                  </content-placeholders>
                </div>
              </div>

              <div class="col-md-3 mx-auto card-filter-item">
                <div class="p-2">
                  <div class="py-1 font-weight-bold mb-2">
                    <content-placeholders :rounded="true" :centered="true">
                      <content-placeholders-text
                        :lines="1"
                        style="width: 100%; height: 20px"
                      />
                    </content-placeholders>
                  </div>
                  <content-placeholders :rounded="true" :centered="true">
                    <content-placeholders-img
                      style="width: 100%; height: 30px"
                    />
                  </content-placeholders>
                </div>
              </div>
            </div>
          </div>
          <!-- FILTER -->
        </div>
        <!-- HEADER -->

        <div class="row mb-2">
          <div class="col-6"></div>
          <div class="col-6">
            <form class="form-inline p-2 float-right">
              <div class="form-group">
                <content-placeholders :rounded="true" :centered="true">
                  <content-placeholders-img
                    style="width: 200px; height: 30px"
                  />
                </content-placeholders>
              </div>
            </form>
          </div>
        </div>

        <div class="row">
          <div v-for="index in 12" :key="index" class="" style="padding: 10px">
            <content-placeholders :rounded="true">
              <content-placeholders-img style="width: 200px; heoght: 200px" />
              <content-placeholders-heading />
            </content-placeholders>
          </div>
        </div>

        <!-- PAGINATION BELUM BISA KARENA DARI API-NYA GA JALAN -->
        <!-- <br>
                        <pagination style="display: flex; align-items: center; justify-content: center;" :records="recordsLength" v-model="page" :per-page="perPage" @paginate="getDataWisLish">
                        </pagination> -->
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.6.0.js" integrity="sha256-H+K7U5CnXl1h5ywQfKtSj8PCmoN9aaq30gDh27Xc0jk=" crossorigin="anonymous"></script>
<script>
import { mapGetters } from "vuex";
import sideBar from "../../components/sidebar/sideBar";
import cardProduk from "../../components/card/cardproduct";
import VueLoadImage from "vue-load-image";

export default {
  data() {
    return {
      srcLoad: "http://via.placeholder.com/350x150",
      isLoaded: false,

      lastseen: {},

      dataFilter: {},
      dataSort: {},
      dataPage: {},
      reqData: {
        page: 1,
        limit: 20,
        keyword: "",
        filter_price: "",
        filter_category: "",
        filter_condition: "",
        filter_preorder: "",
        filter_brand: "",
        sort: "",
      },
      page: 1,
      perPage: 12,
      recordsLength: 0,
      pageSizes: [12, 24, 32],
      isFinish: false,
      dataFilterKategori: [],
      dataFilterCondition: [],
      dataFilterPreorder: [],
    };
  },
  components: {
    sideBar,
    cardProduk,
    "vue-load-image": VueLoadImage,
  },
  watch: {
    isLoaded: function (newValue, oldValue) {
      console.log("New value is: " + newValue);
      console.log("Old value is: " + oldValue);
    },
  },
  computed: {
    ...mapGetters({ globalToken: "globalTokenGetters" }),
    ...mapGetters({ user: "user" }),
  },
  mounted() {
    this.getLastSeen();
  },
  methods: {
    onImgLoad() {
      this.isLoaded = true;
    },
    goToDetailProduk(slug) {
      this.$router.push(`/home/DetailProduk/${slug}`);
    },
    fungsiFilterCategory(slug) {
      this.reqData.filter_category = event.target.value;
      this.getLastSeen(this.page);
    },
    fungsiFilterConditionBaru(slug) {
      var conditionBekas_list =
        document.getElementsByClassName("kondisibarangbekas");
      conditionBekas_list[0].checked = false;
      this.reqData.filter_condition = "";
      this.reqData.filter_condition = event.target.value;
      this.getLastSeen(this.page);
    },
    fungsiFilterConditionBekas(slug) {
      var conditionBaru_list =
        document.getElementsByClassName("kondisibarangbaru");
      conditionBaru_list[0].checked = false;
      this.reqData.filter_condition = "";
      this.reqData.filter_condition = event.target.value;
      this.getLastSeen(this.page);
    },
    fungsiFilterPreorder(slug) {
      var ready_list = document.getElementsByClassName("ready");
      ready_list[0].checked = false;
      this.reqData.filter_preorder = "";
      this.reqData.filter_preorder = event.target.value;
      this.getLastSeen(this.page);
    },
    fungsiFilterReadyStock(slug) {
      var preorder_list = document.getElementsByClassName("preorder");
      preorder_list[0].checked = false;
      this.reqData.filter_preorder = "";
      this.reqData.filter_preorder = event.target.value;
      this.getLastSeen(this.page);
    },
    fungsiSearch(slug) {
      this.reqData.keyword = event.target.value;
      this.getLastSeen(this.page);
    },
    getLastSeen: function (page) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${this.domainURL}user/terakhirDilihat/${this.user.id}?keyword=${this.reqData.keyword}&filter_preorder=${this.reqData.filter_preorder}&filter_condition=${this.reqData.filter_condition}&filter_category=${this.reqData.filter_category}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.lastseen = res.data;
            this.getFilter();
          }
          this.isFinish = true;
        })
        .catch((error) => console.log("error", error));
    },
    getFilter: function (page) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.globalToken);
      myHeaders.append("Cookie", "ci_session=r3rqr2lumbostcrujs286hthp1jfngnt");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${this.domainURL}master/category`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);
          if (res.status.code === 200) {
            this.dataFilterKategori = res.data;
          }
          this.isFinish = true;
        })
        .catch((error) => console.log("error", error));
    },
    handlePageSizeChange(event) {
      this.perPage = event.target.value;
      this.page = 1;
      this.getLastSeen(this.page);
    },
  },
};
</script>
<style lang="scss" scoped>
.CheckShowCheck {
  background-color: white;
}
.card-body {
  background-color: #f8f8f8;
}
.card-header {
  background-color: #64b0c9;
}
.card-filter-item {
  border-radius: 10px;
  background: #ffd68d;
}
</style>

<style>
.card-header {
  background-color: #64b0c9;
}
</style>
